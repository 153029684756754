<template>
    <div>
        <el-dialog :close-on-click-modal="false" top="40px" fullscreen :modal-append-to-body="false" @close="closeFun"
            :visible.sync="showCreateModel" :destroy-on-close="true" width="1300px" center>
            <div class="view wordAdmin" :class="caleType == 'big' ? modelClass : ''" v-loading="wordDetailsLoading">
                <div class="curtain" v-if="caleType == 'big'"></div>
                <div :class="modelType == 12 ? 'h200' : 'h130'"></div>
                <div class="operate" :class="modelType == 12 ? 'h230' : 'h163'">
                    <!-- 项目名称 -->
                    <div class="selectProject flex-a-b-c mt20">
                        <div class="flex-a-c">
                            <i class="el-icon-help fs12 mr5"></i>
                            <span class="fs10 mr10">[{{
            (projectData.projectDay ? projectData.projectDay.slice(0, 4) + "-" : "") +
            projectData.projectNo
        }}]</span>
                            <span class="fs10">{{ projectData.projectName }}</span>
                            <el-button class="ml10 wordBtn" size="mini" type="primary" icon="el-icon-upload"
                                :loading="downloadLoading" @click="downloadAllWord()">一键下载</el-button>
                        </div>
                        <div class="el-icon-close fs14 closeWordModel fwbold" @click="showModel(false)"></div>
                    </div>
                    <!-- 模板类型 -->
                    <el-tabs v-model="modelType" @tab-click="getNormalWordDataByPdId">
                        <el-tab-pane :label="row.title" :name="row.id" v-for="(row, index) in tabData"
                            :key="index"></el-tab-pane>
                    </el-tabs>
                    <!-- 人员申请表月份范围 -->
                    <!-- <el-tabs v-model="checkedMonth" v-if="modelType == 12" @tab-click="getNormalWordDataByPdId">
                        <el-tab-pane :label="item.title" :name="item.title" v-for="(item, index) in timeData"
                            :key="index">
                            <span slot="label"><span :class="{ 'newNum': item.newNum }"> {{ item.title }}</span></span>
                        </el-tab-pane>
                    </el-tabs> -->
                    <el-menu :default-active="checkedMonth" v-if="modelType == 12" class="projectAdmin"
                        mode="horizontal" @select="selectDayFun">
                        <el-menu-item :index="item.title" v-for="(item, index) in timeData" :key="index">
                            <el-tooltip v-if="item.newNum" class="item" effect="dark" content="该月份有新增人员"
                                placement="top">
                                <i class="el-icon-warning-outline fwbold"></i>
                            </el-tooltip>
                            <span slot="title"> {{ item.title }}</span>
                        </el-menu-item>
                    </el-menu>
                    <div class="mt10" style="text-align: right;">
                        <el-button type="primary" :loading="addWordDataLoading" icon="el-icon-edit" @click="addWord()"
                            v-if="timeData.length > 0 || modelType != 12">保
                            存</el-button>
                        <el-button class="wordBtn" type="primary" icon="el-icon-upload" @click="downLoadUserList()"
                            v-if="modelType == 12 && timeData.length > 0"
                            :loading="createWordLoading">导出全部人员申请表</el-button>
                        <el-button class="wordBtn" type="primary" @click="createWord()" icon="el-icon-document"
                            :loading="createWordLoading"
                            v-if="(timeData.length > 0 && modelType == 12) || (modelType != 10 && modelType != 12)">生成WORD</el-button>
                        <!-- <el-button class="pdfBtn" type="primary" :disabled="true" @click="createPdf()">生成PDF</el-button> -->
                        <el-button @click="moveProject('up')" icon="el-icon-top"
                            :disabled="year == 'all' || projectData.projectNo == 'RD01'"
                            title="请选择年份才能进行操作">上一份项目</el-button>
                        <el-button @click="moveProject('next')" icon="el-icon-bottom" :disabled="year == 'all'"
                            title="请选择年份才能进行操作">下一份项目</el-button>
                        <el-button type="info" @click="delProjectData()" v-if="modelType != 10" :disabled="!modelData.id"
                            title="恢复后无法还原原本数据" :loading="delLoading">{{modelData.id?'恢复默认数据':'当前为默认数据'}}</el-button>
                    </div>
                    <!-- 进度条 -->
                    <div class="h20" style=" margin:10px auto;">
                        <div v-if="showProgress">
                            <el-progress :text-inside="true" :stroke-width="20" v-if="progressStatus"
                                :percentage="progressNumber" :status="progressStatus"></el-progress>
                            <el-progress :text-inside="true" :stroke-width="20" :percentage="progressNumber"
                                v-else></el-progress>
                        </div>
                    </div>
                </div>
                <div class="createWord">
                    <div class="modelContent">
                        <div v-if="modelType == 1">
                            <model1 ref="model" @keyPosition="keyPosition" :assignment="assignment" :userData="userList"
                                @askCrateData="askCrateData" :projectData="wordProjectData" :modelData="modelData">
                            </model1>
                        </div>
                        <div v-if="modelType == 2">
                            <model2 ref="model" @keyPosition="keyPosition" :assignment="assignment"
                                :projectData="wordProjectData" :modelData="modelData"></model2>
                        </div>
                        <div v-if="modelType == 3">
                            <model3 ref="model" @keyPosition="keyPosition" :assignment="assignment"
                                :projectData="wordProjectData" :modelData="modelData"></model3>
                        </div>
                        <div v-if="modelType == 4">
                            <model4 ref="model" @keyPosition="keyPosition" :assignment="assignment"
                                :projectData="wordProjectData" :modelData="modelData" @askCrateData="askCrateData">
                            </model4>
                        </div>
                        <div v-if="modelType == 5">
                            <model5 ref="model" @keyPosition="keyPosition" :assignment="assignment"
                                :projectData="wordProjectData" :modelData="modelData"></model5>
                        </div>
                        <div v-if="modelType == 6">
                            <model6 ref="model" @keyPosition="keyPosition" :assignment="assignment"
                                :projectData="wordProjectData" :modelData="modelData"></model6>
                        </div>
                        <div v-if="modelType == 7">
                            <model7 ref="model" @keyPosition="keyPosition" :assignment="assignment"
                                :projectData="wordProjectData" :modelData="modelData"></model7>
                        </div>
                        <div v-if="modelType == 8">
                            <model8 ref="model" @keyPosition="keyPosition" :assignment="assignment"
                                :projectData="wordProjectData" :modelData="modelData" @askCrateData="askCrateData">
                            </model8>
                        </div>
                        <div v-if="modelType == 10">
                            <model10 ref="model" @keyPosition="keyPosition" :assignment="assignment"
                                :projectData="wordProjectData" :modelData="modelData">
                            </model10>
                        </div>
                        <div v-if="modelType == 12">
                            <model12 ref="model" @keyPosition="keyPosition" :assignment="assignment"
                                :checkedMonth="checkedMonth" :projectData="wordProjectData" :modelData="modelData">
                            </model12>
                        </div>
                        <div class="flex-c-c showModelStateContent" v-if="false">
                            <div class="textc">
                                <div class="el-icon-folder-delete eIcon"></div>
                                <div>待开发</div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="bbef0 mt20"></div>
            <div style="text-align: right;" class="mt20">
                <el-button type="primary" :loading="addWordDataLoading" @click="addWord()">保
                    存</el-button>
                <el-button @click="nextStep">下一步</el-button>
                <el-button class="wordBtn" type="primary" @click="createWord()">生成WORD</el-button>
                <el-button class="pdfBtn" type="primary" @click="createPdf()">生成PDF</el-button>
            </div> -->
                </div>
            </div>
            <gpt-model ref="gptModel" :GPTMessage="GPTMessage" @clickAnswer="clickAnswer" @caleChange="caleChange"
                @gptResult="gptResult" @gptMove="gptMove" :gptTitle="gptTitle"
                :commonUseMessage="commonUseMessage"></gpt-model>
        </el-dialog>
    </div>
</template>

<script>
import gptModel from "@/components/common/gptModel";
import { setProjectNormalWord, getProjectUserList, getNormalWordDataByPdId, outPutProjectNormalWord, getProjectCYSQMonth, getProjectDataList, delProjectNormalWord } from "@/api/projectAdmin";
import { copyText } from "@/utils";
// 模板
import model1 from "@/pages/projectAdmin/components/model1";
import model2 from "@/pages/projectAdmin/components/model2";
import model3 from "@/pages/projectAdmin/components/model3";
import model4 from "@/pages/projectAdmin/components/model4";
import model5 from "@/pages/projectAdmin/components/model5";
import model6 from "@/pages/projectAdmin/components/model6";
import model7 from "@/pages/projectAdmin/components/model7";
import model8 from "@/pages/projectAdmin/components/model8";
import model10 from "@/pages/projectAdmin/components/model10";
import model12 from "@/pages/projectAdmin/components/model12";
let base64 = require('js-base64').Base64;
export default {
    name: "projectDevelop",
    props: ['projectDetails', 'setType', 'year'],//项目数据 模板填写数据 编辑类型
    components: {
        gptModel,

        model1,
        model2,
        model3,
        model4,
        model5,
        model6,
        model7,
        model8,
        model10,
        model12
    },
    data() {
        return {
            //模板类型
            tabData: [
                { id: '1', title: "立项报告" },
                { id: '2', title: "可行性报告" },
                { id: '3', title: "立项决议" },
                { id: '4', title: "检测报告" },
                { id: '5', title: "验收报告" },
                { id: '6', title: "验证报告" },
                { id: '7', title: "软件测试报告" },
                { id: '8', title: "软件测试简表" },
            ],
            //选中模板类型
            modelType: "1",
            // 进度条数值
            progressNumber: 0,
            // 进度条状态
            progressStatus: "",
            // 是否显示进度条
            showProgress: false,
            // 保存数据加载状态
            addWordDataLoading: false,
            // 当前选中的研发企业
            checkedCompany: {},
            // 用于延迟加载进度条的定时器
            timeFun: null,
            // 生成word文件加载状态
            createWordLoading: false,
            // 生成pdf文件加载状态
            createPdfLoading: false,
            // 研发人员数据
            userList: [],
            // 显示弹窗
            showCreateModel: false,
            // 模板填写数据
            modelData: {},
            // 模板项目数据
            wordProjectData: {},
            // 控制左右幕布
            modelClass: "",
            // 访问模板填写数据加载状态
            wordDetailsLoading: false,
            // 一键下载 下载状态
            downloadLoading: false,
            //--gpt窗口配置
            // gpt窗口标题
            gptTitle: "",
            // gpt窗口提问预值
            GPTMessage: "",
            // gpt窗口快捷提问数据
            commonUseMessage: [
                '如何增加申报项目成功率',
                '申报项目该准备什么材料',
                '项目申报成功后资金要多久才到账',
            ],
            // gpt窗口缩放类型
            caleType: "",
            // gpt窗口单击答案内容要赋值的数据
            assignment: {},
            // 赋值内容的对象名
            askKey: "",
            askContent: { ask: "", content: "" },
            checkedMonth: "",
            timeData: [],
            projectData: {},
            delLoading:false,
        };
    },
    watch: {
        projectDetails: {
            handler(data) {
                this.projectData = data;
            },
            deep: true,
            immediate: true,
        },
        // 监听项目数据
        projectData: {
            handler(data) {
                if (data.pcId) {
                    // 1: "产品", 2: "软件", 3: "技术",
                    if (data.projectType == 1) {
                        this.tabData = [
                            { id: '1', title: "立项报告" },
                            { id: '2', title: "可行性报告" },
                            { id: '3', title: "立项决议" },
                            { id: '5', title: "验收报告" },
                            { id: '4', title: "检测报告" },
                            // { id: '6', title: "验证报告" },
                            // { id: '7', title: "软件测试报告" }
                            // { id: '9', title: "分配说明" },
                            // { id: '11', title: "人员名单" },
                            { id: '10', title: "RD网上填写" },
                            { id: '12', title: "人员变更申请表" },
                        ];
                    } else if (data.projectType == 2) {
                        this.tabData = [
                            { id: '1', title: "立项报告" },
                            { id: '2', title: "可行性报告" },
                            { id: '3', title: "立项决议" },
                            // { id: '4', title: "检测报告" },
                            { id: '5', title: "验收报告" },
                            // { id: '6', title: "验证报告" },
                            // { id: '7', title: "软件测试报告" },
                            { id: '8', title: "软件测试简表" },
                            // { id: '9', title: "分配说明" },
                            // { id: '11', title: "人员名单" },
                            { id: '10', title: "RD网上填写" },
                            { id: '12', title: "人员变更申请表" },
                        ];
                    } else if (data.projectType == 3) {
                        this.tabData = [
                            { id: '1', title: "立项报告" },
                            { id: '2', title: "可行性报告" },
                            { id: '3', title: "立项决议" },
                            // { id: '4', title: "检测报告" },
                            { id: '5', title: "验收报告" },
                            { id: '6', title: "验证报告" },
                            // { id: '7', title: "软件测试报告" }
                            // { id: '9', title: "分配说明" },
                            // { id: '11', title: "人员名单" },
                            { id: '10', title: "RD网上填写" },
                            { id: '12', title: "人员变更申请表" },
                        ];
                    } else if (data.projectType == 4) {
                        this.tabData = [
                            { id: '1', title: "立项报告" },
                            { id: '2', title: "可行性报告" },
                            { id: '3', title: "立项决议" },
                            { id: '5', title: "验收报告" },
                            { id: '4', title: "检测报告" },
                            // { id: '6', title: "验证报告" },
                            { id: '10', title: "RD网上填写" },
                            { id: '12', title: "人员变更申请表" },
                        ];
                    }
                    let isInit = true;
                    for (let row of this.tabData) {
                        if (row.id == this.modelType) {
                            isInit = false;
                        }
                    }
                    if (isInit) {
                        this.modelType = "1";
                    }
                    this.getNormalWordDataByPdId();//获取申报数据
                    this.getDayData();//获取人员变更表时间范围
                }
            },
            deep: true,
            immediate: true,
        }
    },
    methods: {
        /**@method 删除项目数据 */
        delProjectData() {
            let params = {
                dataId: this.modelData.id,
                modelType: this.modelType,
            };
            this.delLoading = true;
            delProjectNormalWord(params).then(res => {
                this.delLoading = false;
                if (res.code == 200) {
                    this.$message.success("删除旧数据完成，重新为你生成默认数据。");
                    this.getNormalWordDataByPdId();
                } else {
                    this.$message.error(res.message);
                }
            }).catch(err => {
                this.delLoading = false;
                if (err.code != 201) {
                    this.$message.error(err.message);
                }
            })
        },
        selectDayFun(day) {
            this.checkedMonth = day;
            this.getNormalWordDataByPdId();
        },
        /**@method 获取时间范围 */
        getDayData() {
            this.timeData = [];
            this.checkedMonth = "";
            getProjectCYSQMonth({ pdId: this.projectData.pdId }).then(res => {
                if (res.data) {
                    this.timeData = res.data;
                    this.checkedMonth = res.data[0].title;
                }
            })
        },
        /**@method 一键全部导出 */
        downloadAllWord() {
            let modelTypeStr = [];
            for (let row of this.tabData) {
                if (row.id <= 8) {
                    modelTypeStr.push(row.id);
                }
            }
            let params = {
                pdId: this.projectData.pdId,
                modelTypeStr: modelTypeStr.join(",")
            };
            this.$message.info("下载中...");
            this.downloadLoading = true;
            outPutProjectNormalWord(params).then(res => {
                this.downloadLoading = false;
                if (res.data) {
                    let i = 1;
                    for (let url of res.data) {
                        setTimeout(() => {
                            let link = document.createElement('a');
                            link.href = this.$store.state.apiUrl + '/crmPc/project/downloadWordFile?fileName=' + url;
                            link.click();
                        }, i * 1000);
                        i++;
                    }
                } else {
                    this.$message.error(res.message)
                }
            }).catch(err => {
                this.downloadLoading = false;
                if (err.code != 201) {
                    this.$message.error(res.message)
                }
            })
        },
        closeFun() {
            this.$emit("close")
        },
        /**@method 获取word填写内容 */
        getNormalWordDataByPdId() {
            this.wordProjectData = this.projectData;
            this.wordDetailsLoading = true;
            let params = {
                pdId: this.projectData.pdId,
                modelType: this.modelType
            };
            if (this.modelType == 12) {//判断申请表
                params.year = this.checkedMonth.split("-")[0];
                params.month = this.checkedMonth.split("-")[1];
            }
            getNormalWordDataByPdId(params).then(res => {
                this.wordDetailsLoading = false;
                if (res.data) {
                    sessionStorage.setItem("checkedProjectCompany", JSON.stringify(res.data.projectCompany))
                    let row = res.data.projectData ? res.data.projectData : {};
                    row.projectUserList = this.projectData.projectUserList;
                    this.wordProjectData = row;
                    this.modelData = res.data.wordData;
                } else {
                    this.modelData = {};
                    sessionStorage.setItem("checkedProjectCompany", JSON.stringify(this.checkedCompany))
                }
            }).catch(err => {
                this.modelData = {};
                this.wordDetailsLoading = false;
                sessionStorage.setItem("checkedProjectCompany", JSON.stringify(this.checkedCompany))
            })
        },
        /**@method 显示模态框时的操作 */
        showGpt() {
            // this.$refs.gptModel.showHideGptModel('big');
        },
        /**@method 显示模态框 */
        showModel(show) {
            let checkedCompany = JSON.parse(sessionStorage.getItem("checkedProjectCompany"));
            this.checkedCompany = checkedCompany;
            this.showCreateModel = show;
            if (show) {
                this.modelType = "1";
                this.showGpt();
                this.getUserList();
            } else {
                this.GPTMessage = "";
                // this.$refs.gptModel.showHideGptModel('centre');
                this.removePostiton();
            }
        },
        /**@method 去除赋值定位 */
        removePostiton() {
            this.gptTitle = "AI人工智能 ChatGPT";
            this.askKey = "";
        },
        /**@method 监听gpt窗口的移动 */
        gptMove(obj) {
            // if (obj.x > (window.innerWidth / 2)) {
            this.modelClass = "showGptRight"
            // } else {
            // this.modelClass = "showGptLeft"
            // }
        },
        /**@method GPT点击答案回调 */
        clickAnswer(str) {
            if (!this.askKey) {
                this.copyFun(str);
                return;
            } else {
                let msg = "";
                if (this.askKey == "innovate" && this.askKey == "core") {
                    msg = str
                } else {
                    msg = str.replace(/\n/ig, `\n    `);
                }
                this.assignment = { key: this.askKey, gptResult: msg }
            }
        },
        /**@method 回答结果 */
        gptResult(res) {
            let msg = "";
            if (this.askKey == "innovate" && this.askKey == "core") {
                msg = res
            } else {
                msg = res.replace(/\n/ig, `\n    `);
            }
            this.assignment = { key: this.askKey, gptResult: msg }
            if (this.askKey === 'createData') {
                let answerData = localStorage.getItem("answerData") ? JSON.parse(localStorage.getItem("answerData")) : [];
                this.askContent.content = msg;
                answerData.unshift(this.askContent);
                localStorage.setItem("answerData", JSON.stringify(answerData.slice(0, 30)));
                this.askKey = "";
                // this.askContent = { ask: "", content: "" };
            }
        },
        /**@method 监听缩放 */
        //bigRight bigLeft centre small
        caleChange(type) {
            this.caleType = type;
        },
        /**@method 复制 */
        copyFun(text) {
            copyText(text)
            this.$message.success('复制成功！');
        },
        /**@method 提问并转化为数组 */
        askCrateData(ask) {
            this.askKey = "createData";
            this.askContent.ask = ask.slice(0, 30) + "...";
            this.$refs.gptModel.sendMessage(ask);
        },
        /**@method 在提问栏填入预值 */
        keyPosition(data) {
            this.askKey = data.key;
            this.gptTitle = "Key：" + data.title;
            this.GPTMessage = data.problem;
        },
        /**@method 获取人员数据表*/
        getUserList() {
            getProjectUserList({
                pageSize: 100,
                pageNum: 1,
                pcId: this.checkedCompany.pcId,
            }).then(res => {
                if (res.data) {
                    for (let row of res.data.pageInfo.list) {
                        this.userList.push(row.userName);
                    }
                }
            })
        },
        /**@method 保存
         * isDownload 是否下载word
         */
        addWord(isDownload) {
            try {
                if (!this.addWordDataLoading && !this.createWordLoading) {
                    if (isDownload) {
                        this.createWordLoading = true;
                    } else {
                        this.addWordDataLoading = true;
                    }
                    this.showProgressFun("start");
                    let content = {};
                    for (let key in this.$refs.model.formData) {
                        if (key == 'projectList') {
                            let projectList = [];
                            for (let row of this.$refs.model.formData[key]) {
                                let obj = { ...row };
                                obj.time = row.time ? row.time.join(" 至 ") : "";
                                projectList.push(obj);
                            }
                            content[key] = projectList;
                        } else if (typeof this.$refs.model.formData[key] == 'object') {
                            content[key] = JSON.stringify(this.$refs.model.formData[key])
                        } else {
                            content[key] = this.$refs.model.formData[key];
                        }
                    }
                    let params = {
                        pdId: this.projectData.pdId,
                        modelType: this.modelType,
                        content: content
                    };
                    if ($(".companyLogo")) {
                        params.logoWidth = $(".companyLogo").width() / 2;
                        params.logoHeigh = $(".companyLogo").height() / 2;
                    }
                    if (isDownload) {
                        params.isDownload = true;
                    }
                    if (this.setType == 'update') {
                        params.dataId = this.modelData.dataId;
                    }
                    if (this.modelType == 12) {//申请表添加年和月
                        params.year = this.checkedMonth.split("-")[0];
                        params.month = this.checkedMonth.split("-")[1];
                    }
                    setProjectNormalWord(params).then(res => {
                        if (isDownload) {
                            this.createWordLoading = false;
                        } else {
                            this.addWordDataLoading = false;
                        }
                        if (res.code === 200) {
                            this.showProgressFun("end")
                            if (isDownload) {
                                // window.open(this.$store.state.apiUrl + '/crmPc/project/downloadWordFile?fileName=' + res.data);
                                let link = document.createElement('a');
                                link.href = this.$store.state.apiUrl + '/crmPc/project/downloadWordFile?fileName=' + res.data;
                                link.click();
                            }
                        } else {
                            this.$message.error(res.message)
                            this.showProgressFun("error")
                        }
                    }).catch(err => {
                        if (isDownload) {
                            this.createWordLoading = false;
                        } else {
                            this.addWordDataLoading = false;
                        }
                        this.showProgressFun("error")
                        if (err.code === 201) {
                            this.$message.error(err.message)
                        }
                    })
                }
            } catch (err) {
                if (isDownload) {
                    this.createWordLoading = false;
                } else {
                    this.addWordDataLoading = false;
                }
            }
        },
        /**@method 下一个项目/下一个项目 */
        moveProject(type) {
            let projectNo = Number(this.projectData.projectNo.replace("RD", ""));
            let message = "";
            if (type === 'up') {
                message = "没有上一个项目"
                projectNo--;
            } else {
                message = "没有下一个项目"
                projectNo++;
            }
            projectNo = projectNo < 10 ? "RD0" + projectNo : "RD" + projectNo;
            let params = {
                pageSize: 5,
                pageNum: 1,
                selectYear: this.year,
                pcId: this.checkedCompany.pcId,
                projectNo: projectNo
            };
            getProjectDataList(params).then(res => {
                if (res.data) {
                    this.projectData = res.data.pageInfo.list[0];
                    this.getNormalWordDataByPdId();
                } else {
                    this.$message.info(message)
                }
            })
        },
        /**@method 下载全部人员申请表 */
        downLoadUserList() {
            let monthStr = [];
            for (let row of this.timeData) {
                if (row.newNum) {
                    monthStr.push(row.title);
                }
            }
            if (monthStr.length == 0) {
                this.$message.error("没有可以导出的人员申请表！");
                return;
            }
            this.createWordLoading = true;
            let params = {
                pdId: this.projectData.pdId,
                modelTypeStr: 12,
                monthStr: monthStr.join(",")
            };
            this.$message.info("下载中...");
            outPutProjectNormalWord(params).then(res => {
                this.createWordLoading = false;
                if (res.data) {
                    let i = 1;
                    for (let url of res.data) {
                        setTimeout(() => {
                            let link = document.createElement('a');
                            link.href = this.$store.state.apiUrl + '/crmPc/project/downloadWordFile?fileName=' + url;
                            link.click();
                        }, i * 1000);
                        i++;
                    }
                } else {
                    this.$message.error(res.message);
                }
            }).catch(err => {
                this.createWordLoading = false;
                if (err.code != 201) {
                    this.$message.error(res.message);
                }
            })
        },
        /**@method　生成word */
        createWord() {
            this.addWord(true);
        },
        /**@method　生成pdf */
        createPdf() {
            this.$message.info("待开发");
        },
        /**@method 进度条状态 */
        showProgressFun(status) {
            if (this.timeFun) {
                clearTimeout(this.timeFun)
                this.timeFun = null;
            }
            if (status === 'start') {
                this.progressStatus = "";
                this.showProgress = true;
                this.progressNumber = 0;
                this.timeFun = setTimeout(() => {
                    this.progressNumber = 95;
                }, 300)
            } else if (status == 'end') {
                this.progressNumber = 100;
                setTimeout(() => {
                    this.showProgress = false;
                }, 2000)
            } else if (status == 'error') {
                this.progressNumber = 95;
                this.progressStatus = "exception"
                setTimeout(() => {
                    this.showProgress = false;
                }, 2000)
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.projectAdmin {
    /deep/.el-menu-item.is-active::after {
        background: transparent !important;
    }
}

.newNum {
    color: #3a78ef !important;
    font-weight: bold;
}

.showGptRight {
    width: calc(100% - 510px);
    margin-right: 510px;

    .curtain {
        background: rgba(0, 0, 0, 0.3);
        width: 510px;
        position: absolute;
        top: 0;
        right: -510px;
        height: 100%;
        z-index: 20;
    }

    .operate {
        width: calc(100% - 545px);
    }
}

.showGptLeft {
    width: calc(100% - 510px);
    margin-left: 510px;

    .operate {
        margin-left: 510px;
        width: calc(100% - 510px);
    }

    .curtain {
        background: rgba(0, 0, 0, 0.3);
        width: 510px;
        position: absolute;
        left: -510px;
        height: 100%;
        z-index: 20;
    }
}

.closeWordModel {
    cursor: pointer;
    color: #000;
}

.wordAdmin {
    position: relative;
}

.modelContent {
    padding: 50px 0;
    overflow-x: scroll;
    background: #f1f2f4;
}

.operate {
    width: 100%;
    position: fixed;
    background: #fff;
    top: 45px;
    left: 0;
    z-index: 5;
    padding: 0 35px;
}

/deep/ .el-upload--text {
    width: 90px;
    height: 36px;
    border: 0px;
}


.selectProject {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 700;
    color: #51CBCD;

    .el-icon-close {
        cursor: pointer;
    }
}

.eupload.el-icon-loading {
    font-size: 67px;
    color: #c0c4cc;
    margin: 40px 0 16px;
    line-height: 50px;
}

.showModelStateContent {
    height: 630px;
    max-width: 842px;
    margin-top: 10px;
    padding: 20px;

    .eIcon {
        font-size: 65px;
    }

    a {
        cursor: pointer;
        color: #51CDCB;
        margin-left: 5px;
    }
}
</style>