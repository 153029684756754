<template>
    <div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml10">管理文件>可行性验证报告报告V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-GO-R009</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <el-image class="companyLogo" v-if="checkedCompany.logo" :src="checkedCompany.logo"></el-image>
            <div class="pl30 pr30 wordSetContent">
                <div class="wordCodeTable">
                    <div class="flex-a-c" style="border-bottom: 1px solid #000;">
                        <div class="wordCodeTitle flex-c-c">立项编号</div>
                        <div class="flex-c-c wordCodeContent">
                            <div class="zdhq">{{ checkedProject.year }}-{{ checkedProject.projectNo }}</div>
                        </div>
                    </div>
                    <div class="flex-a-c">
                        <div class="wordCodeTitle flex-c-c">报告编号</div>
                        <div class="flex-c-c wordCodeContent">
                            <div class="zdhq">{{ checkedProject.year }}TEST-{{ checkedProject.projectNo }}</div>
                        </div>
                    </div>
                </div>
                <div class="projectTitle">
                    <div class="flex-c-c">
                        可行性验证报告
                    </div>
                    <div class="textc pjTitle mt10 mb10">FEASIBILITY REPORT</div>
                </div>
                <div class="wordTable w750 marginAuto">
                    <div class="wordTableRow h60">
                        <div class="itemTitle rightBorder flex-c-c w160">项目名称</div>
                        <div class="itemTitle flex-c-c w600">
                            <!-- <el-input v-model="formData.name" class="w400" placeholder="项目名称"></el-input> -->
                            <div class="zdhq">{{ checkedProject.projectName }}</div>
                        </div>
                    </div>
                    <div class="wordTableRow h60">
                        <div class="itemTitle rightBorder flex-c-c w160">项目负责人</div>
                        <div class="itemTitle flex-c-c w600">
                            <div class="zdhq">{{ checkedProject.headName }}</div>
                        </div>
                    </div>
                    <div class="wordTableRow h60">
                        <div class="itemTitle rightBorder flex-c-c w160">验证工程师</div>
                        <div class="itemTitle flex-c-c w600">
                            <!-- <el-input v-model="formData.name" class="allShow" placeholder="负责人"></el-input> -->
                            <div class="zdhq">{{ checkedProject.testName }}</div>
                        </div>
                    </div>
                    <div class="wordTableRow h60">
                        <div class="itemTitle rightBorder flex-c-c w160">验证日期</div>
                        <div class="itemTitle flex-c-c w600">
                            <el-date-picker v-model="formData.endDay" class="allShow tableInput" value-format="yyyy-MM-dd"
                                format="yyyy年MM月dd日" placeholde="文件日期" type="date" placeholder="选择日期">
                            </el-date-picker>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>1</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml10">管理文件>可行性验证报告报告V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-GO-R009</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="wordContentTitle">一、技术基本信息</div>
                <div class="wordTable w100p mt30 marginAuto">
                    <div class="wordTableRow h80">
                        <div class="itemTitle rightBorder w180 flex-c-c fw600 " style="background:rgb(255 231 202)">技术名称
                        </div>
                        <div class="itemTitle w800 pl50">
                            <div class="zdhq">{{ checkedProject.projectName }}</div>
                        </div>
                    </div>
                    <div class="wordTableRow h80">
                        <div class="itemTitle rightBorder w180 flex-c-c fw600 " style="background:rgb(255 231 202)">验证时间
                        </div>
                        <div class="itemContent flex-c-c w800 pl50">
                            <el-date-picker v-model="formData.endDay" class="allShow" value-format="yyyy-MM-dd"
                                format="yyyy年MM月dd日" placeholde="文件日期" type="date" placeholder="选择日期">
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="wordTableRow h80">
                        <div class="itemTitle rightBorder w180 flex-c-c fw600" style="background:rgb(255 231 202)">采用标准
                        </div>
                        <div class="itemTitle w800 pl50">
                            公司内控《企业标准》
                        </div>
                    </div>
                    <div class="wordTableRow h80">
                        <div class="itemTitle rightBorder w180 flex-c-c fw600 " style="background:rgb(255 231 202)">备 注
                        </div>
                        <div class="itemContent flex-c-c w800">

                        </div>
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>2</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml10">管理文件>可行性验证报告报告V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-GO-R009</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="wordContentTitle">二、验证指标</div>
                <!-- <div class="wordTable w100p marginAuto">
                    <div class="wordTableRow h60">
                        <div class="itemContent rightBorder w160 flex-c-c">
                            技术指标
                        </div>
                        <div class="itemTitle rightBorder w340 flex-c-c textc">
                            规定指标
                        </div>
                        <div class="itemTitle rightBorder w340 flex-c-c textc">
                            技术功能特性
                        </div>
                        <div class="itemTitle w98 flex-c-c textc">
                            结果
                        </div>
                    </div>
                </div>
                <div class="wordTable w100p marginAuto insert" v-for="(row, index) in resultList" :key="index">
                    <div class="wordTableRow h60 setContainer">
                        <div class="itemContent rightBorder w160 flex-c-c">
                            <el-input type="textarea" class="textIndent" maxlength="1000" show-word-limit placeholder="技术指标" :rows="2" v-model="row.num"
                                ></el-input>
                        </div>
                        <div class="itemContent rightBorder w340 flex-c-c">
                            <el-input type="textarea" class="textIndent" maxlength="1000" show-word-limit placeholder="技术功能特性" :rows="2" v-model="row.name"
                                ></el-input>
                        </div>
                        <div class="itemContent rightBorder w340 flex-c-c">
                            <el-input type="textarea" class="textIndent" maxlength="1000" show-word-limit placeholder="技术功能特性" :rows="2" v-model="row.content"
                                ></el-input>
                        </div>
                        <div class="itemContent w98 flex-c-c">
                            <el-input type="textarea" class="textIndent" maxlength="1000" show-word-limit placeholder="结果" :rows="2" v-model="row.result"></el-input>
                        </div>
                        <div class="tool">
                            <div class="flex-c-c">
                                <i class="eicon el-icon-caret-top" @click="topMove(index, 'resultList')"></i>
                                <i class="eicon el-icon-plus" @click="addRow(index, 'resultList')"></i>
                            </div>
                            <div class="flex-c-c">
                                <i class="eicon el-icon-caret-bottom" @click="bottomMove(index, 'resultList')"></i>
                                <i class="eicon el-icon-delete" @click="delRow(index, 'resultList')"></i>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="wordTable w100p mt30 marginAuto setContainer">
                    <div class="wordTableRow h80">
                        <div class="itemTitle rightBorder w180 flex-c-c fw600 " style="background:rgb(255 231 202)">技术指标1:
                        </div>
                        <div class="itemContent flex-c-c w800">
                            <el-input v-model="formData.num1" type="textarea" class="textIndent" maxlength="1000"
                                show-word-limit rows="2" :placeholder="placeholderData.num1"
                                @focus="keyPosition('num1', '技术指标1', '')"></el-input>
                        </div>
                    </div>
                    <div class="wordTableRow h80">
                        <div class="itemTitle rightBorder w180 flex-c-c" style="background:rgb(255 231 202)">规定指标
                        </div>
                        <div class="itemContent flex-c-c w800">
                            <el-input type="textarea" class="textIndent" maxlength="1000" show-word-limit
                                @focus="keyPosition('name1', '规定指标', '')" :placeholder="placeholderData.name1" :rows="2"
                                v-model="formData.name1"></el-input>
                        </div>
                    </div>
                    <div class="wordTableRow h80">
                        <div class="itemTitle rightBorder w180 flex-c-c" style="background:rgb(255 231 202)">技术功能特性
                        </div>
                        <div class="itemContent flex-c-c w800">
                            <el-input type="textarea" class="textIndent" maxlength="1000" show-word-limit
                                @focus="keyPosition('content1', '技术功能特性', '')" :placeholder="placeholderData.content1"
                                :rows="2" v-model="formData.content1"></el-input>
                        </div>
                    </div>
                    <div class="wordTableRow h80">
                        <div class="itemTitle rightBorder w180 flex-c-c " style="background:rgb(255 231 202)">结 果
                        </div>
                        <div class="itemContent flex-c-c w800">
                            <el-input type="textarea" class="textIndent" maxlength="1000" show-word-limit
                                @focus="keyPosition('result1', '结果', '')" :placeholder="placeholderData.result1" :rows="2"
                                v-model="formData.result1"></el-input>
                        </div>
                    </div>
                </div>
                <div class="wordTable w100p mt30 marginAuto setContainer">
                    <div class="wordTableRow h80">
                        <div class="itemTitle rightBorder w180 flex-c-c fw600 " style="background:rgb(255 231 202)">技术指标2:
                        </div>
                        <div class="itemContent flex-c-c w800">
                            <el-input v-model="formData.num2" @focus="keyPosition('num2', '指标', '')" class="allShow"
                                maxlength="1000" show-word-limit :placeholder="placeholderData.num2"></el-input>
                        </div>
                    </div>
                    <div class="wordTableRow h80">
                        <div class="itemTitle rightBorder w180 flex-c-c" style="background:rgb(255 231 202)">规定指标
                        </div>
                        <div class="itemContent flex-c-c w800">
                            <el-input type="textarea" class="textIndent" maxlength="1000" show-word-limit
                                @focus="keyPosition('name2', '规定指标', '')" :placeholder="placeholderData.name2" :rows="2"
                                v-model="formData.name2"></el-input>
                        </div>
                    </div>
                    <div class="wordTableRow h80">
                        <div class="itemTitle rightBorder w180 flex-c-c" style="background:rgb(255 231 202)">技术功能特性
                        </div>
                        <div class="itemContent flex-c-c w800">
                            <el-input type="textarea" class="textIndent" maxlength="1000" show-word-limit
                                @focus="keyPosition('content2', '技术功能特性', '')" :placeholder="placeholderData.content2"
                                :rows="2" v-model="formData.content2"></el-input>
                        </div>
                    </div>
                    <div class="wordTableRow h80">
                        <div class="itemTitle rightBorder w180 flex-c-c " style="background:rgb(255 231 202)">结 果
                        </div>
                        <div class="itemContent flex-c-c w800">
                            <el-input type="textarea" class="textIndent" maxlength="1000" show-word-limit
                                @focus="keyPosition('result2', '结果', '')" :placeholder="placeholderData.result2" :rows="2"
                                v-model="formData.result2"></el-input>
                        </div>
                    </div>
                </div>

                <div class="wordContentTitle mt50">三、验证结论</div>
                <div class="mt30 marginAuto">
                    <div>
                        <el-input v-model="formData.content" type="textarea" maxlength="1000" show-word-limit
                            @focus="keyPosition('content', '验证结论', placeholderData.content)"
                            class="textIndent textInput w100p" :autosize="{ minRows: 6 }"
                            :placeholder="placeholderData.content"></el-input>
                    </div>
                    <div class="w300 ml50 flex-a-b-c mt50">
                        <div class="flex-a-c">
                            <div class="iconCheck el-icon-check mr5"></div>
                            <div>验证成功</div>
                        </div>
                        <div class="flex-a-c">
                            <div class="iconCheck mr5"></div>
                            <div>验证不成功</div>
                        </div>
                    </div>
                    <div class="mt100 ml50 w750 flex-a-b-c">
                        <div class="d-flex">
                            <div>检测人员签名：</div>
                            <div class="underline w180"></div>
                        </div>
                        <div class="d-flex">
                            <div>日期：</div>
                            <div class="underline w180">{{ convertChinese(formData.endDay) }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>3</div>
                <div class="squareRight"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['projectData', 'assignment', 'modelData'],
    data() {
        return {
            checkedProject: {},
            checkedCompany: {},
            resultList: [
                { num: "", name: "", content: "", result: "" },
            ],
            formData: {
                endDay: "",//时间
                num1: "",
                name1: "",
                content1: "",
                result1: "",
                num2: "",
                name2: "",
                content2: "",
                result2: "",
                content: "经验证，",
            },
            placeholderData: {
                num1: "该技术在自身领域中国家、行业或企业规定的技术标准；或参考国家工业资源综合利用先进适用工艺技术设备目录（如电池回收技术的技术指标——电池级磷酸铁/碳酸锂回收率）",
                name1: "国家、行业或企业规定的具体数值（如回收率95%以上）",
                content1: "该技术其他的功能描述（可将磷铁渣中夹带部分锂大量回收，还可以将磷铁元素回收。）",
                result1: "实际应用或试验指标（回收率96%）",
                num2: "该技术在自身领域中国家、行业或企业规定的技术标准；或参考国家工业资源综合利用先进适用工艺技术设备目录（如电池回收技术的技术指标——电池级磷酸铁/碳酸锂回收率）",
                name2: "国家、行业或企业规定的具体数值（如回收率95%以上）",
                content2: "该技术其他的功能描述（可将磷铁渣中夹带部分锂大量回收，还可以将磷铁元素回收。）",
                result2: "实际应用或试验指标（回收率96%）",
                content: "结论内容说明",
            },
        }
    },
    watch: {
         //监听赋值数据，赋值
         assignment: {
            handler(data) {
                if (data.key) {
                    if (data.key.indexOf("_") != -1) {
                        let keys = data.key.split("_");//0 key 1 列表key 2 下标
                        this.formData[keys[0]][keys[2]][keys[1]] = data.gptResult;
                        // for (let row of this.formData[keys[0]][keys[1]]) {
                        //     row[keys[1]] = data.gptResult;
                        // }
                    } else {
                        this.formData[data.key] = data.gptResult;
                    }
                    if (this.$refs[data.key]) {
                        this.$refs[data.key].focus();
                    }
                }
            },
            deep: true,
            immediate: true
        },
        //监听填写数据
        modelData: {
            handler(data) {
                if (data && JSON.stringify(data) != '{}') {
                    this.createData();
                    for (let key in data) {
                        let arrKey = []
                        if (arrKey.indexOf(key) != -1) {
                            this.formData[key] = data[key] ? JSON.parse(data[key]) : [];
                        } else {
                            this.formData[key] = data[key] ? data[key] : "";
                        }
                    }
                    this.formData.projectName = this.checkedProject.projectName;
                } else {
                    this.formData = {
                        endDay: "",//时间
                        num1: "",
                        name1: "",
                        content1: "",
                        result1: "",
                        num2: "",
                        name2: "",
                        content2: "",
                        result2: "",
                        content: "经验证，",
                    };
                    this.createData();
                }
            },
            deep: true
        },
    },
    methods: {
        /**@method 定位要赋值的数据 */
        keyPosition(key, title, problem = "") {
            this.$emit("keyPosition", { key, title, problem });
        },
        createData() {
            let checkedProject = this.projectData;//项目数据

            this.checkedCompany = JSON.parse(sessionStorage.getItem("checkedProjectCompany"));
            this.checkedProject = {
                ...checkedProject,
                projectName: checkedProject.projectName.replace("的研究开发", ""),
                year: checkedProject.startDay ? checkedProject.startDay.slice(0, 4) : "",
                startMonth: checkedProject.startDay ? checkedProject.startDay.slice(0, 7) : "",
                endMonth: checkedProject.endDay ? checkedProject.endDay.slice(0, 7) : "",
                startDayText: this.convertChinese(checkedProject.startDay),
                endDayText: this.convertChinese(checkedProject.endDay),
                projectDayText: this.convertChinese(checkedProject.projectDay),
            };
            this.formData.projectName = this.checkedProject.projectName;
            this.formData.endDay = new Date(this.checkedProject.endDay).getFullYear() + '-' + (new Date(this.checkedProject.endDay).getMonth() + 1) + "-15";
        },
        convertChinese(time) {
            if (time) {
                let newDate = new Date(time)
                let y = newDate.getFullYear();
                let m = newDate.getMonth() + 1;
                let d = newDate.getDate();
                return y + '年' + (m < 10 ? '0' + m : m) + '月' + (d < 10 ? '0' + d : d) + '日'
            }
            return "";
        },
        /**@method 列表上移 */
        topMove(i, dataKey) {
            if (i > 0) {
                if (dataKey == 'resultList') {
                    let resultList = JSON.parse(JSON.stringify(this.resultList));
                    let replaceData = { ...resultList[i - 1] }
                    let currentData = { ...resultList[i] };
                    this.$set(this.resultList, i, replaceData);
                    this.$set(this.resultList, (i - 1), currentData);
                }

            }
        },
        /**@method 整理序号 */
        getTableCode() {
            for (let key in this.resultList) {
                this.$set(this.resultList[key], 'num', (Number(key) + 1));
            }
        },
        bottomMove(i, dataKey) {
            if (this.resultList.length > (i + 1)) {
                if (dataKey == 'resultList') {
                    let resultList = JSON.parse(JSON.stringify(this.resultList));
                    let replaceData = { ...resultList[i + 1] }
                    let currentData = { ...resultList[i] };
                    this.$set(this.resultList, i, replaceData);
                    this.$set(this.resultList, (i + 1), currentData);
                }
            }
        },
        addRow(i, dataKey) {
            if (dataKey == 'resultList') {
                if (this.resultList.length == (i + 1)) {
                    this.resultList.push({ num: "1", name: "", content: "" });
                } else {
                    let resultList = [];
                    for (let key in this.resultList) {
                        let row = this.resultList[key];
                        if ((i + 1) == key) {
                            resultList.push({ num: "1", name: "", content: "" });
                        }
                        resultList.push(row);
                    }
                    this.resultList = resultList;
                }
                this.getTableCode();
            }
        },
        delRow(i, dataKey) {
            if (this.resultList.length>1) {
                if (dataKey == 'resultList') {
                    this.resultList.splice(i, 1);
                }
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.companyLogo {
    height: 100px;
    position: absolute;
    top: 100px;
    left: 80px;
}

.zdhq {
    // color: #E6A23C !important;
}

.showModelContent div,
.showModelContent span {
    color: #000;
    border-color: #000;

    /deep/input {
        border: none;
        color: #E6A23C; // #000;
    }

    /deep/.el-textarea__inner,
    /deep/.el-input__inner {
        border: none;
        color: #E6A23C; // #000;
    }

    .tableInput {
        /deep/.el-input__inner {
            text-align: center;
        }
    }
}

.wordSetContent div {
    font-size: 20px;
    letter-spacing: 2px;
}

.showModelContent {
    background: #fff;
    position: relative;
    width: 1100px;
    min-height: 1500px;
    margin: 0 auto;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 50px;
    padding-bottom: 100px;

    .wordContentTitle {
        font-size: 30px;
        padding: 10px 0;
        font-weight: 600;
    }

    .modelHeader {
        div {
            font-size: 16px;
        }

        .modelHeaderTitle {
            border-bottom: 1px solid #000;
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordPage {
        position: absolute;
        bottom: 50px;
        width: 1000px;
        height: 30px;
        font-size: 20px;

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordCodeTable {
        width: 350px;
        margin-left: auto;
        border: 2px solid #000;
        margin-bottom: 150px;

        .wordCodeTitle {
            width: 40%;
            height: 43px;
            border-right: 1px solid #000;
            font-size: 18px;
        }

        .wordCodeContent {
            width: 60%;
            height: 43px;
            font-size: 16px;

            div {
                letter-spacing: 0px;
            }

            /deep/input {
                height: 42px;
                line-height: 42px;
            }
        }
    }

    .projectTitle div {
        font-size: 45px !important;
    }

    .projectTitle {
        margin-bottom: 200px;
    }

    .wordSetContent {
        .wordTable {
            border: 1px solid #000;

            .wordTableRow {
                display: flex;
                align-items: center;
                border-bottom: 1px solid #000;
                position: relative;

                .allShow {
                    width: 100%;
                    height: 60px;
                    border: none;
                }

                /deep/.allShow input {
                    width: 100%;
                    font-size: 20px !important;
                }

                /deep/input {
                    height: 56px;
                    line-height: 56px;
                    font-size: 20px !important;
                }

                .itemTitle {
                    font-size: 20px;
                    padding: 0 10px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                }

                .itemContent {
                    height: 60px;
                    overflow: hidden;

                    div {
                        font-size: 20px;
                    }

                    /deep/.el-range-editor--small.el-input__inner {
                        height: 58px;
                    }

                    /deep/.el-range-separator {
                        padding: 0;
                        line-height: 50px;
                        font-size: 20px;
                        width: 20px;
                    }

                    /deep/.el-input__inner {
                        padding-right: 0;
                    }

                    /deep/.el-range__close-icon {
                        font-size: 20px;
                        line-height: 53px;
                    }
                }

                .rightBorder {
                    border-right: 1px solid #000;
                }

                .topBorder {
                    border-top: 1px solid #000;
                }

                .leftBorder {
                    border-left: 1px solid #000;
                }

                .bottomBorder {
                    border-bottom: 1px solid #000;
                }

                .wordSign {
                    height: 100%;
                    padding-top: 250px;

                    .wordSignContent {
                        text-align: justify;
                        margin-left: 50px;
                    }
                }
            }

            .wordTableRow:last-child {
                border: none;
            }
        }

        .setContainer {
            position: relative;
        }

        .setContainer:hover .tool {
            display: block;
        }

        .tool {
            display: block;
            position: absolute;
            right: -61px;
            top: 50%;
            width: 60px;
            height: 60px;
            margin-top: -30px;
            background: #f1f2f4;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);

            .eicon {
                font-size: 20px;
                color: #666;
                cursor: pointer;
                height: 30px;
                width: 30px;
                text-align: center;
                line-height: 30px;
            }

            .el-icon-delete {
                color: #F56C6C;
            }
        }

        .textInput {
            /deep/.el-input__inner {
                font-size: 20px !important;
            }

        }

        .iconCheck {
            border: 1px solid #000;
            height: 20px;
            width: 20px;
        }

        .underline {
            border-bottom: 1px solid #000;
        }

        .wordTableBigTitle {
            padding: 15px 10px;
            border: 1px solid #000;
            font-weight: 600;
        }

        .insert {
            border-top: none;
        }

        .wordTableTitle {
            padding: 15px 10px;
            border: 1px solid #000;
        }

        .wordTableTitle.insert {
            border-top: none;
        }

        .wordSetTextarea {
            border: 1px solid #000;
            border-top: none;

            .wordTextareaContent {
                text-indent: 45px;
                text-align: justify;
                line-height: 40px;
            }
        }
    }
}
</style>