<template>
    <div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml10">管理文件>研发项目立项决议 V1.0 </span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-GO-R005</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <el-image class="companyLogo" v-if="checkedCompany.logo" :src="checkedCompany.logo"></el-image>
            <div class="pl30 pr30 mt80 wordSetContent">
                <div class="wordContentTitle zdhq">{{ checkedCompany.companyName }}</div>
                <div class="wordContentTitle">研发项目立项决议</div>
                <div class="h40 mt40">
                    会议名称：总经理办公会
                </div>
                <div class="h40">
                    会议地点：公司会议室
                </div>
                <div class="flex-a-c h40">
                    <div>会议时间：</div>
                    <div>
                        <el-date-picker class="wordInput w300" value-format="yyyy-MM-dd HH:mm" format="yyyy年MM月dd日 HH:mm"
                            v-model="formData.meetingTime" type="datetime" placeholder="会议时间">
                        </el-date-picker>
                    </div>
                </div>
                <div class="flex-a-c h40">
                    <div>参会人员：</div>
                    <div><el-input style="margin-left: -10px;" class="wordInput" placeholder="参会人员"
                            v-model="formData.meetingUser"></el-input></div>
                </div>
                <div>
                    会议决议内容如下:
                </div>
                <div class="textareaContent">
                    一、通过研发中心、财务部门评估，公司管理层联合评审，认为该项目技术上成熟可靠，公司现有技术研发力量能按项目计划进行研究，公司各种资源可以保障该项目研发的顺利实现，项目预算成本控制可完全满足公司利润目标要求，对公司未来业绩有较大的促进作用，会议一致通过《<span
                        class="zdhq">{{ checkedProject.projectName }}</span>》研发项目自主研发的立项决定；
                </div>
                <div class="textareaContent">
                    二、同时，会议还确定并通过了研发项目经理、团队成员及分工，研发项目开发工作进度计划的决定；
                </div>
                <div class="textareaContent">
                    三、预计项目开发周期：<span class="zdhq">{{ checkedProject.startMonth }}至{{ checkedProject.endMonth }}</span>；
                </div>
                <div class="textareaContent">
                    四、项目研发预算：<span class="zdhq">{{ checkedProject.budget?checkedProject.budget:checkedProject.money }}</span>万元；
                </div>
                <div class="textareaContent">
                    五、项目经理（负责人）：<span class="zdhq">{{ checkedProject.headName }}</span>；
                </div>
                <div class="textareaContent">
                    同时，会议最后还强调，技术研发中心应加强项目管理，保证项目进度按计划完成及控制好研发成本，使项目顺利达成预期目标，并快速实现科技成果转化。
                </div>
                <div class="mt100">
                    <div class="zdhq text-align-right">{{ checkedCompany.companyName }}</div>
                    <!-- <div class="flex-a-c justify-content-end" v-if="!formData.meetingTime">
                        <div>年</div>
                        <div class="ml30">月</div>
                        <div class="ml30">日</div>
                    </div>
                    <div v-if="formData.meetingTime" class="text-align-right">{{ convertChinese(formData.meetingTime) }}
                    </div> -->
                    <div class="text-align-right" style="margin-right:-30px;">
                        <el-date-picker class="wordInput w210" value-format="yyyy-MM-dd" format="yyyy年MM月dd日"
                            v-model="meetingTime" type="date" placeholder="会议时间">
                        </el-date-picker>
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div class="squareRight"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['projectData', 'assignment', 'modelData'],
    data() {
        return {
            checkedProject: {},
            checkedCompany: {},
            meetingTime: "",
            formData: {
                meetingTime: "",
                meetingUser: "",
            },
        }
    },
    watch: {
         //监听赋值数据，赋值
         assignment: {
            handler(data) {
                if (data.key) {
                    if (data.key.indexOf("_") != -1) {
                        let keys = data.key.split("_");//0 key 1 列表key 2 下标
                        this.formData[keys[0]][keys[2]][keys[1]] = data.gptResult;
                        // for (let row of this.formData[keys[0]][keys[1]]) {
                        //     row[keys[1]] = data.gptResult;
                        // }
                    } else {
                        this.formData[data.key] = data.gptResult;
                    }
                    if (this.$refs[data.key]) {
                        this.$refs[data.key].focus();
                    }
                }
            },
            deep: true,
            immediate: true
        },
        'formData.meetingTime'(date) {
            this.meetingTime = date.slice(0, 10)
        },
        meetingTime(date) {
            this.formData.meetingTime = date + (this.formData.meetingTime.slice(10,))
        },
        //监听填写数据
        modelData: {
            handler(data) {
                if (data && JSON.stringify(data) != '{}') {
                    this.createData();
                    for (let key in data) {
                        let arrKey = []
                        if (arrKey.indexOf(key) != -1) {
                            this.formData[key] = data[key] ? JSON.parse(data[key]) : [];
                        } else {
                            this.formData[key] = data[key] ? data[key] : "";
                        }
                    }
                    this.formData.projectName = this.checkedProject.projectName;
                } else {
                    this.formData = {
                        meetingTime: "",
                        meetingUser: "",
                    };
                    this.createData();
                }
            },
            deep: true
        },
    },
    methods: {
        createData() {
            let checkedProject = this.projectData;//项目数据
            this.checkedCompany = JSON.parse(sessionStorage.getItem("checkedProjectCompany"));
            this.checkedProject = {
                ...checkedProject,
                //研发开始年
                year: checkedProject.startDay ? checkedProject.startDay.slice(0, 4) : "",
                //编制日期
                compileDate: this.convertChinese((new Date(checkedProject.projectDay).getTime() + (86400000 * 10))),
                //研发开始只显示到月
                startMonth: checkedProject.startDay ? checkedProject.startDay.slice(0, 7) : "",
                //研发结束只显示到月
                endMonth: checkedProject.endDay ? checkedProject.endDay.slice(0, 7) : "",
                //转中文
                projectDayText: this.convertChinese(checkedProject.projectDay),
                startDayText: this.convertChinese(checkedProject.startDay),
                endDayText: this.convertChinese(checkedProject.endDay),
                projectName: checkedProject.projectName.replace("的研究开发", ""),
            };
            this.formData.projectName = this.checkedProject.projectName;
            this.formData.meetingTime = checkedProject.projectDay + " 10:00:00";
            this.meetingTime = checkedProject.projectDay;
        },
        convertChinese(time) {
            if (time) {
                let newDate = new Date(time)
                let y = newDate.getFullYear();
                let m = newDate.getMonth() + 1;
                let d = newDate.getDate();
                return y + '年' + (m < 10 ? '0' + m : m) + '月' + (d < 10 ? '0' + d : d) + '日'
            }
            return "";
        },
    }
}
</script>
<style lang="scss" scoped>
.companyLogo {
    height: 100px;
    position: absolute;
    top: 100px;
    left: 80px;
}

.zdhq {
    // color: #E6A23C !important;
}

.showModelContent div,
.showModelContent span {
    color: #000;
    border-color: #000;

    /deep/input {
        border: none;
        color: #E6A23C; // #000;
    }

    /deep/.el-textarea__inner,
    /deep/.allShow .el-input__inner {
        border: none;
        padding: 0px 15px;
        color: #E6A23C; // #000;
    }
}

.wordSetContent div {
    font-size: 22px;
    letter-spacing: 2px;

}

.showModelContent {
    background: #fff;
    position: relative;
    width: 1100px;
    min-height: 1500px;
    margin: 0 auto;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 50px;
    padding-bottom: 100px;

    .wordContentTitle {
        font-size: 30px;
        text-align: center;
        padding: 10px 0;
        font-weight: 600;
    }

    .modelHeader {
        div {
            font-size: 16px;
        }

        .modelHeaderTitle {
            border-bottom: 1px solid #000;
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordPage {
        position: absolute;
        bottom: 50px;
        width: 1000px;
        height: 30px;
        font-size: 20px;

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordCodeTable {
        width: 350px;
        margin-left: auto;
        border: 2px solid #000;
        margin-bottom: 150px;

        .wordCodeTitle {
            width: 40%;
            height: 43px;
            border-right: 1px solid #000;
            font-size: 20px;
        }

        .wordCodeContent {
            width: 60%;
            height: 43px;
            font-size: 16px;

            /deep/input {
                height: 42px;
                line-height: 42px;
            }
        }
    }

    .projectTitle div {
        font-size: 45px !important;
    }

    .projectTitle {
        margin-bottom: 200px;
    }

    .wordSetContent {
        .wordInput {
            /deep/input {
                font-size: 20px;
            }
        }

        .wordTable {
            border: 1px solid #000;

            .wordTableRow {
                display: flex;
                align-items: center;
                border-bottom: 1px solid #000;
                position: relative;

                .childTable {
                    display: flex;
                    align-items: center;
                }

                .tool {
                    display: block;
                    position: absolute;
                    right: -61px;
                    width: 60px;
                    height: 60px;
                    background: #f1f2f4;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);

                    .eicon {
                        font-size: 20px;
                        color: #666;
                        cursor: pointer;
                        height: 30px;
                        width: 30px;
                        text-align: center;
                        line-height: 30px;
                    }

                    .el-icon-delete {
                        color: #F56C6C;
                    }
                }

                .itemTitle {
                    font-size: 20px;
                    padding: 0 10px;
                    height: 100%;
                    overflow: hidden;
                }

                .itemContent {
                    min-height: 40px;
                    overflow: hidden;

                    div {
                        font-size: 20px;
                    }


                }

                .rightBorder {
                    border-right: 1px solid #000;
                }

                .topBorder {
                    border-top: 1px solid #000;
                }

                .leftBorder {
                    border-left: 1px solid #000;
                }

                .bottomBorder {
                    border-bottom: 1px solid #000;
                }

                .wordSign {
                    height: 100%;
                    padding-top: 250px;

                    .wordSignContent {
                        text-align: justify;
                        margin-left: 50px;
                    }
                }
            }

            .wordTableRow:hover .tool {
                display: block;
            }

            .wordTableRow:last-child {
                border: none;
            }
        }

        /deep/.el-range-editor--small.el-input__inner {
            height: 58px;
        }

        /deep/.el-range-separator {
            padding: 0;
            line-height: 50px;
            font-size: 20px;
            width: 20px;
        }

        /deep/.el-input__inner {
            padding-right: 0;
        }

        /deep/.el-range__close-icon {
            font-size: 20px;
            line-height: 53px;
        }

        .allShow {
            width: 100%;
            height: 40px;
            border: none;
        }

        /deep/.allShow input {
            width: 100%;
            font-size: 20px !important;
        }

        /deep/input {
            height: 39px;
            line-height: 39px;
            font-size: 20px !important;
        }

        /deep/.el-input__icon {
            line-height: 39px;
            font-size: 20px !important;
        }

        .iconCheck {
            border: 1px solid #000;
            height: 20px;
            width: 20px;
        }

        .wordTableBigTitle {
            padding: 15px 10px;
            border: 1px solid #000;
            font-weight: 600;
        }

        .insert {
            border-top: none;
        }

        .wordTableTitle {
            padding: 15px 10px;
            border: 1px solid #000;
        }

        .wordTableTitle.insert {
            border-top: none;
        }

        .wordSetTextarea {
            border: 1px solid #000;
            border-top: none;
        }

        .textareaContent {
            text-indent: 45px;
            text-align: justify;
            line-height: 40px;
        }
    }
}
</style>