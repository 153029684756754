<template>
    <div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml10">管理文件>检测报告 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-GO-R009</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <el-image class="companyLogo" v-if="checkedCompany.logo" :src="checkedCompany.logo"></el-image>
            <div class="pl30 pr30 wordSetContent">
                <div class="wordCodeTable">
                    <div class="flex-a-c" style="border-bottom: 1px solid #000;">
                        <div class="wordCodeTitle flex-c-c">立项编号</div>
                        <div class="flex-c-c wordCodeContent">
                            <div class="zdhq">{{ checkedProject.year }}-{{ checkedProject.projectNo }}</div>
                        </div>
                    </div>
                    <div class="flex-a-c">
                        <div class="wordCodeTitle flex-c-c">报告编号</div>
                        <div class="flex-c-c wordCodeContent">
                            <div class="zdhq">{{ checkedProject.year }}TEST-{{ checkedProject.projectNo }}</div>
                        </div>
                    </div>
                </div>
                <div class="projectTitle">
                    <div class="flex-c-c">
                        检测报告
                    </div>
                    <div class="textc pjTitle mt10 mb10">TESTING REPORT</div>
                </div>
                <div class="wordTable w750 marginAuto">
                    <div class="wordTableRow h60">
                        <div class="itemTitle rightBorder w160">产品名称</div>
                        <div class="itemTitle flex-c-c w600">
                            <el-input v-model="formData.projectName" class="textc" placeholder="产品名称"></el-input>
                            <!-- <div class="zdhq">{{ checkedProject.projectName }}</div> -->
                        </div>
                    </div>
                    <div class="wordTableRow h60">
                        <div class="itemTitle rightBorder w160">项目负责人</div>
                        <div class="itemTitle flex-c-c w600">
                            <!-- <el-input v-model="formData.name" class="allShow" placeholder="申请人"></el-input> -->
                            <div class="zdhq">{{ checkedProject.headName }}</div>
                        </div>
                    </div>
                    <div class="wordTableRow h60">
                        <div class="itemTitle rightBorder w160">检测人员</div>
                        <div class="itemTitle flex-c-c w600">
                            <!-- <el-input v-model="formData.name" class="allShow" placeholder="负责人"></el-input> -->
                            <div class="zdhq">{{ checkedProject.testName }}</div>
                        </div>
                    </div>
                    <div class="wordTableRow h60">
                        <div class="itemTitle rightBorder w160">检测日期</div>
                        <div class="itemTitle flex-c-c w600">
                            <el-date-picker v-model="formData.testDate" class="allShow textc" value-format="yyyy-MM-dd"
                                format="yyyy年MM月dd日" placeholde="文件日期" type="date" placeholder="选择日期">
                            </el-date-picker>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>1</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml10">管理文件>检测报告 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-GO-R009</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="wordContentTitle">一、基本信息</div>
                <div class="wordTable w100p mt30 marginAuto">
                    <div class="wordTableRow h80">
                        <div class="itemTitle rightBorder w150 flex-c-c fw600 " style="background:rgb(255 247 207)">产品名称
                        </div>
                        <div class="itemTitle flex-c-c w780">
                            <el-input v-model="formData.projectName" class="textc" placeholder="产品名称"></el-input>
                        </div>
                    </div>
                    <div class="wordTableRow h80">
                        <div class="itemTitle rightBorder w150 flex-c-c fw600 " style="background:rgb(255 247 207)">送样时间
                        </div>
                        <div class="itemTitle flex-c-c w780">
                            <el-date-picker v-model="formData.sendDate" class="allShow textc" value-format="yyyy-MM-dd"
                                format="yyyy年MM月dd日" placeholde="文件日期" type="date" placeholder="选择日期">
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="wordTableRow h80">
                        <div class="itemTitle rightBorder w150 flex-c-c fw600 " style="background:rgb(255 247 207)">检测时间
                        </div>
                        <div class="itemTitle flex-c-c w780">
                            <el-date-picker v-model="formData.testDate" class="allShow textc" value-format="yyyy-MM-dd"
                                format="yyyy年MM月dd日" placeholde="文件日期" type="date" placeholder="选择日期">
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="wordTableRow h80">
                        <div class="itemTitle rightBorder w150 flex-c-c fw600 " style="background:rgb(255 247 207)">检测标准
                        </div>
                        <div class="itemTitle flex-c-c w780">
                            公司内控《企业标准》
                        </div>
                    </div>
                    <div class="wordTableRow h80">
                        <div class="itemTitle rightBorder w150 flex-c-c fw600 " style="background:rgb(255 247 207)">备 注
                        </div>
                        <div class="itemTitle flex-c-c w780">

                        </div>
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>2</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml10">管理文件>检测报告 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-GO-R009</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="wordContentTitle flex-a-b-c">二、主要功能测试 <el-dropdown class="ml10" split-button type="primary"
                        @click="askCrateData" v-if="askContent" @command="selectAsk">
                        一键填入
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :title="row.content" v-for="(row, index) in answerData" :key="index"
                                :command="row.content">{{ row.ask }}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown></div>
                <div v-loading="askLoading" element-loading-text="填入中,请勿操作..."
                    element-loading-spinner="el-icon-loading">
                    <div class="wordTable w100p mt30 marginAuto">
                        <div class="wordTableRow h60">
                            <div class="itemContent rightBorder w100 flex-c-c">
                                序号
                            </div>
                            <div class="itemContent rightBorder w300 flex-c-c">
                                检测项目
                            </div>
                            <div class="itemContent rightBorder w430 flex-c-c">
                                检测内容
                            </div>
                            <div class="itemContent w100 flex-c-c">
                                结论
                            </div>
                        </div>
                    </div>
                    <vuedraggable v-model="formData.testList" v-bind="dragOptions" @end="getTableCode()">
                        <transition-group>
                            <section class="wordTable w100p marginAuto insert" v-for="(row, index) in formData.testList"
                                :key="index">
                                <div class="wordTableRow h60">
                                    <div class="itemContent rightBorder w100 flex-c-c">
                                        {{ row.num }}
                                    </div>
                                    <div class="itemContent rightBorder w300">
                                        <el-input type="textarea"
                                            @focus="keyPosition('testList_name', '主要功能测试-检测项目', '')" maxlength="1000"
                                            show-word-limit v-model="row.name" rows="2" placeholder="检测项目"></el-input>
                                    </div>
                                    <div class="itemContent rightBorder w430 flex-c-c">
                                        <el-input type="textarea"
                                            @focus="keyPosition('testList_content', '主要功能测试-检测内容', checkedProject.projectName)"
                                            maxlength="1000" show-word-limit v-model="row.content" rows="2"
                                            placeholder="检测内容"></el-input>
                                    </div>
                                    <div class="itemContent w100 flex-c-c">合格</div>
                                    <div class="tool">
                                        <div class="flex-c-c">
                                            <i class="eicon el-icon-plus" @click="addRow(index, 'testList')"></i>
                                        </div>
                                        <div class="flex-c-c">
                                            <i class="eicon el-icon-delete" @click="delRow(index, 'testList')"></i>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </transition-group>
                    </vuedraggable>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>2</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml10">管理文件>检测报告 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-GO-R009</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="wordContentTitle">三、检测结论</div>
                <div class="mt30 ml70 marginAuto">
                    <div>
                        经检验，该产品正常运行，工艺程序和参数精确控制，达到客户要求。
                    </div>
                    <div class="w500 marginAuto flex-a-b-c mt50">
                        <div class="flex-a-c">
                            <div class="iconCheck el-icon-check mr5"></div>
                            <div>检测成功</div>
                        </div>
                        <div class="flex-a-c">
                            <div class="iconCheck mr5"></div>
                            <div>检测不成功</div>
                        </div>
                    </div>
                    <div class="mt100 w750 flex-a-b-c">
                        <div class="d-flex">
                            <div>检测人员签名：</div>
                            <div class="underline w150"></div>
                        </div>
                        <div class="d-flex">
                            <div>日期：</div>
                            <div class="underline w180">{{ convertChinese(formData.testDate) }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>3</div>
                <div class="squareRight"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { getNormalWordDataByPdId } from "@/api/projectAdmin";
import vuedraggable from 'vuedraggable';
export default {
    components: { vuedraggable },
    props: ['projectData', 'assignment', 'modelData'],
    data() {
        return {
            dragOptions: {
                //拖拽配置
                animation: 200,
            },
            checkedProject: {},
            checkedCompany: {},
            formData: {
                projectName: "",
                sendDate: "",
                testDate: "",
                endDay: "",
                testList: [{ num: "1", name: "", content: "" }],
            },
            researchDefault: {//1：硬件，2：软件，3：技术，4：设备
                1: `公司建立了健全的研发组织构架，成立了企业技术研发中心。并根据本项目特点组建了项目研发团队，主要由产品设计人员，策划人员、研发工程师组等组成的项目研发组，设置多名专职的系统架构、设计、研发、测试人员参与本项目的研发。公司制定了完善、规范的《科研项目管理制度》及《研发中心管理办法》、《研发准备金管理办法》等管理制度，为项目研发提供了基础保障。`,
                2: `公司建立了健全的研发组织构架，成立了企业技术研发中心。并根据本项目特点组建了项目研发团队，主要由产品设计人员，策划人员、软件工程师、平面设计师组等组成的项目研发组 ，设置多名专职的系统架构、设计、研发、测试人员参与本系统的研发。公司制定了完善、规范的《科研项目管理制度》及《研发中心管理办法》、《研发准备金管理办法》等管理制度，为项目研发提供了基础保障。<br>同时公司配备了适宜的硬件设备、开发环境及服务器，为本系统的研发打造优质的开发及测试环境。`,
                3: `公司建立了健全的研发组织构架，成立了企业技术研发中心。并根据本项目特点组建了项目研发团队，主要由产品设计人员，策划人员、研发工程师组等组成的项目研发组，设置多名专职的系统架构、设计、研发、测试人员参与本项目的研发。公司制定了完善、规范的《科研项目管理制度》及《研发中心管理办法》、《研发准备金管理办法》等管理制度，为项目研发提供了基础保障。`,
            },
            askContent: "",
            answerData: [],
            askLoading: false,
        }
    },
    watch: {
        //监听填写数据
        modelData: {
            handler(data) {
                if (data && JSON.stringify(data) != '{}') {
                    this.createData();
                    for (let key in data) {
                        let arrKey = ['testList']
                        if (arrKey.indexOf(key) != -1) {
                            this.formData[key] = data[key] ? JSON.parse(data[key]) : [];
                        } else {
                            this.formData[key] = data[key] ? data[key] : "";
                        }
                    }
                } else {
                    this.formData = {
                        projectName: "",
                        sendDate: "",
                        testDate: "",
                        endDay: "",
                        testList: [{ num: "1", name: "", content: "" }],
                    };
                    this.createData();
                }
            },
            deep: true
        },
        //监听赋值数据，赋值
        assignment: {
            handler(data) {
                if (data.key === 'createData') {
                    try {
                        this.getAnswerData(this.projectData.projectName);
                        let strArr = data.gptResult.replace(/：/ig, ":").replace(/[0-9]*\.|=|=[0-9]*\:|[\u4e00-\u9fa5]*[0-9]\:/ig, "|").split("|");
                        strArr.splice(0, 1);
                        let testList = [];
                        for (let key in strArr) {
                            let row = strArr[key];
                            row = row.replace(/(^\u0020*)|(^\u0020*$)|\n/ig, "");
                            testList.push({ num: (this.formData.testList.length + Number(key) + 1), name: row.split(":")[0], content: row.split(":")[1] })
                        }
                        this.formData.testList = testList.concat(this.formData.testList);
                        this.askLoading = false;
                    } catch (err) {
                        this.askLoading = false;
                        this.$message.info("一键填入失败");
                    }
                } else if (data.key) {
                    if (data.key.indexOf("_") != -1) {
                        let keys = data.key.split("_");//0 key 1 列表key 2 下标
                        this.formData[keys[0]][keys[2]][keys[1]] = data.gptResult;
                    } else {
                        this.formData[data.key] = data.gptResult;
                    }
                    if (this.$refs[data.key]) {
                        this.$refs[data.key].focus();
                    }
                }
            },
            deep: true,
            immediate: true
        },
    },
    methods: {
        askCrateData() {
            this.askLoading = true;
            this.$emit("askCrateData", this.askContent)
        },
        selectAsk(ask) {
            try {
                let strArr = ask.replace(/：/ig, ":").replace(/[0-9]*\.|=|=[0-9]*\:|[\u4e00-\u9fa5]*[0-9]\:/ig, "|").split("|");
                strArr.splice(0, 1);
               //console.log(strArr);
                let testList = [];
                for (let key in strArr) {
                    let row = strArr[key];
                    row = row.replace(/(^\u0020*)|(^\u0020*$)|\n/ig, "");
                   //console.log(row);
                    testList.push({ num: (this.formData.testList.length + Number(key) + 1), name: row.split(":")[0], content: row.split(":")[1] })
                }
                this.formData.testList = testList.concat(this.formData.testList);
                this.askLoading = false;
            } catch (err) {
               //console.log(err);
                this.askLoading = false;
                this.$message.info("一键填入失败");
            }
        },
        /**@method 定位要赋值的数据 */
        keyPosition(key, title, problem = "") {
            this.$emit("keyPosition", { key, title, problem });
        },
        getAnswerData(ask) {
            this.answerData = [];
            let answerData = localStorage.getItem("answerData") ? JSON.parse(localStorage.getItem("answerData")) : [];
            for (let row of answerData) {
                if (row.ask.indexOf(ask) != -1) {
                    this.answerData.push(row)
                }
            }
        },
        createData() {
            let checkedProject = this.projectData;//项目数据

            this.checkedCompany = JSON.parse(sessionStorage.getItem("checkedProjectCompany"));
            this.checkedProject = {
                ...checkedProject,
                projectName: checkedProject.projectName.replace("的研究开发", ""),
                year: checkedProject.startDay ? checkedProject.startDay.slice(0, 4) : "",
                startMonth: checkedProject.startDay ? checkedProject.startDay.slice(0, 7) : "",
                endMonth: checkedProject.endDay ? checkedProject.endDay.slice(0, 7) : "",
                startDayText: this.convertChinese(checkedProject.startDay),
                endDayText: this.convertChinese(checkedProject.endDay),
                projectDayText: this.convertChinese(checkedProject.projectDay),
            };
            this.formData.projectName = this.checkedProject.projectName;
            let time15 = new Date(this.checkedProject.endDay).getFullYear() + '-' + (new Date(this.checkedProject.endDay).getMonth() + 1) + "-15";//结束时间年月15号
            let oneDay = 1000 * 60 * 60 * 24;//一天
           //console.log(new Date(time15).getDay());
            let testDate = new Date(time15).getDay() > 5 || new Date(time15).getDay() == 0 ? this.convertChinese((new Date(time15).getTime() - (oneDay * 2)), '-') : time15;
            this.formData.sendDate = this.convertChinese(new Date(testDate).getTime() - oneDay, '-')
            this.formData.testDate = testDate;
            this.formData.endDay = testDate;
            getNormalWordDataByPdId({
                pdId: checkedProject.pdId,
                modelType: 1
            }).then(res => {
                if (res.data) {
                    this.formData.content = res.data.wordData.content;//研究开发内容
                    if (res.data.wordData.content) {
                        this.askContent = `${this.projectData.projectName + res.data.wordData.content}，项目成功转化，你是检测员，请说明${this.projectData.projectName}装备6个确切的检测项目名称，以及他们对应的检测内容（检测内容限制50字）。要求输出格式为=序号.检测项目:检测内容`;
                        this.getAnswerData(this.projectData.projectName);
                    }
                }
            })
        },
        convertChinese(time, type = 'text') {
            if (time) {
                let newDate = new Date(time)
                let y = newDate.getFullYear();
                let m = newDate.getMonth() + 1;
                let d = newDate.getDate();
                if (type == 'text') {

                    return y + '年' + (m < 10 ? '0' + m : m) + '月' + (d < 10 ? '0' + d : d) + '日'
                } else {
                    return y + type + (m < 10 ? '0' + m : m) + type + (d < 10 ? '0' + d : d)
                }
            }
            return "";
        },
        /**@method 整理序号 */
        getTableCode() {
            for (let key in this.formData.testList) {
                this.$set(this.formData.testList[key], 'num', (Number(key) + 1));
            }
        },
        addRow(i, dataKey) {
            if (dataKey == 'testList') {
                if (this.formData.testList.length == (i + 1)) {
                    this.formData.testList.push({ num: "1", name: "", content: "" });
                } else {
                    let testList = [];
                    for (let key in this.formData.testList) {
                        let row = this.formData.testList[key];
                        if ((i + 1) == key) {
                            testList.push({ num: "1", name: "", content: "" });
                        }
                        testList.push(row);
                    }
                    this.formData.testList = testList;
                }
                this.getTableCode();
            }
        },
        delRow(i, dataKey) {
            if (this.formData.testList.length > 1) {
                if (dataKey == 'testList') {
                    this.formData.testList.splice(i, 1);
                }
                this.getTableCode();
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.companyLogo {
    height: 100px;
    position: absolute;
    top: 100px;
    left: 80px;
}

.zdhq {
    // color: #E6A23C !important;
}

.showModelContent div,
.showModelContent span {
    color: #000;
    border-color: #000;

    /deep/input {
        border: none;
        color: #E6A23C; // #000;
    }

    /deep/.el-textarea__inner,
    /deep/.el-input__inner {
        border: none;
        color: #E6A23C; // #000;
    }

    /deep/.textc .el-textarea__inner,
    /deep/.textc .el-input__inner {
        text-align: center;
    }
}

.wordSetContent div {
    font-size: 20px;
    letter-spacing: 2px;
}

.showModelContent {
    background: #fff;
    position: relative;
    width: 1100px;
    min-height: 1500px;
    margin: 0 auto;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 50px;
    padding-bottom: 100px;

    .wordContentTitle {
        font-size: 30px;
        padding: 10px 0;
        font-weight: 600;
    }

    .modelHeader {
        div {
            font-size: 16px;
        }

        .modelHeaderTitle {
            border-bottom: 1px solid #000;
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordPage {
        position: absolute;
        bottom: 50px;
        width: 1000px;
        height: 30px;
        font-size: 20px;

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordCodeTable {
        width: 350px;
        margin-left: auto;
        border: 2px solid #000;
        margin-bottom: 150px;

        .wordCodeTitle {
            width: 40%;
            height: 43px;
            border-right: 1px solid #000;
            font-size: 18px;
        }

        .wordCodeContent {
            width: 60%;
            height: 43px;
            font-size: 16px;

            div {
                letter-spacing: 0px;
            }

            /deep/input {
                height: 42px;
                line-height: 42px;
            }
        }
    }

    .projectTitle div {
        font-size: 45px !important;
    }

    .projectTitle {
        margin-bottom: 200px;
    }

    .wordSetContent {
        .wordTable {
            border: 1px solid #000;

            .wordTableRow {
                display: flex;
                align-items: center;
                border-bottom: 1px solid #000;
                position: relative;

                .tool {
                    display: block;
                    position: absolute;
                    right: -31px;
                    width: 30px;
                    height: 60px;
                    background: #f1f2f4;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);

                    .eicon {
                        font-size: 20px;
                        color: #666;
                        cursor: pointer;
                        height: 30px;
                        width: 30px;
                        text-align: center;
                        line-height: 30px;
                    }

                    .el-icon-delete {
                        color: #F56C6C;
                    }
                }

                .allShow {
                    width: 100%;
                    height: 60px;
                    border: none;
                }

                /deep/.allShow input {
                    width: 100%;
                    font-size: 20px !important;
                }

                /deep/input {
                    height: 56px;
                    line-height: 56px;
                    font-size: 20px !important;
                }

                .itemTitle {
                    font-size: 20px;
                    padding: 0 10px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                }

                .itemContent {
                    height: 60px;
                    overflow: hidden;

                    div {
                        font-size: 20px;
                    }

                    /deep/.el-range-editor--small.el-input__inner {
                        height: 58px;
                    }

                    /deep/.el-range-separator {
                        padding: 0;
                        line-height: 50px;
                        font-size: 20px;
                        width: 20px;
                    }

                    /deep/.el-input__inner {
                        padding-right: 0;
                    }

                    /deep/.el-range__close-icon {
                        font-size: 20px;
                        line-height: 53px;
                    }
                }

                .rightBorder {
                    border-right: 1px solid #000;
                }

                .topBorder {
                    border-top: 1px solid #000;
                }

                .leftBorder {
                    border-left: 1px solid #000;
                }

                .bottomBorder {
                    border-bottom: 1px solid #000;
                }

                .wordSign {
                    height: 100%;
                    padding-top: 250px;

                    .wordSignContent {
                        text-align: justify;
                        margin-left: 50px;
                    }
                }
            }

            .wordTableRow:hover .tool {
                display: block;
            }

            .wordTableRow:last-child {
                border: none;
            }
        }

        .iconCheck {
            border: 1px solid #000;
            height: 20px;
            width: 20px;
        }

        .underline {
            border-bottom: 1px solid #000;
        }

        .wordTableBigTitle {
            padding: 15px 10px;
            border: 1px solid #000;
            font-weight: 600;
        }

        .insert {
            border-top: none;
        }

        .wordTableTitle {
            padding: 15px 10px;
            border: 1px solid #000;
        }

        .wordTableTitle.insert {
            border-top: none;
        }

        .wordSetTextarea {
            border: 1px solid #000;
            border-top: none;

            .wordTextareaContent {
                text-indent: 45px;
                text-align: justify;
                line-height: 40px;
            }
        }
    }
}
</style>