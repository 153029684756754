<template>
    <div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml10">管理文件>软件工程测试报告 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-GO-R009</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <el-image class="companyLogo" v-if="checkedCompany.logo" :src="checkedCompany.logo"></el-image>
            <div class="pl30 pr30 wordSetContent">
                <div class="wordCodeTable">
                    <div class="flex-a-c" style="border-bottom: 1px solid #000;">
                        <div class="wordCodeTitle flex-c-c">立项编号</div>
                        <div class="flex-c-c wordCodeContent">
                            <div class="zdhq">{{ checkedProject.year }}-{{ checkedProject.projectNo }}</div>
                        </div>
                    </div>
                    <div class="flex-a-c">
                        <div class="wordCodeTitle flex-c-c">报告编号</div>
                        <div class="flex-c-c wordCodeContent">
                            <div class="zdhq">{{ checkedProject.year }}TEST-{{ checkedProject.projectNo }}</div>
                        </div>
                    </div>
                </div>
                <div class="projectTitle">
                    <div class="flex-c-c">
                        软件测试报告
                    </div>
                    <div class="textc pjTitle mt10 mb10">SOFTWARE TESTING REPORT</div>
                </div>
                <div class="wordTable w750 marginAuto">
                    <div class="wordTableRow h60">
                        <div class="itemTitle rightBorder flex-c-c w160">项目名称</div>
                        <div class="itemTitle flex-c-c w600">
                            <!-- <el-input v-model="formData.name" class="w400" placeholder="项目名称"></el-input> -->
                            <div class="zdhq">{{ checkedProject.projectName }}</div>
                        </div>
                    </div>
                    <div class="wordTableRow h60">
                        <div class="itemTitle rightBorder flex-c-c w160">项目负责人</div>
                        <div class="itemTitle flex-c-c w600">
                            <div class="zdhq">{{ checkedProject.headName }}</div>
                        </div>
                    </div>
                    <div class="wordTableRow h60">
                        <div class="itemTitle rightBorder flex-c-c w160">验证工程师</div>
                        <div class="itemTitle flex-c-c w600">
                            <!-- <el-input v-model="formData.name" class="allShow" placeholder="负责人"></el-input> -->
                            <div class="zdhq">{{ checkedProject.testName }}</div>
                        </div>
                    </div>
                    <div class="wordTableRow h60">
                        <div class="itemTitle rightBorder flex-c-c w160">验证日期</div>
                        <div class="itemTitle flex-c-c w600">
                            <el-date-picker v-model="formData.testDay" class="allShow tableInput" value-format="yyyy-MM-dd"
                                format="yyyy年MM月dd日" type="date" placeholder="验证日期">
                            </el-date-picker>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>1</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml10">管理文件>软件工程测试报告 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-GO-R009</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="wordContentTitle textc">目&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;录</div>
                <div class="wordDirectory">
                    <div v-for="(row, index) in directoryData" :key="index">
                        <a class="wordDirectoryItem" :href="'#' + row.id">
                            <div>
                                {{ index + 1 }}. {{ row.title }}
                            </div>
                            <div class="line"></div>
                            <div>{{ row.page }}</div>
                        </a>
                        <div v-if="row.childData">
                            <div class="ml30" v-for="(item, key) in row.childData" :key="key">
                                <a class="wordDirectoryItem" :href="'#' + item.id">
                                    <div>
                                        {{ index + 1 }}.{{ key + 1 }}. {{ item.title }}
                                    </div>
                                    <div class="line"></div>
                                    <div>{{ row.page }}</div>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>2</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml10">管理文件>软件工程测试报告 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-GO-R009</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="wordContentTitle" id="RJGS">1.软件概述</div>
                <el-input type="textarea" class="textIndent" maxlength="1000" show-word-limit
                    @focus="keyPosition('content', '软件概述', placeholderData.summary)" :placeholder="placeholderData.summary"
                    :autosize="{ minRows: 6 }" v-model="formData.summary"></el-input>
                <div class="wordContentTitle" id="CSGS">2.测试概述</div>
                <div class="wordContentTitle" id="BXMD">2.1.编写目的</div>
                <div class="wordTextareaContent">
                    <div>
                        本测试报告为“{{ checkedProject.projectName
                        }}”测试报告，目的在于总结测试阶段的测试情况以及分析测试结果，描述系统是否符合用户需求，是否已达到用户预期的功能目标，并对测试质量进行分析。
                    </div>
                    <div>
                        测试报告参考文档提供给用户、测试人员、开发人员、项目管理者、其他管理人员和需要阅读本报告的高层经理阅读。
                    </div>
                </div>
                <div class="wordContentTitle" id="CSFW">2.2.测试范围</div>
                <div class="wordTextareaContent">
                    <div>
                        测试主要根据用户需求说明书和软件需求规格说明书以及相应的文档进行系统测试，包括功能测试、性能测试、安全性和访问控制测试、用户界面测试以及兼容性测试等，而单元测试和集成测试由开发人员来执行。
                    </div>
                    <el-input type="textarea" class="textIndent" maxlength="1000" show-word-limit
                        @focus="keyPosition('content', '测试范围', placeholderData.content)"
                        :placeholder="placeholderData.content" :autosize="{ minRows: 6 }"
                        v-model="formData.content"></el-input>
                </div>
                <div class="wordContentTitle" id="CSJHZXQK">3.测试计划执行情况</div>
                <div class="wordContentTitle" id="CSLX">3.1.测试类型</div>
                <div class="wordTable w100p marginAuto">
                    <div class="wordTableRow h60">
                        <div class="itemContent rightBorder w100 flex-c-c">
                            测试类型
                        </div>
                        <div class="itemContent rightBorder w300 flex-c-c">
                            测试内容
                        </div>
                        <div class="itemContent rightBorder w300 flex-c-c">
                            测试目的
                        </div>
                        <div class="itemContent w235 flex-c-c">
                            所用的测试工具和方法
                        </div>
                    </div>
                </div>
                <div class="wordTable w100p marginAuto insert" v-for="(row, index) in formData.testList" :key="'t' + index">
                    <div class="wordTableRow h60 setContainer">
                        <div class="itemContent rightBorder w100 flex-c-c">
                            <!-- <el-input v-model="row.type" class="allShow" placeholder="测试类型"></el-input> -->
                            <el-input type="textarea" maxlength="1000" show-word-limit placeholder="测试类型" :rows="2"
                                v-model="row.type"></el-input>
                        </div>
                        <div class="itemContent rightBorder w300 flex-c-c">
                            <!-- <el-input v-model="row.content" class="allShow" placeholder="测试内容"></el-input> -->
                            <el-input type="textarea" maxlength="1000" show-word-limit placeholder="测试内容" :rows="2"
                                v-model="row.content"></el-input>
                        </div>
                        <div class="itemContent rightBorder w300 flex-c-c">
                            <!-- <el-input v-model="row.result" class="allShow" placeholder="测试目的"></el-input> -->
                            <el-input type="textarea" maxlength="1000" show-word-limit placeholder="测试目的" :rows="2"
                                v-model="row.result"></el-input>
                        </div>
                        <div class="itemContent w235 flex-c-c">
                            <!-- <el-input v-model="row.tools" class="allShow" placeholder="所用的测试工具和方法"></el-input> -->
                            <el-input type="textarea" maxlength="1000" show-word-limit placeholder="所用的测试工具和方法" :rows="2"
                                v-model="row.tools"></el-input>
                        </div>
                        <div class="tool">
                            <div class="flex-c-c">
                                <i class="eicon el-icon-caret-top" @click="topMove(index, 'testList')"></i>
                                <i class="eicon el-icon-plus" @click="addRow(index, 'testList')"></i>
                            </div>
                            <div class="flex-c-c">
                                <i class="eicon el-icon-caret-bottom" @click="bottomMove(index, 'testList')"></i>
                                <i class="eicon el-icon-delete" @click="delRow(index, 'testList')"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="wordContentTitle" id="CSWTZJ">3.2.测试问题总结</div>
                <div class="wordTextareaContent">
                    <div>
                        在整个软件系统测试执行期间，项目组开发人员能高效地及时解决测试人员提出的各种缺陷，在一定程度上较好的保证了测试执行的效率以及测试最终期限。
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>3</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml10">管理文件>软件工程测试报告 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-GO-R009</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="wordContentTitle" id="CSZJ">4.测试总结</div>
                <div class="wordContentTitle" id="CSYLZXJG">4.1.测试用例执行结果</div>
                <div class="wordTable w100p marginAuto">
                    <div class="wordTableRow h60">
                        <div class="itemContent rightBorder w190 flex-c-c">
                            测试用例标识符
                        </div>
                        <div class="itemContent rightBorder w340 flex-c-c">
                            主要测试功能名称
                        </div>
                        <div class="itemContent rightBorder w80 flex-c-c">
                            状态
                        </div>
                        <div class="itemContent rightBorder w250 flex-c-c">
                            测试结果
                        </div>
                        <div class="itemContent w80 flex-c-c">
                            备注
                        </div>
                    </div>
                </div>
                <div class="wordTable w100p marginAuto insert" v-for="(row, index) in formData.doResultList"
                    :key="'d' + index">
                    <div class="wordTableRow h60 setContainer">
                        <div class="itemContent rightBorder w190 flex-c-c">
                            <el-input type="textarea" maxlength="1000" show-word-limit placeholder="测试用例标识符" :rows="2"
                                v-model="row.num"></el-input>
                        </div>
                        <div class="itemContent rightBorder w340 flex-c-c">
                            <el-input type="textarea" maxlength="1000" show-word-limit placeholder="主要测试功能名称" :rows="2"
                                v-model="row.name"></el-input>
                        </div>
                        <div class="itemContent rightBorder w80 flex-c-c">
                            <el-input v-model="row.status" class="allShow" placeholder="状态"></el-input>
                        </div>
                        <div class="itemContent rightBorder w250 flex-c-c">
                            <el-input type="textarea" maxlength="1000" show-word-limit placeholder="测试结果" :rows="2"
                                v-model="row.result"></el-input>
                        </div>
                        <div class="itemContent w80 flex-c-c">
                        </div>
                        <div class="tool">
                            <div class="flex-c-c">
                                <i class="eicon el-icon-caret-top" @click="topMove(index, 'doResultList')"></i>
                                <i class="eicon el-icon-plus" @click="addRow(index, 'doResultList')"></i>
                            </div>
                            <div class="flex-c-c">
                                <i class="eicon el-icon-caret-bottom" @click="bottomMove(index, 'doResultList')"></i>
                                <i class="eicon el-icon-delete" @click="delRow(index, 'doResultList')"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="wordContentTitle mt20" id="CSWTJJ">4.2.测试问题解决</div>
                <div class="wordTable w100p marginAuto">
                    <div class="wordTableRow h60">
                        <div class="itemContent rightBorder w100 flex-c-c">
                            序号
                        </div>
                        <div class="itemContent rightBorder w280 flex-c-c">
                            测试用例标识符
                        </div>
                        <div class="itemContent rightBorder w280 flex-c-c">
                            错误或问题描述
                        </div>
                        <div class="itemContent w280 flex-c-c">
                            错误或问题状态
                        </div>
                    </div>
                </div>
                <div class="wordTable w100p marginAuto insert" v-for="(row, index) in formData.doProblemList"
                    :key="'dp' + index">
                    <div class="wordTableRow h60 setContainer">
                        <div class="itemContent rightBorder w100 flex-c-c">
                            {{ row.num }}
                        </div>
                        <div class="itemContent rightBorder w280 flex-c-c">
                            <el-input v-model="row.key" class="allShow" placeholder="测试用例标识符"></el-input>
                        </div>
                        <div class="itemContent rightBorder w280 flex-c-c">
                            <el-input v-model="row.content" class="allShow" placeholder="错误或问题描述"></el-input>
                        </div>
                        <div class="itemContent w280 flex-c-c">
                            <el-input v-model="row.status" class="allShow" placeholder="错误或问题状态"></el-input>
                        </div>
                        <div class="tool">
                            <div class="flex-c-c">
                                <i class="eicon el-icon-caret-top" @click="topMove(index, 'doProblemList')"></i>
                                <i class="eicon el-icon-plus" @click="addRow(index, 'doProblemList')"></i>
                            </div>
                            <div class="flex-c-c">
                                <i class="eicon el-icon-caret-bottom" @click="bottomMove(index, 'doProblemList')"></i>
                                <i class="eicon el-icon-delete" @click="delRow(index, 'doProblemList')"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="wordContentTitle mt20" id="CSJGFX">4.3.测试结果分析</div>
                <div class="wordTable w100p marginAuto">
                    <div class="wordTableRow h60">
                        <div class="itemContent specialTable rightBorder w200 flex-c-c">
                            <div class="titleLeft">功能模块</div>
                            <div class="titleRight">严重级</div>
                            <div class="prohibit w250"></div>
                        </div>
                        <div class="itemTitle rightBorder w164 flex-c-c textc">
                            A-严重影响系统运行的错误
                        </div>
                        <div class="itemTitle rightBorder w164 flex-c-c textc">
                            B-功能缺陷，影响系统运行
                        </div>
                        <div class="itemTitle rightBorder w164 flex-c-c textc">
                            C-不影响运行但必须修改
                        </div>
                        <div class="itemTitle rightBorder w164 flex-c-c textc">
                            D-合理的建议
                        </div>
                        <div class="itemTitle w80 flex-c-c">
                            总数
                        </div>
                    </div>
                </div>
                <div class="wordTable w100p marginAuto insert" v-for="(row, index) in formData.endResultList"
                    :key="'er' + index">
                    <div class="wordTableRow h60 setContainer">
                        <div class="itemContent rightBorder w200 flex-c-c">
                            <el-input placeholder="功能模块/严重级" v-model="row.content"></el-input>
                        </div>
                        <div class="itemContent rightBorder w164 flex-c-c">
                            <el-input type="number" placeholder="严重影响系统运行的错误" v-model="row.a"></el-input>
                        </div>
                        <div class="itemContent rightBorder w164 flex-c-c">
                            <el-input type="number" placeholder="功能缺陷，影响系统运行" v-model="row.b"></el-input>
                        </div>
                        <div class="itemContent rightBorder w164 flex-c-c">
                            <el-input type="number" placeholder="不影响运行但必须修改" v-model="row.c"></el-input>

                        </div>
                        <div class="itemContent rightBorder w164 flex-c-c">
                            <el-input type="number" placeholder="合理的建议" v-model="row.d"></el-input>
                        </div>
                        <div class="itemContent w80 flex-c-c">
                            {{ Number(row.a) + Number(row.b) + Number(row.c) + Number(row.d) }}
                        </div>
                        <div class="tool">
                            <div class="flex-c-c">
                                <i class="eicon el-icon-caret-top" @click="topMove(index, 'endResultList')"></i>
                                <i class="eicon el-icon-plus" @click="addRow(index, 'endResultList')"></i>
                            </div>
                            <div class="flex-c-c">
                                <i class="eicon el-icon-caret-bottom" @click="bottomMove(index, 'endResultList')"></i>
                                <i class="eicon el-icon-delete" @click="delRow(index, 'endResultList')"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="wordTable w100p marginAuto insert">
                    <div class="wordTableRow h40">
                        <div class="itemTitle rightBorder w200 flex-c-c">
                            总数
                        </div>
                        <div class="itemTitle rightBorder w164 flex-c-c textc">
                            {{ aSum }}
                        </div>
                        <div class="itemTitle rightBorder w164 flex-c-c textc">
                            {{ bSum }}
                        </div>
                        <div class="itemTitle rightBorder w164 flex-c-c textc">
                            {{ cSum }}
                        </div>
                        <div class="itemTitle rightBorder w164 flex-c-c textc">
                            {{ dSum }}
                        </div>
                        <div class="itemTitle w80 flex-c-c">
                            {{ abcdSum }}
                        </div>
                    </div>
                </div>
                <div class="wordTextareaContent">
                    本次测试中共发现bug {{ abcSum }}个，按严重程度，缺陷集中在C级，建议{{ dSum }}个
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>4</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml10">管理文件>软件工程测试报告 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-GO-R009</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="wordContentTitle" id="ZHPJ">5.综合评价</div>
                <div class="wordContentTitle" id="RJNL">5.1.软件能力</div>
                <el-input type="textarea" class="textIndent" maxlength="1000" show-word-limit
                    @focus="keyPosition('power', '软件能力', placeholderData.power)" :placeholder="placeholderData.power"
                    :autosize="{ minRows: 8 }" v-model="formData.power"></el-input>
                <div class="wordContentTitle" id="XGJY">5.2.相关建议</div>
                <el-input type="textarea" class="textIndent" maxlength="1000" show-word-limit
                    @focus="keyPosition('rule', '相关建议', placeholderData.rule)" :placeholder="placeholderData.rule"
                    :autosize="{ minRows: 8 }" v-model="formData.rule"></el-input>
                <div class="wordContentTitle" id="CSJL">5.3.测试结论</div>
                <div class="mt30 ml70 marginAuto">
                    <div class="w300 flex-a-b-c">
                        <div class="flex-a-c">
                            <div class="iconCheck el-icon-check mr5"></div>
                            <div>测试通过</div>
                        </div>
                        <div class="flex-a-c">
                            <div class="iconCheck mr5"></div>
                            <div>测试不通过</div>
                        </div>
                    </div>
                    <div class="h400"></div>
                    <div class=" w750 flex-a-b-c">
                        <div class="d-flex">
                            <div>测试工程师签名：</div>
                            <div class="underline w150"></div>
                        </div>
                        <div class="d-flex">
                            <div>日期：</div>
                            <div class="underline w170">{{ convertChinese(formData.testDay) }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>5</div>
                <div class="squareRight"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['projectData', 'assignment', 'modelData'],
    data() {
        return {
            checkedProject: {},
            checkedCompany: {},
            // testList: [{ type: "", content: "", result: "", tools: "" }],
            // doResultList: [{ num: "", name: "", status: "", result: "", remark: "" }],
            // doProblemList: [{ num: "1", key: "", content: "", status: "" }],
            directoryData: [
                { title: "软件概述", id: "RJGS", page: 2 },
                {
                    title: "测试概述", id: "CSGS", page: 3,
                    childData: [
                        { title: "编写目的", id: "BXMD", page: 3 },
                        { title: "测试范围", id: "CSFW", page: 3 },
                    ]
                },
                {
                    title: "测试计划执行情况", id: "CSJHZXQK", page: 3,
                    childData: [
                        { title: "测试类型", id: "CSLX", page: 3 },
                        { title: "测试问题总结", id: "CSWTZJ", page: 3 },
                    ]
                },
                {
                    title: "测试总结", id: "CSZJ", page: 3,
                    childData: [
                        { title: "测试用例执行结果", id: "CSYLZXJG", page: 3 },
                        { title: "测试问题解决", id: "CSWTJJ", page: 4 },
                        { title: "测试结果分析", id: "CSJGFX", page: 4 },
                    ]
                },
                {
                    title: "综合评价", id: "ZHPJ", page: 4,
                    childData: [
                        { title: "软件能力", id: "RJNL", page: 4 },
                        { title: "相关建议", id: "XGJY", page: 4 },
                        { title: "测试结论", id: "CSJL", page: 5 },
                    ]
                }
            ],
            formData: {
                testDay: "",
                summary: "",
                content: "主要测试模块包括：",
                testList: [{ type: "", content: "", result: "", tools: "" }],
                doResultList: [{ num: "", name: "", status: "", result: "", remark: "" }],
                doProblemList: [{ num: "1", key: "", content: "", status: "" }],
                power: "",
                rule: "",
                endResultList: [{ content: "", a: "", b: "", c: "", d: "" }],
            },
            placeholderData: {
                summary: "软件内容",
                content: "测试内容",
                power: "软件能力",
                rule: "相关建议",
            }
        }
    },
    computed: {
        aSum() {
            let sum = 0;
            for (let row of this.formData.endResultList) {
                sum += Number(row.a);
            }
            return sum
        },
        bSum() {
            let sum = 0;
            for (let row of this.formData.endResultList) {
                sum += Number(row.b);
            }
            return sum
        },
        cSum() {
            let sum = 0;
            for (let row of this.formData.endResultList) {
                sum += Number(row.c);
            }
            return sum
        },
        dSum() {
            let sum = 0;
            for (let row of this.formData.endResultList) {
                sum += Number(row.d);
            }
            return sum
        },
        abcSum() {
            let sum = 0;
            for (let row of this.formData.endResultList) {
                sum += Number(row.a);
                sum += Number(row.b);
                sum += Number(row.c);
            }
            return sum
        },
        abcdSum() {
            let sum = 0;
            for (let row of this.formData.endResultList) {
                sum += Number(row.a);
                sum += Number(row.b);
                sum += Number(row.c);
                sum += Number(row.d);
            }
            return sum
        },
    },
    watch: {
         //监听赋值数据，赋值
         assignment: {
            handler(data) {
                if (data.key) {
                    if (data.key.indexOf("_") != -1) {
                        let keys = data.key.split("_");//0 key 1 列表key 2 下标
                        this.formData[keys[0]][keys[2]][keys[1]] = data.gptResult;
                        // for (let row of this.formData[keys[0]][keys[1]]) {
                        //     row[keys[1]] = data.gptResult;
                        // }
                    } else {
                        this.formData[data.key] = data.gptResult;
                    }
                    if (this.$refs[data.key]) {
                        this.$refs[data.key].focus();
                    }
                }
            },
            deep: true,
            immediate: true
        },
        //监听填写数据
        modelData: {
            handler(data) {
                if (data && JSON.stringify(data) != '{}') {
                    this.createData();
                    for (let key in data) {
                        let arrKey = ['testList', 'doResultList', 'doProblemList', 'endResultList']
                        if (arrKey.indexOf(key) != -1) {
                            this.formData[key] = data[key] ? JSON.parse(data[key]) : [];
                        } else {
                            this.formData[key] = data[key] ? data[key] : "";
                        }
                    }
                    this.formData.projectName = this.checkedProject.projectName;
                } else {
                    this.formData = {
                        testDay: "",
                        summary: "",
                        content: "主要测试模块包括：",
                        testList: [{ type: "", content: "", result: "", tools: "" }],
                        doResultList: [{ num: "", name: "", status: "", result: "", remark: "" }],
                        doProblemList: [{ num: "1", key: "", content: "", status: "" }],
                        power: "",
                        rule: "",
                        endResultList: [{ content: "", a: "", b: "", c: "", d: "" }],
                    };
                    this.createData();
                }
            },
            deep: true
        },
    },
    methods: {
        /**@method 定位要赋值的数据 */
        keyPosition(key, title, problem = "") {
            this.$emit("keyPosition", { key, title, problem });
        },
        createData() {
            let checkedProject = this.projectData;//项目数据
            this.checkedCompany = JSON.parse(sessionStorage.getItem("checkedProjectCompany"));
            this.checkedProject = {
                ...checkedProject,
                projectName: checkedProject.projectName.replace("的研究开发", ""),
                year: checkedProject.startDay ? checkedProject.startDay.slice(0, 4) : "",
                startMonth: checkedProject.startDay ? checkedProject.startDay.slice(0, 7) : "",
                endMonth: checkedProject.endDay ? checkedProject.endDay.slice(0, 7) : "",
                startDayText: this.convertChinese(checkedProject.startDay),
                endDayText: this.convertChinese(checkedProject.endDay),
                projectDayText: this.convertChinese(checkedProject.projectDay),
            };
            this.formData.testDay = new Date(this.checkedProject.endDay).getFullYear() + '-' + (new Date(this.checkedProject.endDay).getMonth() + 1) + "-15";
            this.formData.projectName = this.checkedProject.projectName;
        },
        convertChinese(time) {
            if (time) {
                let newDate = new Date(time)
                let y = newDate.getFullYear();
                let m = newDate.getMonth() + 1;
                let d = newDate.getDate();
                return y + '年' + (m < 10 ? '0' + m : m) + '月' + (d < 10 ? '0' + d : d) + '日'
            }
            return "";
        },
        /**@method 整理序号 */
        getTableCode() {
            for (let key in this.formData.doResultList) {
                this.$set(this.formData.doResultList[key], 'num', (Number(key) + 1));
            }
        },
        /**@method 列表上移 */
        topMove(i, dataKey) {
            if (i > 0) {
                let arr = JSON.parse(JSON.stringify(this.formData[dataKey]));
                let replaceData = { ...arr[i - 1] }
                let currentData = { ...arr[i] };
                this.$set(this.formData[dataKey], i, replaceData);
                this.$set(this.formData[dataKey], (i - 1), currentData);
            }
            if (dataKey == 'doProblemList') {
                this.getTableCode();
            }
        },
        bottomMove(i, dataKey) {
            if (this.formData[dataKey].length > (i + 1)) {
                let arr = JSON.parse(JSON.stringify(this.formData[dataKey]));
                let replaceData = { ...arr[i + 1] }
                let currentData = { ...arr[i] };
                this.$set(this.formData[dataKey], i, replaceData);
                this.$set(this.formData[dataKey], (i + 1), currentData);

            }
            if (dataKey == 'doProblemList') {
                this.getTableCode();
            }
        },
        addRow(i, dataKey) {
            let dataModel = {
                testList: { type: "", content: "", result: "", tools: "" },
                doResultList: { num: "", name: "", status: "", result: "", remark: "" },
                doProblemList: { num: "", key: "", content: "", status: "" },
                endResultList: { content: "", a: "", b: "", c: "", d: "" },
            }
            if (this.formData[dataKey].length == (i + 1)) {
                this.formData[dataKey].push(dataModel[dataKey]);
            } else {
                let arr = [];
                for (let key in this.formData[dataKey]) {
                    let row = this.formData[dataKey][key];
                    if ((i + 1) == key) {
                        arr.push(dataModel[dataKey]);
                    }
                    arr.push(row);
                }
                this.formData[dataKey] = arr;
            }
            if (dataKey == 'doProblemList') {
                this.getTableCode();
            }
        },
        delRow(i, dataKey) {
            if (this.formData[dataKey].length>1) {
                this.formData[dataKey].splice(i, 1);
                if (dataKey == 'doProblemList') {
                    this.getTableCode();
                }
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.companyLogo {
    height: 100px;
    position: absolute;
    top: 100px;
    left: 80px;
}

.zdhq {
    // color: #E6A23C !important;
}

.showModelContent div,
.showModelContent span {
    color: #000;
    border-color: #000;

    /deep/input {
        border: none;
        color: #E6A23C; // #000;
    }

    /deep/.el-textarea__inner,
    /deep/.el-input__inner {
        border: none;
        color: #E6A23C; // #000;
    }

    .tableInput {
        /deep/.el-input__inner {
            text-align: center;
        }
    }
}

.wordSetContent div {
    font-size: 20px;
    letter-spacing: 2px;
}

.showModelContent {
    background: #fff;
    position: relative;
    width: 1100px;
    min-height: 1500px;
    margin: 0 auto;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 50px;
    padding-bottom: 100px;

    .wordContentTitle {
        font-size: 30px;
        padding: 10px 0;
        font-weight: 600;
    }

    .modelHeader {
        div {
            font-size: 16px;
        }

        .modelHeaderTitle {
            border-bottom: 1px solid #000;
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordPage {
        position: absolute;
        bottom: 50px;
        width: 1000px;
        height: 30px;
        font-size: 20px;

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordCodeTable {
        width: 350px;
        margin-left: auto;
        border: 2px solid #000;
        margin-bottom: 150px;

        .wordCodeTitle {
            width: 40%;
            height: 43px;
            border-right: 1px solid #000;
            font-size: 18px;
        }

        .wordCodeContent {
            width: 60%;
            height: 43px;
            font-size: 16px;

            div {
                letter-spacing: 0px;
            }

            /deep/input {
                height: 42px;
                line-height: 42px;
            }
        }
    }

    .projectTitle div {
        font-size: 45px !important;
    }

    .projectTitle {

        margin-bottom: 200px;
    }

    .wordSetContent {
        .wordTable {
            border: 1px solid #000;

            .wordTableRow {
                display: flex;
                align-items: center;
                border-bottom: 1px solid #000;
                position: relative;

                .allShow {
                    width: 100%;
                    height: 60px;
                    border: none;
                }

                /deep/.allShow input {
                    width: 100%;
                    font-size: 20px !important;
                }

                /deep/input {
                    height: 56px;
                    line-height: 56px;
                    font-size: 20px !important;
                }

                .itemTitle {
                    font-size: 20px;
                    padding: 0 10px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                }

                .specialTable {
                    position: relative;

                    .titleLeft {
                        position: absolute;
                        left: 10px;
                        bottom: 5px;
                    }

                    .prohibit {
                        height: 0px;
                        border-top: 1px solid #000;
                        transform: rotate(15deg);
                        -o-transform: rotate(15deg);
                        -moz-transform: rotate(15deg);
                        -webkit-transform: rotate(15deg);
                        border-radius: 4px;
                    }

                    .titleRight {
                        position: absolute;
                        right: 10px;
                        top: 5px;
                    }
                }

                .itemContent {
                    height: 60px;
                    overflow: hidden;

                    div {
                        font-size: 20px;
                    }

                    /deep/.el-range-editor--small.el-input__inner {
                        height: 58px;
                    }

                    /deep/.el-range-separator {
                        padding: 0;
                        line-height: 50px;
                        font-size: 20px;
                        width: 20px;
                    }

                    /deep/.el-input__inner {
                        padding-right: 0;
                    }

                    /deep/.el-range__close-icon {
                        font-size: 20px;
                        line-height: 53px;
                    }
                }

                .rightBorder {
                    border-right: 1px solid #000;
                }

                .topBorder {
                    border-top: 1px solid #000;
                }

                .leftBorder {
                    border-left: 1px solid #000;
                }

                .bottomBorder {
                    border-bottom: 1px solid #000;
                }

                .wordSign {
                    height: 100%;
                    padding-top: 250px;

                    .wordSignContent {
                        text-align: justify;
                        margin-left: 50px;
                    }
                }
            }

            .wordTableRow:last-child {
                border: none;
            }
        }

        .wordDirectory {
            .wordDirectoryItem {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 15px;
                cursor: pointer;
            }

            .line {
                flex: 1;
                height: 15px;
                border-bottom: 2px dotted #000;
                margin: 0 5px;
            }
        }

        .setContainer {
            position: relative;
        }

        .setContainer:hover .tool {
            display: block;
        }

        .tool {
            display: block;
            position: absolute;
            right: -61px;
            top: 50%;
            width: 60px;
            height: 60px;
            margin-top: -30px;
            background: #f1f2f4;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);

            .eicon {
                font-size: 20px;
                color: #666;
                cursor: pointer;
                height: 30px;
                width: 30px;
                text-align: center;
                line-height: 30px;
            }

            .el-icon-delete {
                color: #F56C6C;
            }
        }

        .textInput {
            /deep/.el-input__inner {
                font-size: 20px !important;
            }

        }

        .iconCheck {
            border: 1px solid #000;
            height: 20px;
            width: 20px;
        }

        .underline {
            border-bottom: 1px solid #000;
        }

        .wordTableBigTitle {
            padding: 15px 10px;
            border: 1px solid #000;
            font-weight: 600;
        }

        .insert {
            border-top: none;
        }

        .wordTableTitle {
            padding: 15px 10px;
            border: 1px solid #000;
        }

        .wordTableTitle.insert {
            border-top: none;
        }

        .wordSetTextarea {
            border: 1px solid #000;
            border-top: none;
        }

        .wordTextareaContent {
            text-indent: 45px;
            text-align: justify;
            line-height: 40px;
        }
    }
}
</style>