<template>
    <div>
        <div class="showModelContent">
            <!-- <div class="timeData">
                <el-collapse>
                    <el-collapse-item name="1">
                        <template slot="title">
                            <div><i class="mr10 ml5 el-icon-time"></i> 日 期</div>
                        </template>
<div class="h300 scrollbar_none overflowHide">
    <div v-for="(day, index) in timeData" :key="index" class="timeItem lineheight40 curPointer w100"
        :class="{ 'on': checkedMonth == day }" @click="checkedMonth = day">{{ day }}</div>
</div>s
</el-collapse-item>
</el-collapse>
</div> -->
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml10">管理文件>软件工程测试报告 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-RD-R005</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <el-image class="companyLogo" v-if="checkedCompany.logo" :src="checkedCompany.logo"></el-image>
            <div class="pl30 pr30 wordSetContent">
                <div class="wordContentTitle">企业科研项目成员变更申请表</div>
                <div class="wordTable w100p marginAuto">
                    <div class="wordTableRow h40">
                        <div class="itemTitle rightBorder flex-c-c w150">项目名称</div>
                        <div class="itemTitle flex-c-c w780">
                            <div class="zdhq">{{ checkedProject.projectName }}</div>
                        </div>
                    </div>
                    <div class="wordTableRow h40">
                        <div class="itemTitle rightBorder flex-c-c w150">项目编号</div>
                        <div class="itemTitle rightBorder flex-c-c w380">
                            <div class="zdhq">{{ checkedProject.projectNo }}</div>
                        </div>
                        <div class="itemTitle rightBorder flex-c-c w120">项目时间</div>
                        <div class="itemTitle flex-c-c w280">
                            <div class="zdhq">{{ checkedProject.startMonth + " 至 " + checkedProject.endMonth }}</div>
                        </div>
                    </div>
                    <div class="wordTableRow h100">
                        <div class="itemTitle rightBorder flex-c-c w150">原项目组人员</div>
                        <div class="itemTitle flex-c-c w780">
                            <el-select class="w100p" @change="changeMember" size="medium" v-model="member" multiple
                                placeholder="原项目组人员">
                                <el-option v-for="(item, id) in projectUserList" :key="id" :label="item.userName"
                                    :value="String(id)">
                                    <span style="float: left">{{ item.userName }}</span>
                                    <div style="float: right;color: #8492a6;font-size: 13px;">
                                        <span class="mr20">{{ item.work }}</span>
                                        <span class="mr20">{{ item.education }}</span>
                                        <span>{{ item.inTime ? item.inTime.slice(0, 10) : "未入职"
                                            }}</span>
                                        <span>~</span>
                                        <span>{{ item.outTime ? item.outTime.slice(0, 10) : "未离职"
                                            }}</span>
                                    </div>
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="wordTableRow h40">
                        <div class="itemTitle rightBorder flex-c-c w150">新增人员</div>
                        <div class="itemTitle flex-a-c w780">
                            <el-select class="w100p" @change="changeUser" size="medium" v-model="newMember" filterable
                                multiple placeholder="新增人员" :loading="userListLoading" :remote-method="getUserList">
                                <el-option v-for="(item, id) in userList" :key="id" :label="item.userName"
                                    :value="String(id)">
                                    <span style="float: left">{{ item.userName }}</span>
                                    <div style="float: right;color: #8492a6;font-size: 13px;">
                                        <span class="mr20">{{ item.work }}</span>
                                        <span class="mr20">{{ item.education }}</span>
                                        <span>{{ item.inTime ? item.inTime.slice(0, 10) : "未入职"
                                            }}</span>
                                        <span>~</span>
                                        <span>{{ item.outTime ? item.outTime.slice(0, 10) : "未离职"
                                            }}</span>
                                    </div>
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="wordTableRow h240">
                        <div class="itemTitle rightBorder flex-c-c w150">添加人员原因</div>
                        <div class="itemTitle h240" style="width:788px">
                            <el-input type="textarea" maxlength="1000" show-word-limit :rows="6" placeholder="添加人员原因"
                                v-model="formData.reason"></el-input>
                            <div class="autograph">
                                <div class="ml50 mr100">项目负责人签字：</div>
                                <div class="ml50">
                                    日&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;期：<el-date-picker
                                        v-model="formData.fileDay" class="miniDay w180" value-format="yyyy-MM-dd"
                                        format="yyyy年MM月dd日" placeholder="日期" type="date"
                                        :picker-options="pickerOptions">
                                    </el-date-picker></div>
                            </div>
                        </div>
                    </div>
                    <div class="wordTableRow h60 flex-c-c fwbold posre">
                        变更人员后项目组成员组成名单
                        <el-button class="synchro" @click="synchroDataList" type="primary">同步立项人员数据</el-button>
                    </div>
                    <div class="w100p marginAuto insert bottomBorder">
                        <div class="wordTableRow h60">
                            <div class="itemTitle rightBorder w150 flex-c-c">姓名</div>
                            <!-- <div class="itemTitle rightBorder w160 flex-c-c">性别</div> -->
                            <div class="itemTitle rightBorder w160 flex-c-c">学历</div>
                            <div class="itemTitle rightBorder w140 flex-c-c">岗位</div>
                            <div class="itemTitle flex-c-c" style="width:488px">分 工</div>
                        </div>
                        <vuedraggable v-model="formData.dataList" v-bind="dragOptions">
                            <transition-group>
                                <section class="wordTableRow h60" v-for="(row, index) in formData.dataList"
                                    :key="index">
                                    <div class="itemContent rightBorder w150 flex-c-c h60">
                                        {{ row.name }}
                                        <!--<el-input v-model="row.name" class="allShow" placeholder="姓名"></el-input> -->
                                        <!-- <el-select v-model="row.name" filterable remote placeholder="请输入关键词"
                                            :remote-method="getUserList" :loading="userListLoading">
                                            <el-option v-for="(item, index) in userList" :key="index" :label="item"
                                                :value="item">
                                            </el-option>
                                        </el-select> -->
                                    </div>
                                    <!-- <div class="itemContent rightBorder w160 flex-c-c h60">
                                        <el-autocomplete class="inline-input w100p" v-model="row.sex"
                                            :fetch-suggestions="sexSearch" placeholder="性别"></el-autocomplete>
                                    </div> -->
                                    <div class="itemContent rightBorder w160 flex-c-c h60">
                                        <!-- <el-input v-model="row.education" class="allShow" placeholder="学历"></el-input> -->
                                        <el-autocomplete class="inline-input w100p" v-model="row.education"
                                            :fetch-suggestions="educationSearch" placeholder="学历"></el-autocomplete>
                                    </div>
                                    <div class="itemContent rightBorder w140 flex-c-c h60">
                                        <!-- <el-input v-model="row.work" class="allShow" placeholder="岗位"></el-input> -->
                                        <el-autocomplete class="inline-input w100p" v-model="row.work"
                                            :fetch-suggestions="workSearch" placeholder="岗位"
                                            @select="watchWork($event, index)">
                                            <template slot-scope="{ item }">
                                                <div class="name">{{ item.key }}</div>
                                            </template>
                                        </el-autocomplete>
                                    </div>
                                    <div class="itemContent flex-c-c h60" style="width:488px">
                                        <el-input v-model="row.content" type="textarea" maxlength="1000" show-word-limit
                                            :rows="2" placeholder="分 工"></el-input>
                                        <!-- <div class="tool">
                                            <div class="flex-c-c">
                                                <i class="eicon el-icon-plus" @click="addRow(index, 'dataList')"></i>
                                            </div>
                                            <div class="flex-c-c">
                                                <i class="eicon el-icon-delete" @click="delRow(index, 'dataList')"></i>
                                            </div>
                                        </div> -->
                                    </div>
                                </section>
                            </transition-group>
                        </vuedraggable>
                    </div>
                    <div class="wordTableRow h200">
                        <div class="itemTitle rightBorder flex-c-c w150 textc">
                            <div>
                                <div>总经理审批</div>
                                <div>意见</div>
                            </div>
                        </div>
                        <div class="itemTitle w780 posre pl10">
                            <div class="flex-a-b-c autograph w780">
                                <div class="ml50">总经理签名：</div>
                                <div class="ml50">
                                    日&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;期：<el-date-picker
                                        v-model="formData.fileDay" class="miniDay w180" value-format="yyyy-MM-dd"
                                        format="yyyy年MM月dd日" placeholder="日期" type="date">
                                    </el-date-picker>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div class="squareRight"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { getProjectUserList, getNormalWordDataByPdId } from "@/api/projectAdmin";
import vuedraggable from 'vuedraggable'
export default {
    components: { vuedraggable },
    props: ['projectData', 'assignment', 'modelData', 'checkedMonth'],
    data() {
        return {
            dragOptions: {
                //拖拽配置
                animation: 200,
            },
            checkedProject: {},
            checkedCompany: {},
            newMember: [],
            member: [],
            formData: {
                member: "",
                newMember: "",
                reason: "",
                dataList: [{
                    name: "",
                    sex: "",
                    work: "",
                    education: "",
                    content: ""
                }],
                fileDay: "",
            },
            userTableJobDefault: this.$store.state.userTableJobDefault,
            placeholderData: {
                keyWord: "技术关键词",
                targetWay: "立项目的及组织实施方式",
                newIdea: "核心技术及创新点",
                result: "取得的阶段性成果"
            },
            sexData: [
                {
                    id: "男",
                    value: "男"
                },
                {
                    id: "女",
                    value: "女"
                },
            ],
            workData: this.$store.state.workData,
            educationData: this.$store.state.educationData,
            gptIcon: this.$store.state.ossUrl + "yimai_photos/iconFont/chatGTP02.png",
            departmentData: {},
            userListLoading: false,
            userList: {},//新增人员 下拉选项
            projectUserList: {},//原项目组人员 下拉选项
            initUserList: {},//新增人员 下拉选项
            noPuIds: "",//不需要显示的用户
            pickerOptions: {
                disabledDate: (time) => {
                    return time.getTime() < (new Date(this.checkedMonth + "-01").getTime() - (1000 * 60 * 60 * 24)) || time.getTime() > (new Date(this.checkedMonth.split("-")[0], this.checkedMonth.split("-")[1], 0).getTime());
                },
            },
            memberObj: {},
            resultModel1: false,
        }
    },
    watch: {
        //监听赋值数据，赋值
        assignment: {
            handler(data) {
                if (data.key) {
                    if (data.key.indexOf("_") != -1) {
                        let keys = data.key.split("_");//0 key 1 列表key 2 下标
                        this.formData[keys[0]][keys[2]][keys[1]] = data.gptResult;
                        // for (let row of this.formData[keys[0]]) {
                        //     row[keys[1]] = data.gptResult;
                        // }
                    } else {
                        this.formData[data.key] = data.gptResult;
                    }
                    if (this.$refs[data.key]) {
                        this.$refs[data.key].focus();
                    }
                }
            },
            deep: true,
            immediate: true
        },
        //监听填写数据
        modelData: {
            async handler(data) {
                if (data && JSON.stringify(data) != '{}') {
                    await this.createData();
                    let noPuIds = [];
                    this.userList = {};
                    for (let key in data) {
                        let arrKey = ['dataList', 'newMember', 'member']
                        if (key != 'cycle') {
                            if (arrKey.indexOf(key) != -1) {
                                let member = [];
                                if (key == 'newMember' && data.newMember) {
                                    let newMember = [];
                                    for (let row of JSON.parse(data.newMember)) {
                                        newMember.push(String(row.puId));
                                    }
                                    this.newMember = newMember;
                                    this.formData.newMember = "";

                                } else if (key == 'member' && data.member) {
                                    for (let row of JSON.parse(data.member)) {
                                        member.push(String(row.puId));//原项目组人员 人员id
                                        noPuIds.push(row.puId);
                                        if (!this.projectUserList[row.puId]) {
                                            this.projectUserList[row.puId] = { userName: row.name, puId: row.puId }
                                        }
                                    }
                                    this.formData.reason = `根据项目开发进度及技术需要，增加新入职的0名研发工程师。`;
                                    this.member = member;
                                    this.formData.member = "";
                                } else if (key == 'dataList' && data.dataList) {
                                    let dataList = data.dataList ? JSON.parse(data.dataList) : [];
                                    let arr = [];
                                    for (let row of dataList) {
                                        let obj = {
                                            puId: row.puId,
                                            name: row.name ? row.name : "",
                                            sex: row.sex ? row.sex : "",
                                            work: row.work ? row.work : "",
                                            education: row.education ? row.education : "",
                                            content: row.content ? row.content : ""
                                        };
                                        arr.push(obj);
                                    }
                                    this.formData.dataList = arr;
                                }
                                this.noPuIds = noPuIds.join(",");
                            } else {
                                this.formData[key] = data[key] ? data[key] : "";
                            }
                        }
                    }
                    await this.getUserList();
                    this.getNewMemberValue();
                    this.getMemberValue();
                    this.getDataList();
                }// else {
                //     this.formData.member = "";
                //     this.formData.newMember = "";
                //     this.formData.reason = "";
                //     this.createData();
                // }
            },
            deep: true
        },
        checkedMonth: {
            handler(m) {
                this.formData.fileDay = m + "-01";
                this.formData = {
                    member: "",
                    newMember: "",
                    reason: "",
                    dataList: [],
                    fileDay: "",
                };
                this.member = "";
                this.newMember = "";
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        /**@method 同步立项人员数据 */
        synchroDataList() {
            for (let row of this.formData.dataList) {
                let item = this.memberObj[row.name];
                if (item) {
                    row.education = item.education ? item.education : row.education;
                    row.work = item.work ? item.work : row.work;
                    row.content = item.job ? item.job : row.content;
                    row.sex = item.sex ? item.sex : row.sex;
                }
            }
            this.$set(this.formData, "dataList", this.formData.dataList)
        },
        watchWork(data, index) {
            this.formData.dataList[index].work = data.key;
            if (!this.formData.dataList[index].content) {
                this.formData.dataList[index].content = data.content;
            }
            this.$set(this.formData.dataList, index, this.formData.dataList[index])
        },
        createFilter(queryString, key = 'value') {
            return (restaurant) => {
                return (restaurant[key].toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        /**@method 学历提示 */
        educationSearch(queryString, cb) {
            var educationData = this.educationData;
            var results = queryString ? educationData.filter(this.createFilter(queryString)) : educationData;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        /**@method 岗位提示 */
        workSearch(queryString, cb) {
            var workData = this.userTableJobDefault[this.checkedProject.projectType];
            var results = queryString ? workData.filter(this.createFilter(queryString, 'key')) : workData;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        /**@method 性别提示 */
        sexSearch(queryString, cb) {
            cb(this.sexData);
        },
        /**@method 定位要赋值的数据 */
        keyPosition(key, title, problem = "") {
            this.$emit("keyPosition", { key, title, problem });
        },
        createData() {
           //console.log(this.projectData);
            let checkedProject = this.projectData;//项目数据
            this.checkedCompany = JSON.parse(sessionStorage.getItem("checkedProjectCompany"));
            this.checkedProject = {
                ...checkedProject,
                //研发开始年
                year: checkedProject.startDay ? checkedProject.startDay.slice(0, 4) : "",
                //研发开始只显示到月
                startMonth: checkedProject.startDay ? checkedProject.startDay.slice(0, 7) : "",
                //研发结束只显示到月
                endMonth: checkedProject.endDay ? checkedProject.endDay.slice(0, 7) : "",
            };
            if (!this.resultModel1) {
                getNormalWordDataByPdId({
                    pdId: checkedProject.pdId,
                    modelType: 1
                }).then(res => {
                    if (res.data) {
                        if (res.data.wordData.memberList) {
                            let memberList = JSON.parse(res.data.wordData.memberList);
                            let memberObj = {};
                            for (let row of memberList) {
                                memberObj[row.name] = row;
                            }
                            this.memberObj = memberObj;
                        }
                    }
                    this.resultModel1 = true;
                })
            }
            if (checkedProject.projectUserList) {
                let projectUserList = {};
                for (let row of checkedProject.projectUserList) {
                    projectUserList[row.puId] = row;

                }
                this.projectUserList = projectUserList;
               //console.log(projectUserList);
            }
            // this.placeholderData = {
            //     market: `我司立项${checkedProject.projectName}，请写出该项目的可行性市场分析，不要分点，150字以内。`,//市场分析
            //     product: `我司立项${checkedProject.projectName}，请写出该项目的产品概念分析，不要分点，150字以内`,//产品概念分析
            //     economic: `我司立项${checkedProject.projectName}，请写出该项目的经济分析，不要分点，150字以内`,//经济分析
            //     difficult: `我司立项${checkedProject.projectName}，请写出该项目的技术可行性难度分析，不要分点，150字以内。`,
            // }
        },
        getNewMemberValue() {
            let memberValue = [];
            for (let puId of this.newMember) {
                memberValue.push({
                    puId: puId,
                    name: this.initUserList[puId].userName
                });
            }
            //赋值新增人员
            this.formData.newMember = memberValue;
        },
        getMemberValue() {
            //赋值原项目组人员
            let memberValue = [];
            for (let puId of this.member) {
                memberValue.push({
                    puId: puId,
                    name: this.projectUserList[puId].userName
                });
            }
            this.formData.member = memberValue;
        },
        /**@method 获取人员数据 */
        getUserList(str) {
            this.userListLoading = true;
            return new Promise(open => {
                getProjectUserList({
                    pageSize: 100,
                    pageNum: 1,
                    userName: str,
                    pcId: this.checkedCompany.pcId,
                    noPuIds: this.noPuIds,
                    selectYear: this.checkedMonth.split("-")[0],
                    selectMonth: this.checkedMonth.split("-")[1],

                }).then(res => {
                    open();
                    this.userListLoading = false;
                    let userList = {};
                    if (res.data) {
                        for (let row of res.data.pageInfo.list) {
                            userList[row.puId] = row;
                        }
                    }
                    if (!str) {
                        this.initUserList = userList;
                    }
                    this.userList = userList;
                }).catch(err => {
                    open();
                    this.userListLoading = false;
                })
            })
        },
        /**@method 获取项目列表 */
        getUserData(str, cb) {
            let params = {
                pageSize: 100,
                pageNum: 1,
                sortCode: 1,
                pcId: this.pcId,
            };
            if (str) {
                params.projectName = str;
            }
            let userTable = [];
            getProjectUserList(params).then(res => {
                if (res.data) {
                    for (let row of res.data.pageInfo.list) {
                        userTable.push(row);
                    }
                }
                cb(userTable);
            }).catch(err => {
                cb(userTable);
            })
        },
        /**@method 监听原项目组人员 */
        changeMember(data) {
            this.getMemberValue();
            this.getDataList();
        },
        /**@method 监听新增人员 */
        changeUser(data) {
            this.formData.reason = `根据项目开发进度及技术需要，增加新入职的${data.length}名研发工程师。`;
            this.getNewMemberValue();
            this.getDataList();
        },
        /**@method 获取变更人员后项目组成员组成名单 */
        getDataList() {
            let dataList = [];
            let puIds = [];
            let oldDataList = {};
            for (let row of this.formData.dataList) {
                puIds.push(Number(row.puId));
                oldDataList[row.puId] = row;
            }
            for (let puId of this.member) {//原项目组人员
                puId = Number(puId)
                if (puIds.indexOf(puId) != -1) {//有数据直接替换，没数据走添加
                    //对应替换数据，空值走立项的数据
                    let oldData = oldDataList[puId]
                    let listIndex = puIds.indexOf(puId);
                    let memberItem = this.memberObj[oldData.name];
                    let work = oldData.work ? oldData.work : memberItem && memberItem.work ? memberItem.work : "";
                    let education = oldData.education ? oldData.education : memberItem && memberItem.education ? memberItem.education : "";
                    let content = oldData.content ? oldData.content : memberItem && memberItem.job ? memberItem.job : "";
                    this.formData.dataList[listIndex].sex = oldData.sex ? oldData.sex : "";
                    this.formData.dataList[listIndex].work = work;//岗位
                    this.formData.dataList[listIndex].education = education;//学历
                    this.formData.dataList[listIndex].content = content;
                    if (!this.formData.dataList[listIndex].content) {
                        //默认值分工
                        for (let item of this.userTableJobDefault[this.checkedProject.projectType]) {
                            if (item.key === this.formData.dataList[listIndex].work) {
                                this.formData.dataList[listIndex].content = item.content;
                                break;
                            }
                        }
                    }
                } else {
                    let row = this.projectUserList[puId];
                    let memberItem = this.memberObj[row.userName];
                    let obj = {
                        puId: row.puId,
                        name: row.userName,
                        sex: row.sex,
                        work: memberItem && memberItem.work ? memberItem.work : row.work,//岗位
                        education: memberItem && memberItem.education ? memberItem.education : row.education,//学历
                        content: memberItem && memberItem.job ? memberItem.job : ""
                    }
                    if (!obj.content) {
                        //分配分工默认值
                        for (let item of this.userTableJobDefault[this.checkedProject.projectType]) {
                            if (item.key === row.work) {
                                obj.content = item.content;
                                break;
                            }
                        }
                    }
                    dataList.push(obj);
                }

            }
            for (let puId of this.newMember) {//新增人员
                puId = Number(puId)
                if (puIds.indexOf(Number(puId)) != -1) {//有数据直接替换，没数据走添加
                    //对应替换数据，空值走立项的数据
                    let oldData = oldDataList[puId]
                    let listIndex = puIds.indexOf(puId);
                    let memberItem = this.memberObj[oldData.name];
                    let work = oldData.work ? oldData.work : memberItem && memberItem.work ? memberItem.work : "";
                    let education = oldData.education ? oldData.education : memberItem && memberItem.education ? memberItem.education : "";
                    let content = oldData.content ? oldData.content : memberItem && memberItem.job ? memberItem.job : "";;
                    this.formData.dataList[listIndex].sex = oldData.sex ? oldData.sex : "";
                    this.formData.dataList[listIndex].work = work;//岗位
                    this.formData.dataList[listIndex].education = education;//学历
                    this.formData.dataList[listIndex].content = content;
                    if (!this.formData.dataList[listIndex].content) {
                        //分配分工默认值
                        for (let item of this.userTableJobDefault[this.checkedProject.projectType]) {
                            if (item.key === this.formData.dataList[listIndex].work) {
                                this.formData.dataList[listIndex].content = item.content;
                                break;
                            }
                        }
                    }
                } else {
                    let row = this.initUserList[puId];
                    let memberItem = this.memberObj[row.userName];
                    let obj = {
                        puId: row.puId,
                        name: row.userName,
                        sex: row.sex,
                        work: memberItem && memberItem.work ? memberItem.work : row.work,//岗位
                        education: memberItem && memberItem.education ? memberItem.education : row.education,//学历
                        content: memberItem && memberItem.job ? memberItem.job : ""
                    }
                    if (!obj.content) {
                        //分配分工默认值
                        for (let item of this.userTableJobDefault[this.checkedProject.projectType]) {
                            if (item.key === row.work) {
                                obj.content = item.content;
                                break;
                            }
                        }
                    }
                    dataList.unshift(obj);
                }
            }
            let newDataList = dataList.concat(this.formData.dataList);
            let dataListValue = [];
            let newPuIds = [];
            for (let puId of this.member) {
                newPuIds.push(Number(puId));
            }
            for (let puId of this.newMember) {
                newPuIds.push(Number(puId));
            }
            for (let row of newDataList) {
                if (newPuIds.indexOf(Number(row.puId)) != -1) {
                    dataListValue.push(row);
                }
            }
            this.formData.dataList = dataListValue;
        },
    }
}
</script>
<style lang="scss" scoped>
.synchro {
    position: absolute;
    right: 10px;
}

.timeItem.on {
    border-bottom: 2px solid #51CBCD;
    color: #51CBCD;
}

.timeItem {
    margin: 0 auto;
    text-align: center;
    border-bottom: 2px solid transparent;
}

.timeData {
    position: absolute;
    width: 150px;
    left: 0;
    top: -40px;
    z-index: 3;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #eee;
    background: #fff;

    /deep/.el-collapse-item__header,
    /deep/.el-collapse {
        border: none;
    }
}

.companyLogo {
    height: 100px;
    position: absolute;
    top: 100px;
    left: 80px;
}

.gptIcon {
    position: absolute;
    left: 0px;
    bottom: 0px;
    height: 25px;
    width: 25px;
    z-index: 4;
    cursor: pointer;
    background: #fff;
    border-radius: 50%;
}

.zdhq {
    // color: #E6A23C !important;
}

.wordSetContent div,
.wordSetContent span {
    color: #000;
    border-color: #000;

    /deep/input {
        border: none;
        color: #E6A23C; // #000;
    }

    /deep/.el-textarea__inner,
    /deep/.allShow .el-input__inner {
        border: none;
        padding: 0px 15px;
        color: #E6A23C; // #000;
    }
}

.wordSetContent div {
    font-size: 18px;
    letter-spacing: 2px;
}

.checkedMonth {
    width: 1100px;
    margin: 0 auto;
}

.showModelContent {
    background: #fff;
    position: relative;
    width: 1100px;
    min-height: 1500px;
    margin: 0 auto;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-bottom: 30px;
    padding: 50px;
    padding-bottom: 100px;

    .wordContentTitle {
        font-size: 30px;
        text-align: center;
        padding: 20px 0;
        font-weight: 600;
    }

    .modelHeader {
        div {
            font-size: 16px;
        }

        .modelHeaderTitle {
            border-bottom: 1px solid #000;
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordPage {
        position: absolute;
        bottom: 50px;
        width: 1000px;
        height: 30px;
        font-size: 18px;

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordCodeTable {
        width: 350px;
        margin-left: auto;
        border: 2px solid #000;
        margin-bottom: 150px;

        .wordCodeTitle {
            width: 40%;
            height: 43px;
            border-right: 1px solid #000;
            font-size: 18px;
        }

        .wordCodeContent {
            width: 60%;
            height: 43px;
            font-size: 16px;

            /deep/input {
                height: 42px;
                line-height: 42px;
            }
        }
    }

    .projectTitle div {
        font-size: 45px !important;
    }

    .projectTitle {
        margin-bottom: 200px;
    }

    .wordSetContent {
        .wordTable {
            border: 1px solid #000;

            .wordTableRow {
                display: flex;
                align-items: center;
                border-bottom: 1px solid #000;
                position: relative;

                .childTable {
                    display: flex;
                    align-items: center;
                }

                .tool {
                    display: block;
                    position: absolute;
                    right: -31px;
                    width: 30px;
                    height: 60px;
                    background: #f1f2f4;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);

                    .eicon {
                        font-size: 20px;
                        color: #666;
                        cursor: pointer;
                        height: 30px;
                        width: 30px;
                        text-align: center;
                        line-height: 30px;
                    }

                    .el-icon-delete {
                        color: #F56C6C;
                    }
                }

                .allShow {
                    width: 100%;
                    height: 40px;
                    border: none;
                }

                /deep/.allShow input {
                    width: 100%;
                    font-size: 18px !important;
                }

                /deep/input {
                    height: 38px;
                    line-height: 38px;
                    font-size: 18px !important;
                }

                /deep/.el-input__icon {
                    line-height: 38px;
                    font-size: 18px !important;
                }

                .iconCheck {
                    border: 1px solid #000;
                    height: 20px;
                    width: 20px;
                }

                .itemTitle {
                    font-size: 18px;
                    padding: 0 10px;
                    height: 100%;
                    overflow: hidden;
                    position: relative;

                    .autograph {
                        position: absolute;
                        right: 10px;
                        bottom: 10px;
                    }
                }

                .itemContent {
                    min-height: 40px;
                    overflow: hidden;

                    div {
                        font-size: 18px;
                    }

                    /deep/.el-range-editor--small.el-input__inner {
                        height: 58px;
                    }

                    /deep/.el-range-separator {
                        padding: 0;
                        line-height: 50px;
                        font-size: 18px;
                        width: 20px;
                    }

                    /deep/.el-input__inner {
                        padding-right: 0;
                    }

                    /deep/.el-range__close-icon {
                        font-size: 18px;
                        line-height: 53px;
                    }
                }

                .wordSign {
                    height: 100%;
                    padding-top: 250px;

                    .wordSignContent {
                        text-align: justify;
                        margin-left: 50px;
                    }
                }
            }

            .wordTableRow:hover .tool {
                display: block;
            }

            .wordTableRow:last-child {
                border: none;
            }
        }

        .iconCheck {
            border: 1px solid #000;
            height: 20px;
            width: 20px;
        }

        .underline {
            border-bottom: 1px solid #000;
        }

        .wordTableBigTitle {
            padding: 15px 10px;
            border: 1px solid #000;
            font-weight: 600;
        }

        .insert {
            border-top: none;
        }

        .wordTableTitle {
            padding: 15px 10px;
            border: 1px solid #000;
        }

        .wordTableTitle.insert {
            border-top: none;
        }

        .wordSetTextarea {
            border: 1px solid #000;
            border-top: none;

            .wordTextareaContent {
                text-indent: 45px;
                text-align: justify;
                line-height: 40px;
            }
        }
    }

    .rightBorder {
        border-right: 1px solid #000;
    }

    .topBorder {
        border-top: 1px solid #000;
    }

    .leftBorder {
        border-left: 1px solid #000;
    }

    .bottomBorder {
        border-bottom: 1px solid #000;
    }
}
</style>