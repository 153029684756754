<template>
    <div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml10">管理文件>科研项目立项报告 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-GO-R001</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <el-image class="companyLogo" v-if="checkedCompany.logo" :src="checkedCompany.logo"></el-image>
            <div class="pl30 pr30 wordSetContent">
                <div class="wordCodeTable">
                    <div class="flex-a-c" style="border-bottom: 1px solid #000;">
                        <div class="wordCodeTitle flex-c-c">立项编号</div>
                        <div class="flex-c-c wordCodeContent">
                            <!-- <el-input v-model="formData.name" class="w100" placeholder="编号"></el-input>
                            <span>-RD</span>
                            <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                            <div class="zdhq">{{ checkedProject.year }}-{{ checkedProject.projectNo }}</div>
                        </div>
                    </div>
                    <div class="flex-a-c">
                        <div class="wordCodeTitle flex-c-c">申报编号</div>
                        <div class="flex-c-c wordCodeContent">
                            <div class="zdhq">{{ checkedProject.code }}</div>
                        </div>
                    </div>
                </div>
                <div class="projectTitle">
                    <div class="flex-c-c textc">
                        <!-- <el-input v-model="formData.name" class="fs30 pjName w100p" placeholder="项目名称"></el-input> -->
                        <div class="zdhq">{{ checkedProject.projectName }}</div>
                    </div>
                    <div class="textc pjTitle mt10 mb10">立项报告</div>
                    <!-- <el-input v-model="formData.name" class="w150 pjyeer" placeholder="年份"></el-input> -->
                    <div class="textc pjTitle">（<span class="zdhq">{{ checkedProject.year }}</span>年度）</div>
                </div>
                <div class="wordTable w750 marginAuto">
                    <div class="wordTableRow h60">
                        <div class="itemTitle rightBorder w160">项目名称</div>
                        <div class="itemTitle flex-c-c w600">
                            <!-- <el-input v-model="formData.name" class="w400" placeholder="项目名称"></el-input> -->
                            <div class="zdhq">{{ checkedProject.projectName }}</div>
                        </div>
                    </div>
                    <div class="wordTableRow h60">
                        <div class="itemTitle rightBorder w160">项目申请人</div>
                        <div class="itemTitle flex-c-c w600">
                            <!-- <el-input v-model="formData.name" class="allShow" placeholder="申请人"></el-input> -->
                            <div class="zdhq">{{ checkedProject.proposerName }}</div>
                        </div>
                    </div>
                    <div class="wordTableRow h60">
                        <div class="itemTitle rightBorder w160">项目负责人</div>
                        <div class="itemTitle flex-c-c w600">
                            <!-- <el-input v-model="formData.name" class="allShow" placeholder="负责人"></el-input> -->
                            <div class="zdhq">{{ checkedProject.headName }}</div>
                        </div>
                    </div>
                    <div class="wordTableRow h60">
                        <div class="itemTitle rightBorder w160">立项日期</div>
                        <div class="itemTitle flex-c-c w600">
                            <div class="zdhq">{{ checkedProject.projectDayText }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>1</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml10">管理文件>科研项目立项报告 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-GO-R001</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="wordContentTitle">项目立项报告</div>
                <div class="wordTable w100p marginAuto">
                    <div class="wordTableRow h60">
                        <div class="itemTitle rightBorder w150">项目名称</div>
                        <div class="itemTitle rightBorder flex-c-c w380">
                            <div class="zdhq">{{ checkedProject.projectName }}</div>
                        </div>
                        <div class="itemTitle rightBorder w160">项目起止时间</div>
                        <div class="itemTitle flex-c-c w250">
                            <div class="zdhq">{{ checkedProject.startMonth }}至{{ checkedProject.endMonth }}</div>
                        </div>
                    </div>
                    <div class="wordTableRow h60">
                        <div class="itemTitle rightBorder w150">项目总投资</div>
                        <div class="itemContent rightBorder w380 flex-c-c">
                            <div class="zdhq">{{ checkedProject.budget ? checkedProject.budget : checkedProject.money
                                }}万元
                            </div>
                        </div>
                        <div class="itemTitle rightBorder w160">项目经费来源</div>
                        <div class="itemTitle flex-c-c w250">
                            <div>企业自筹</div>
                        </div>
                    </div>
                    <div class="wordTableRow h60">
                        <div class="itemTitle rightBorder w150">项目申请人</div>
                        <div class="itemTitle rightBorder w380 flex-c-c">
                            <div class="zdhq">{{ checkedProject.proposerName }}</div>
                        </div>
                        <div class="itemTitle rightBorder w160">所属部门</div>
                        <div class="itemTitle flex-c-c w250">
                            <div>{{ checkedProject.departmentName }}</div>
                        </div>
                    </div>
                    <div class="wordTableRow h60">
                        <div class="itemTitle rightBorder w150">项目负责人</div>
                        <div class="itemTitle rightBorder w380 flex-c-c">
                            <div class="zdhq">{{ checkedProject.headName }}</div>
                        </div>
                        <div class="itemTitle rightBorder w160">项目来源</div>
                        <div class="itemTitle flex-c-c w250">
                            <div>自主立项</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="wordTableBigTitle textc insert">
                        研发项目内容概要
                    </div>
                    <div class="wordSetTextarea posre">
                        <!-- <el-image :src="gptIcon" class="gptIcon"
                            @click="askGpt('summary', placeholderData.summary)"></el-image> -->
                        <el-input type="textarea" class="textIndent" maxlength="1000" show-word-limit
                            @focus="keyPosition('summary', '研发项目内容概要', placeholderData.summary)" ref="summary"
                            :placeholder="placeholderData.summary" :autosize="{ minRows: 5 }"
                            v-model="formData.summary"></el-input>
                    </div>
                </div>
                <div class="mt50">
                    <div class="wordTableBigTitle">
                        一、项目立项依据
                    </div>
                    <div class="wordTableTitle insert">
                        1.1国内外现状及发展趋势
                    </div>
                    <div class="wordSetTextarea posre">
                        <el-input type="textarea" class="textIndent" maxlength="1000" show-word-limit
                            @focus="keyPosition('trend', '国内外现状及发展趋势', placeholderData.trend)" ref="trend"
                            :placeholder="placeholderData.trend" :rows="6" v-model="formData.trend"></el-input>
                    </div>
                </div>
                <div>
                    <div class="wordTableTitle insert">
                        1.2项目研究开发的目的及意义
                    </div>
                    <div class="wordSetTextarea posre">
                        <el-input type="textarea" class="textIndent" maxlength="1000" show-word-limit
                            @focus="keyPosition('objective', '项目研究开发的目的及意义', placeholderData.objective)" ref="objective"
                            :placeholder="placeholderData.objective" :rows="6" v-model="formData.objective"></el-input>
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>2</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml10">管理文件>科研项目立项报告 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-GO-R001</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div>
                    <div class="wordTableBigTitle">
                        二、项目研究开发的内容及目标
                    </div>
                    <div class="wordTableTitle insert">
                        1.1项目主要内容
                    </div>
                    <div class="wordSetTextarea posre">
                        <el-input type="textarea" class="textIndent" maxlength="1000" show-word-limit
                            @focus="keyPosition('content', '项目主要内容', placeholderData.content)" ref="content"
                            :placeholder="placeholderData.content" :rows="6" v-model="formData.content"></el-input>
                    </div>
                </div>
                <div>
                    <div class="wordTableTitle insert">
                        1.2项目关键核心技术
                    </div>
                    <div class="wordSetTextarea posre">
                        <el-input type="textarea" maxlength="1000" show-word-limit
                            @focus="keyPosition('core', '项目关键核心技术', placeholderData.core)" ref="core"
                            :placeholder="placeholderData.core" :rows="6" v-model="formData.core"></el-input>
                    </div>
                </div>
                <div>
                    <div class="wordTableTitle insert">
                        1.3项目技术创新点
                    </div>
                    <div class="wordSetTextarea posre">
                        <el-input type="textarea" maxlength="1000" show-word-limit
                            @focus="keyPosition('innovate', '项目技术创新点', placeholderData.innovate)" ref="innovate"
                            :placeholder="placeholderData.innovate" :rows="6" v-model="formData.innovate"></el-input>
                    </div>
                </div>
                <div>
                    <div class="wordTableTitle insert">
                        1.4项目目标及成果
                    </div>
                    <div class="wordSetTextarea posre">
                        <el-input type="textarea" class="textIndent" maxlength="1000" show-word-limit
                            @focus="keyPosition('result', '项目目标及成果', placeholderData.result)" ref="result"
                            :placeholder="placeholderData.result" :rows="6" v-model="formData.result"></el-input>
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>3</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml10">管理文件>科研项目立项报告 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-GO-R001</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="mb50">
                    <div class="wordTableBigTitle">
                        三、前期准备工作
                    </div>
                    <div class="wordSetTextarea">
                        <el-input type="textarea" class="textIndent" maxlength="1000" show-word-limit
                            @focus="keyPosition('ready', '前期准备工作', placeholderData.ready)" ref="ready"
                            :placeholder="placeholderData.ready" :rows="6" v-model="formData.ready"></el-input>
                    </div>
                </div>
                <div class="mb50">
                    <div class="wordTableBigTitle">
                        四、项目研究开发基础
                    </div>
                    <div class="wordSetTextarea pl10 pr10">
                        <el-input type="textarea" class="textIndent" maxlength="1000" show-word-limit
                            @focus="keyPosition('base', '项目研究开发基础', placeholderData.base)" ref="base"
                            :placeholder="placeholderData.base" :rows="6" v-model="formData.base"></el-input>
                    </div>
                </div>
                <div class="mb50">
                    <div class="wordTableBigTitle">
                        五、项目组织形式
                    </div>
                    <div class="wordSetTextarea pl10 pr10">
                        <el-input type="textarea" class="textIndent" maxlength="1000" show-word-limit
                            @focus="keyPosition('organizeorganize', '项目组织形式', placeholderData.organizeorganize)"
                            ref="organize" :placeholder="placeholderData.organize" :rows="6"
                            v-model="formData.organize"></el-input>
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>4</div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml10">管理文件>科研项目立项报告 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-GO-R001</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="mb50">
                    <div class="wordTableBigTitle">
                        六、项目组成员
                    </div>
                    <div class="wordTable w100p marginAuto insert">
                        <div class="wordTableRow h60">
                            <div class="itemTitle rightBorder w80 flex-c-c">序号</div>
                            <div class="itemTitle rightBorder w110 flex-c-c">姓名</div>
                            <div class="itemTitle rightBorder w140 flex-c-c">岗位</div>
                            <div class="itemTitle rightBorder w160 flex-c-c">学历</div>
                            <div class="itemTitle flex-c-c" style="width:448px">担任本项目工作</div>
                        </div>
                        <vuedraggable v-model="formData.memberList" v-bind="dragOptions" @end="getTableCode()">
                            <transition-group>
                                <section class="wordTableRow h60" v-for="(row, index) in formData.memberList"
                                    :key="index">
                                    <div class="itemContent rightBorder w80 flex-c-c">{{ row.num }}</div>
                                    <div class="itemContent rightBorder w110 flex-c-c">
                                        <!-- {{ row.name }} -->
                                        <!-- <el-select v-model="row.name" filterable remote placeholder="请输入关键词"
                                            :remote-method="getUserList" :loading="userListLoading">
                                            <el-option v-for="(item, key) in userList" :key="key" :label="item"
                                                :value="item">
                                            </el-option>
                                        </el-select> -->
                                        <el-autocomplete class="inline-input w100p" v-model="row.name"
                                            :fetch-suggestions="getUserList" placeholder="名称"
                                            @select="selectUser($event, index)">
                                            <template slot-scope="{ item }">
                                                <div>
                                                    <div class="name">{{ item.userName }}</div>
                                                    <!-- <span class="addr">{{ item.inTime }}</span> -->
                                                </div>
                                            </template>
                                        </el-autocomplete>
                                    </div>
                                    <div class="itemContent rightBorder w140 flex-c-c">
                                        <el-autocomplete class="inline-input w100p" v-model="row.work"
                                            :fetch-suggestions="workSearch" placeholder="岗位"
                                            @select="watchWork($event, index)">
                                            <template slot-scope="{ item }">
                                                <div class="name">{{ item.key }}</div>
                                            </template>
                                        </el-autocomplete>
                                    </div>
                                    <div class="itemContent rightBorder w160 flex-c-c">
                                        <el-autocomplete class="inline-input w100p" v-model="row.education"
                                            :fetch-suggestions="educationSearch" placeholder="学历"></el-autocomplete>
                                    </div>
                                    <div class="itemContent flex-c-c" style="width:448px">
                                        <el-input v-model="row.job" type="textarea" maxlength="1000" show-word-limit
                                            @focus="keyPosition('memberList_job_' + index, '项目组成员-担任本项目工作', '项目组成员-担任本项目工作')"
                                            :rows="2" placeholder="担任本项目工作"></el-input>
                                    </div>
                                    <div class="tool">
                                        <div class="flex-c-c">
                                            <i class="eicon el-icon-plus" @click="addRow(index, 'memberList')"></i>
                                        </div>
                                        <div class="flex-c-c">
                                            <i class="eicon el-icon-delete" @click="delRow(index, 'memberList')"></i>
                                        </div>
                                    </div>
                                </section>
                            </transition-group>
                        </vuedraggable>
                    </div>
                </div>
                <div class="mb50">
                    <div class="wordTableBigTitle flex-a-b-c">
                        七、项目计划进度
                        <div>
                            <el-button type="primary" class="wordBtn" @click="createDate()">日期填入</el-button>
                            <el-dropdown class="ml10" split-button type="primary" @click="askCrateData"
                                v-if="askContent" @command="selectAsk">
                                一键提问
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item :title="row.content" v-for="(row, index) in answerData"
                                        :key="index" :command="row.content">{{ row.ask }}</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                    </div>
                    <vuedraggable v-model="formData.projectList" v-bind="dragOptions">
                        <transition-group>
                            <section class="wordTable w100p marginAuto insert"
                                v-for="(row, index) in formData.projectList" :key="index">
                                <div class="wordTableRow h120">
                                    <div class="itemContent h120 rightBorder w320 flex-c-c">
                                        <el-date-picker v-model="row.time" :default-value="defaultValue"
                                            @change="changeTime" value-format="yyyy-MM" type="monthrange"
                                            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                                        </el-date-picker>
                                    </div>
                                    <div class="itemContent h120 w600 flex-c-c">
                                        <el-input v-model="row.content" type="textarea"
                                            @focus="keyPosition('projectList_content_' + index, '项目计划进度-内容', checkedProject.projectName)"
                                            maxlength="1000" show-word-limit :autosize="{ minRows: 4, maxRows: 4 }"
                                            placeholder="内容"></el-input>
                                    </div>
                                    <div class="tool">
                                        <div class="flex-c-c">
                                            <i class="eicon el-icon-plus" @click="addRow(index, 'projectList')"></i>
                                        </div>
                                        <div class="flex-c-c">
                                            <i class="eicon el-icon-delete" @click="delRow(index, 'projectList')"></i>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </transition-group>
                    </vuedraggable>
                </div>
                <div class="mb50 posre">
                    <div class="wordTableBigTitle">
                        八、公司审批意见
                    </div>
                    <div class="wordTable w100p marginAuto insert">
                        <div class="wordTableRow h60">
                            <div class="itemTitle rightBorder w50p">
                                研发中心意见
                            </div>
                            <div class="itemTitle w50p">
                                总经理审批意见
                            </div>
                        </div>
                        <div class="wordTableRow">
                            <div class="wordSign rightBorder w50p pb100">
                                <div class="ml100 mb60">
                                    <div class="mb20">负责人签名：</div>
                                    <div>日 <span class="ml60">期：</span></div>
                                </div>
                            </div>
                            <div class="wordSign w50p pb100">
                                <div class="ml100">
                                    <div class="mb20">总经理签名：</div>
                                    <div>日 <span class="ml60">期：</span></div>
                                </div>
                                <div class="mt30 ml300">
                                    （公司章）
                                </div>
                            </div>
                        </div>
                    </div>
                    <el-image class="companySeal" v-if="checkedCompany.seal" :src="checkedCompany.seal"></el-image>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>5</div>
                <div class="squareRight"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { getProjectUserList } from "@/api/projectAdmin";
import vuedraggable from 'vuedraggable'
import { copyText, getDataTimeSec } from "@/utils";
export default {
    components: { vuedraggable },
    //项目数据 人员数据 赋值数据 填写数据
    props: ['projectData', 'userData', 'assignment', 'modelData'],
    data() {
        return {
            dragOptions: {
                //拖拽配置
                animation: 200,
            },
            checkedProject: {},
            checkedCompany: {},
            formData: {
                summary: "",//研发项目内容概要
                trend: "",//国内外现状及发展趋势
                objective: "",//项目研究开发的目的及意义
                content: "",//项目主要内容
                core: "",//项目关键核心技术
                innovate: "",//项目技术创新点
                result: "",//目标及成果
                memberList: [{
                    num: 1,
                    name: "",
                    work: "",
                    education: "",
                    job: ""
                }],
                ready: `本项目立项前，公司作了充分的调研认证和可行性分析。在投入成本，项目风险，开发团队和项目组织建立，质量保障方面都作了充分的准备。包括：
    1.需求调研，市场调查，风险评估；
    2.可行性分析认证，知识产权检索评估分析；
    3.项目预算评估和申请；
    4.项目团队力量评估和组织，软硬件设施投入的需求评估。`,
                base: "",
                organize: `本项目通过我司独立自主研究方式进行研发，项目研发资金来源于企业自筹，研发场地设在我公司技术研发中心，通过成立以项目经理负责制的项目组进行开发，项目组下设项目经理1名，项目经理负责对项目的需求、研发方案、开发进度、项目质量、研发成本进行全面控制，确保在计划开发期内取得计划的研发开发成果。`,
                projectList: [{ time: "", content: "" }],
            },
            placeholderData: {
                summary: "",//研发项目内容概要
                trend: "",//国内外现状及发展趋势
                objective: "",//项目研究开发的目的及意义
                content: "",//项目主要内容
                core: "",//项目关键核心技术
                innovate: "",//项目技术创新点
                result: "",//目标及成果
            },
            //根据项目类型赋值默认数据
            //1：产品，2：软件，3：技术
            userTableJobDefault: this.$store.state.userTableJobDefault,
            researchDefault: {
                1: `公司建立了健全的研发组织构架，成立了企业技术研发中心。并根据本项目特点组建了项目研发团队，主要由产品设计人员，策划人员、研发工程师组等组成的项目研发组，设置多名专职的系统架构、设计、研发、测试人员参与本项目的研发。公司制定了完善、规范的《科研项目管理制度》及《研发中心管理办法》、《研发准备金管理办法》等管理制度，为项目研发提供了基础保障。`,
                2: `公司建立了健全的研发组织构架，成立了企业技术研发中心。并根据本项目特点组建了项目研发团队，主要由产品设计人员，策划人员、软件工程师、平面设计师组等组成的项目研发组 ，设置多名专职的系统架构、设计、研发、测试人员参与本系统的研发。公司制定了完善、规范的《科研项目管理制度》及《研发中心管理办法》、《研发准备金管理办法》等管理制度，为项目研发提供了基础保障。<br>同时公司配备了适宜的硬件设备、开发环境及服务器，为本系统的研发打造优质的开发及测试环境。`,
                3: `公司建立了健全的研发组织构架，成立了企业技术研发中心。并根据本项目特点组建了项目研发团队，主要由产品设计人员，策划人员、研发工程师组等组成的项目研发组，设置多名专职的系统架构、设计、研发、测试人员参与本项目的研发。公司制定了完善、规范的《科研项目管理制度》及《研发中心管理办法》、《研发准备金管理办法》等管理制度，为项目研发提供了基础保障。`,
                4: `公司建立了健全的研发组织构架，成立了企业技术研发中心。并根据本项目特点组建了项目研发团队，主要由产品设计人员，策划人员、研发工程师组等组成的项目研发组，设置多名专职的系统架构、设计、研发、测试人员参与本项目的研发。公司制定了完善、规范的《科研项目管理制度》及《研发中心管理办法》、《研发准备金管理办法》等管理制度，为项目研发提供了基础保障。`,

            },
            userList: [],
            userListLoading: false,
            gptIcon: this.$store.state.ossUrl + "yimai_photos/iconFont/chatGTP02.png",
            defaultValue: new Date(),
            askContent: "",
            answerData: [],
            sexData: [
                {
                    id: "男",
                    value: "男"
                },
                {
                    id: "女",
                    value: "女"
                },
            ],
            educationData: this.$store.state.educationData,
        }
    },
    watch: {
        //因为组件关系影响到生命周期，所以靠父组件传
        userData: {
            handler() {
                this.userList = this.userData;
            },
            deep: true,
            immediate: true
        },
        //监听赋值数据，赋值
        assignment: {
            handler(data) {
                // if (data.key === 'createData') {
                //     try {
                //         this.getAnswerData(this.projectData.projectName);
                //         let strArr = data.gptResult.replace(/：/ig, ":").replace(/[0-9]*\.|[\u4e00-\u9fa5]*[0-9]\:|\[|(\]\n)|\]/ig, "|").split("|");
                //         strArr.splice(0, 1)
                //         let projectList = [];
                //         for (let key in strArr) {
                //             let row = strArr[key];
                //             row = row.replace(/(^\u0020*)|(^\u0020*$)|\n/ig, "");
                //             if (row) {
                //                 if (row.split(":").length == 2) {
                //                     projectList.push({ content: row.split(":")[1] })
                //                 } else {
                //                     projectList.push({ content: row })
                //                 }
                //             }
                //         }
                //         this.formData.projectList = projectList.concat(this.formData.projectList);
                //         this.askLoading = false;
                //     } catch (err) {
                //        //console.log(err);
                //         this.askLoading = false;
                //         this.$message.info("一键填入失败");
                //     }
                // } else 
                if (data.key) {
                    if (data.key.indexOf("_") != -1) {
                        let keys = data.key.split("_");//0 key 1 列表key 2 下标
                        this.formData[keys[0]][keys[2]][keys[1]] = data.gptResult;
                        // for (let row of this.formData[keys[0]][keys[1]]) {
                        //     row[keys[1]] = data.gptResult;
                        // }
                    } else {
                        this.formData[data.key] = data.gptResult;
                    }
                    if (this.$refs[data.key]) {
                        this.$refs[data.key].focus();
                    }
                }
            },
            deep: true,
            immediate: true
        },
        //监听填写数据
        modelData: {
            handler(data) {
                if (data && JSON.stringify(data) != '{}') {
                    this.createData();
                    for (let key in data) {
                        let arrKey = ['memberList', 'projectList']
                        if (arrKey.indexOf(key) != -1) {
                            if (key == 'projectList') {
                                let projectList = [];
                                if (data[key]) {
                                    for (let row of JSON.parse(data[key])) {
                                        let obj = { ...row };
                                        let time = [];
                                        if (row.time) {
                                            let arr = row.time.split("至")
                                            time = [arr[0].trim(), arr[1].trim()]
                                        }
                                        obj.time = time
                                        projectList.push(obj);
                                    }
                                }
                                this.formData[key] = projectList
                            } else if (key == 'memberList') {
                                let memberList = [];
                                if (data[key]) {
                                    for (let row of JSON.parse(data[key])) {
                                        let obj = { ...row };
                                        if (!obj.job) {
                                            for (let item of this.userTableJobDefault[this.checkedProject.projectType]) {
                                                if (item.key == row.work) {
                                                    obj.job = item.content;
                                                    break;
                                                }
                                            }
                                        }
                                        memberList.push(obj);
                                    }
                                }
                                this.formData[key] = memberList
                            } else {
                                this.formData[key] = data[key] ? JSON.parse(data[key]) : [];
                            }
                        } else {
                            this.formData[key] = data[key] ? data[key] : "";
                        }
                    }
                } else {
                    this.formData = {
                        summary: "",//研发项目内容概要
                        trend: "",//国内外现状及发展趋势
                        objective: "",//项目研究开发的目的及意义
                        content: "",//项目主要内容
                        core: "",//项目关键核心技术
                        innovate: "",//项目技术创新点
                        result: "",//目标及成果
                        memberList: [{
                            num: 1,
                            name: "",
                            work: "",
                            education: "",
                            job: ""
                        }],
                        ready: `本项目立项前，公司作了充分的调研认证和可行性分析。在投入成本，项目风险，开发团队和项目组织建立，质量保障方面都作了充分的准备。包括：
    1.需求调研，市场调查，风险评估；
    2.可行性分析认证，知识产权检索评估分析；
    3.项目预算评估和申请；
    4.项目团队力量评估和组织，软硬件设施投入的需求评估。`,
                        base: "",
                        organize: `本项目通过我司独立自主研究方式进行研发，项目研发资金来源于企业自筹，研发场地设在我公司技术研发中心，通过成立以项目经理负责制的项目组进行开发，项目组下设项目经理1名，项目经理负责对项目的需求、研发方案、开发进度、项目质量、研发成本进行全面控制，确保在计划开发期内取得计划的研发开发成果。`,
                        projectList: [
                            { time: "", content: "" }
                        ],
                    }
                    this.createData();
                }
            },
            deep: true,
        },
    },
    methods: {
        watchWork(data, index) {
            this.formData.memberList[index].work = data.key;
            if (!this.formData.memberList[index].job) {
                this.formData.memberList[index].job = data.content;
            }
        },
        createFilter(queryString, key = 'value') {
            return (restaurant) => {
                return (restaurant[key].toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        /**@method 学历提示 */
        educationSearch(queryString, cb) {
            var educationData = this.educationData;
            var results = queryString ? educationData.filter(this.createFilter(queryString)) : educationData;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        /**@method 岗位提示 */
        workSearch(queryString, cb) {
            var workData = this.userTableJobDefault[this.checkedProject.projectType];
            var results = queryString ? workData.filter(this.createFilter(queryString, 'key')) : workData;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        /**@method 性别提示 */
        sexSearch(queryString, cb) {
            cb(this.sexData);
        },
        getAnswerData(ask) {
            this.answerData = [];
            let answerData = localStorage.getItem("answerData") ? JSON.parse(localStorage.getItem("answerData")) : [];
            for (let row of answerData) {
                if (row.ask.indexOf(ask) != -1) {
                    this.answerData.push(row)
                }
            }
        },
        selectAsk(ask) {
            // 填入操作
            // try {
            //     let strArr = ask.replace(/：/ig, ":").replace(/[0-9]\.|[0-9]\:|\[|(\]\n)|\]/ig, "|").split("|");
            //     strArr.splice(0, 1)
            //     let projectList = [];
            //     for (let key in strArr) {
            //         let row = strArr[key];
            //         row = row.replace(/(^\u0020*)|(^\u0020*$)|\n/ig, "");
            //         if (row) {
            //             if (row.split(":").length == 2) {
            //                 projectList.push({ content: row.split(":")[1] })
            //             } else {
            //                 projectList.push({ content: row })
            //             }
            //         }
            //     }
            //     this.formData.projectList = projectList.concat(this.formData.projectList);
            //     this.askLoading = false;
            // } catch (err) {
            //     this.askLoading = false;
            //     this.$message.info("一键填入失败");
            // }
            copyText(ask);
            this.$message.info("复制成功！");
        },
        askCrateData() {
            this.$emit("askCrateData", this.askContent)
        },
        createDate() {
            let rowsNum = this.formData.projectList.length;//数据长度
            let startMonth = this.checkedProject.startMonth;//开始月份
            let endMonth = this.checkedProject.endMonth;//结束月份
            let oneMonth = 1000 * 60 * 60 * 24 * 32;//一个月的时间戳
            let monthRange = new Date(endMonth).getMonth() - (new Date(startMonth).getMonth());//隔着多少个月
            let repeatIndex = [];
            if (rowsNum > monthRange) {//长度超出月份范围， 记录下随机重复下标
                let i = 0;
                while (i < (rowsNum - monthRange)) {
                    let randomIndex = parseInt(Math.random() * rowsNum + 1);
                    randomIndex = randomIndex >= rowsNum ? randomIndex - 1 : randomIndex;
                    if (repeatIndex.indexOf(randomIndex) == -1) {
                        repeatIndex.push(randomIndex);
                        i++;
                    }
                }
            }
            let allocation = parseInt((monthRange - 2) / (rowsNum - 2));
            let monthNum = (allocation * (rowsNum - 2)) + 2;
            let moreThanMonth=0;
            if(monthRange!=monthNum){
                moreThanMonth=monthRange-monthNum;
            }
            for (let index in this.formData.projectList) {
                let row = this.formData.projectList[index];
                if (index == 0 || index == (rowsNum - 1) || rowsNum > monthRange) {//开始和结束为一个月范围
                    allocation = 1
                }else{
                    allocation = parseInt((monthRange - 2) / (rowsNum - 2))
                    if(moreThanMonth){
                        allocation++;
                        moreThanMonth--;
                    }
                }
                if (repeatIndex.indexOf(Number(index)) != -1) {//超出条数，随机重复时间
                    row.time = [
                        getDataTimeSec(this.formData.projectList[index - 1].time[0], 'yy-mm'),
                        getDataTimeSec(this.formData.projectList[index - 1].time[1], 'yy-mm')
                    ];
                } else {
                    row.time = [
                        getDataTimeSec(index == 0 ? startMonth : this.formData.projectList[index - 1].time[1], 'yy-mm'),
                        getDataTimeSec(new Date(new Date(index == 0 ? startMonth : this.formData.projectList[index - 1].time[1]).getTime() + (oneMonth * allocation)), 'yy-mm')
                    ];
                }

            }
        },
        changeTime(time) {
            this.defaultValue = time[0].slice(0, 4);
        },
        /**@method 定位要赋值的数据 */
        keyPosition(key, title, problem = "") {
            this.$emit("keyPosition", { key, title, problem });
        },
        selectUser(data, index) {
            this.$set(this.formData.memberList[index], "name", data.userName);
            this.$set(this.formData.memberList[index], "work", data.work);
            this.$set(this.formData.memberList[index], "education", data.education);
            for (let item of this.userTableJobDefault[this.checkedProject.projectType]) {
                if (item.key === data.work && !data.job) {
                    this.$set(this.formData.memberList[index], "job", item.content);
                    break;
                }
            }
        },
        /**@method 获取人员数据 */
        getUserList(str, cb) {
            this.userListLoading = true;
            getProjectUserList({
                pageSize: 100,
                pageNum: 1,
                userName: str,
                pcId: this.checkedCompany.pcId
            }).then(res => {
                this.userListLoading = false;
                if (res.data) {
                    cb(res.data.pageInfo.list);
                } else {
                    cb([]);
                }
            }).catch(err => {
                cb([]);
                this.userListLoading = false;
            })
        },
        /**@method 初始化操作
         * 填写项目数据
         * 生成人员列表
         * 填写默认数据
         * 
         */
        createData() {
            let checkedProject = this.projectData;//项目数据
            this.checkedCompany = JSON.parse(sessionStorage.getItem("checkedProjectCompany"));
            this.checkedProject = {
                ...checkedProject,
                year: checkedProject.startDay ? checkedProject.startDay.slice(0, 4) : "",
                startMonth: checkedProject.startDay ? checkedProject.startDay.slice(0, 7) : "",
                endMonth: checkedProject.endDay ? checkedProject.endDay.slice(0, 7) : "",
                startDayText: this.convertChinese(checkedProject.startDay),
                endDayText: this.convertChinese(checkedProject.endDay),
                projectDayText: this.convertChinese(checkedProject.projectDay),
                code: checkedProject.projectDay ? checkedProject.projectDay.slice(0, 4) + checkedProject.projectNo.replace(/RD/ig, "") : ""
            };
            this.askContent = this.askContent = `请写出${checkedProject.projectName}项目计划进度，只显示阶段计划内容，每个阶段计划内容30字左右，要求输出格式示例：[阶段计划内容1,阶段计划内容2,阶段计划内容3]并且显示阶段不超过六个阶段`;
            if (checkedProject.projectUserList && checkedProject.projectUserList.length) {
                let memberList = checkedProject.projectUserList;
                let newMember = [];
                for (let i in memberList) {
                    if (memberList[i].isShow) {
                        let row = memberList[i];
                        let obj = {
                            num: (Number(i) + 1),
                            name: row.userName,
                            work: row.work,
                            education: row.education,
                            job: ""
                        }
                        for (let item of this.userTableJobDefault[checkedProject.projectType]) {
                            if (item.key === row.work && !obj.job) {
                                obj.job = item.content;
                                break;
                            }
                        }
                        newMember.push(obj);
                    }
                }
                this.$set(this.formData, 'memberList', newMember);
            }
            this.placeholderData = {
                summary: "请写出" + checkedProject.projectName + "项目内容概要，包括研究方法、研究内容，不要分点，100字以内",//研发项目内容概要
                trend: "我司立项" + checkedProject.projectName + "，请写出该项目的国内外技术现状、市场规模、行业竞争，不要分点，500字左右",//国内外现状及发展趋势
                objective: "我司立项" + checkedProject.projectName + "，请写出该项目技术背景现状和研发目的，一段话表述 ，150字以内。",//项目研究开发的目的及意义
                content: "",//项目主要内容
                core: "",//项目关键核心技术
                innovate: "请列出" + checkedProject.projectName + "4个创新点,150字左右。",//项目技术创新点
                result: "本项目研发出一款" + checkedProject.projectName.replace("的研究开发", "") + "。请写出该项目的研究成果及研究用途，150字以内。",//目标及成果
            };
            //1：硬件，2：软件，3：技术，4：设备
            if (checkedProject.projectType == 1) {
                this.placeholderData.content = "我司立项" + checkedProject.projectName + "，请写出该项目的主要研发内容，包括所用技术、结构、材料、功能等，并描述该项目的用途，不要分点，150字左右."
                this.placeholderData.core = "请列出" + checkedProject.projectName + "4个关键核心技术。";
                this.formData.projectList = [
                    { time: "", content: "进行项目的设计、调研工作、制定详细的研发方案" },
                    { time: "", content: "整体功能规划、设计，框架开发" },
                    { time: "", content: "其他功能完善，上线测试" }
                ]
            } else if (checkedProject.projectType == 2) {
                this.placeholderData.content = "我司立项" + checkedProject.projectName + "，请写出该项目的主要研发内容，包括所用技术、结构、材料、功能等，并描述该项目的用途，不要分点，150字左右."
                this.placeholderData.core = "请列出" + checkedProject.projectName + "4个关键核心技术。1、前端技术：2、后端技术：3、功能模块：4、前沿的互联网技术"
                this.formData.projectList = [
                    { time: "", content: "进行项目的设计、调研工作、制定详细的研发方案" },
                    { time: "", content: "系统功能规划、系统架构、数据结构设计" },
                    { time: "", content: "其他功能完善、效果测试" }
                ]
            } else if (checkedProject.projectType == 3) {
                this.placeholderData.content = "我司立项" + checkedProject.projectName + "，请写出该项目的主要研发内容，150字左右."
                this.placeholderData.core = "请列出" + checkedProject.projectName + "4个关键核心技术。";
                this.formData.projectList = [
                    { time: "", content: "进行项目的设计、调研工作、制定详细的研发方案" },
                    { time: "", content: "整体功能规划、设计，框架开发" },
                    { time: "", content: "其他功能完善，上线测试" }
                ]
            } else if (checkedProject.projectType == 4) {
                this.placeholderData.content = "我司立项" + checkedProject.projectName + "，请写出该项目的主要研发内容，包括所用技术、结构、材料、功能等，并描述该项目的用途，不要分点，150字左右."
                this.placeholderData.core = "请列出" + checkedProject.projectName + "4个关键核心技术。";
                this.formData.projectList = [
                    { time: "", content: "进行项目的设计、调研工作、制定详细的研发方案" },
                    { time: "", content: "整体功能规划、设计，框架开发" },
                    { time: "", content: "其他功能完善，上线测试" }
                ]
            }
            this.formData.base = this.researchDefault[checkedProject.projectType].replace("<br>", "\n");
        },
        convertChinese(time) {
            if (time) {
                let newDate = new Date(time)
                let y = newDate.getFullYear();
                let m = newDate.getMonth() + 1;
                let d = newDate.getDate();
                return y + '年' + (m < 10 ? '0' + m : m) + '月' + (d < 10 ? '0' + d : d) + '日'
            }
            return "";
        },
        /**@method 整理序号 */
        getTableCode() {
            for (let key in this.formData.memberList) {
                this.$set(this.formData.memberList[key], 'num', (Number(key) + 1));
            }
        },
        addRow(i, dataKey) {
            if (dataKey == 'memberList') {
                if (this.formData.memberList.length == (i + 1)) {
                    this.formData.memberList.push({
                        num: 1,
                        name: "",
                        work: "",
                        education: "",
                        job: ""
                    });
                    this.$set(this.formData, 'memberList', this.formData.memberList);
                } else {
                    let memberList = [];
                    for (let key in this.formData.memberList) {
                        let row = this.formData.memberList[key];
                        if ((i + 1) == key) {
                            memberList.push({
                                num: 1,
                                name: "",
                                work: "",
                                education: "",
                                job: ""
                            });
                        }
                        memberList.push(row);
                    }
                    this.formData.memberList = memberList;
                }
                this.getTableCode();
            } else if (dataKey == 'projectList') {
                if (this.formData.projectList.length == (i + 1)) {
                    this.formData.projectList.push({ time: "", content: "" });
                    this.$set(this.formData, 'projectList', this.formData.projectList);
                } else {
                    let projectList = [];
                    for (let key in this.formData.projectList) {
                        let row = this.formData.projectList[key];
                        if ((i + 1) == key) {
                            projectList.push({ time: "", content: "" });
                        }
                        projectList.push(row);
                    }
                    this.formData.projectList = projectList;
                }
            }
        },
        delRow(i, dataKey) {
            if (dataKey == 'memberList' && this.formData.memberList.length > 1) {
                this.formData.memberList.splice(i, 1);
                this.getTableCode();
            } else if (dataKey == 'projectList' && this.formData.projectList.length > 1) {
                this.formData.projectList.splice(i, 1);
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.companyLogo {
    height: 100px;
    position: absolute;
    top: 100px;
    left: 80px;
}

.companySeal {
    height: 200px;
    width: 200px;
    position: absolute;
    bottom: 130px;
    right: 30px;
}

.gptIcon {
    position: absolute;
    left: 0px;
    bottom: 0px;
    height: 25px;
    width: 25px;
    z-index: 4;
    cursor: pointer;
    background: #fff;
    border-radius: 50%;
}

.zdhq {
    // color: #E6A23C !important;
}

.showModelContent div,
.showModelContent span {
    color: #000;
    border-color: #000;

    /deep/input {
        border: none;
        color: #E6A23C; // #000;
    }

    /deep/.el-textarea__inner,
    /deep/.el-input__inner {
        border: none;
        color: #E6A23C; // #000;
        text-align: justify;
    }
}

.wordSetContent div {
    font-size: 20px;
    letter-spacing: 2px;
}

.showModelContent {
    background: #fff;
    position: relative;
    width: 1100px;
    min-height: 1500px;
    margin: 0 auto;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 50px;
    padding-bottom: 100px;

    .wordContentTitle {
        font-size: 30px;
        text-align: center;
        padding: 10px 0;
        font-weight: 600;
    }

    .modelHeader {
        div {
            font-size: 16px;
        }

        .modelHeaderTitle {
            border-bottom: 1px solid #000;
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordPage {
        position: absolute;
        bottom: 50px;
        width: 1000px;
        height: 30px;
        font-size: 20px;

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordCodeTable {
        width: 350px;
        margin-left: auto;
        border: 2px solid #000;
        margin-bottom: 150px;

        .wordCodeTitle {
            width: 40%;
            height: 43px;
            border-right: 1px solid #000;
            font-size: 18px;
        }

        .wordCodeContent {
            width: 60%;
            height: 43px;
            font-size: 16px;

            div {
                letter-spacing: 0px;
            }

            /deep/input {
                height: 42px;
                line-height: 42px;
            }
        }
    }

    .projectTitle div {
        font-size: 45px !important;
    }

    .projectTitle {
        margin-bottom: 200px;
    }

    .wordSetContent {
        .wordTable {
            border: 1px solid #000;

            .wordTableRow {
                display: flex;
                align-items: center;
                border-bottom: 1px solid #000;
                position: relative;

                .tool {
                    display: block;
                    position: absolute;
                    right: -31px;
                    width: 30px;
                    height: 60px;
                    background: #f1f2f4;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);

                    .eicon {
                        font-size: 20px;
                        color: #666;
                        cursor: pointer;
                        height: 30px;
                        width: 30px;
                        text-align: center;
                        line-height: 30px;
                    }

                    .el-icon-delete {
                        color: #F56C6C;
                    }
                }

                .allShow {
                    width: 100%;
                    height: 59px;
                    border: none;
                }

                /deep/.allShow input {
                    width: 100%;
                    font-size: 20px !important;
                }

                /deep/input {
                    height: 56px;
                    line-height: 56px;
                    font-size: 20px !important;
                }

                .itemTitle {
                    font-size: 20px;
                    padding: 0 10px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                }

                .itemContent {
                    height: 60px;
                    overflow: hidden;

                    div {
                        font-size: 20px;
                    }

                    /deep/.el-range-editor--small.el-input__inner {
                        height: 58px;
                    }

                    /deep/.el-textarea__inner {
                        height: 58px;
                    }

                    /deep/.el-range-separator {
                        padding: 0;
                        line-height: 50px;
                        font-size: 20px;
                        width: 20px;
                    }

                    /deep/.el-input__inner {
                        padding-right: 0;
                    }

                    /deep/.el-range__close-icon {
                        font-size: 20px;
                        line-height: 53px;
                    }
                }

                .rightBorder {
                    border-right: 1px solid #000;
                }

                .topBorder {
                    border-top: 1px solid #000;
                }

                .leftBorder {
                    border-left: 1px solid #000;
                }

                .bottomBorder {
                    border-bottom: 1px solid #000;
                }

                .wordSign {
                    height: 100%;
                    padding-top: 250px;

                    .wordSignContent {
                        text-align: justify;
                        margin-left: 50px;
                    }
                }
            }

            .wordTableRow:hover .tool {
                display: block;
            }

            .wordTableRow:last-child {
                border: none;
            }
        }

        .iconCheck {
            border: 1px solid #000;
            height: 20px;
            width: 20px;
        }

        .underline {
            border-bottom: 1px solid #000;
        }

        .wordTableBigTitle {
            padding: 15px 10px;
            border: 1px solid #000;
            font-weight: 600;
        }

        .insert {
            border-top: none;
        }

        .wordTableTitle {
            padding: 15px 10px;
            border: 1px solid #000;
        }

        .wordTableTitle.insert {
            border-top: none;
        }

        .wordSetTextarea {
            border: 1px solid #000;
            border-top: none;

            .wordTextareaContent {
                text-indent: 45px;
                text-align: justify;
                line-height: 40px;
            }
        }
    }
}
</style>