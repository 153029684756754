<template>
    <div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml10">管理文件>软件工程测试报告 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-RD-R005</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <el-image class="companyLogo" v-if="checkedCompany.logo" :src="checkedCompany.logo"></el-image>
            <div class="pl30 pr30 wordSetContent">
                <div class="wordCodeTable">
                    <div class="flex-a-c" style="border-bottom: 1px solid #000;">
                        <div class="wordCodeTitle flex-c-c">报告编号</div>
                        <div class="flex-c-c wordCodeContent">
                            <!-- <el-input v-model="formData.name" class="w100" placeholder="编号"></el-input>
                            <span>-RD</span>
                            <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                            <div class="zdhq">{{ checkedProject.year }}TEST-{{ checkedProject.projectNo }}</div>
                        </div>
                    </div>
                    <div class="flex-a-c">
                        <div class="wordCodeTitle flex-c-c">报告页数</div>
                        <div class="flex-c-c wordCodeContent">
                            共5页
                            <!-- <el-input v-model="formData.name" class="w110" placeholder="编号"></el-input>
                            <span>0</span>
                            <el-input v-model="formData.name" class="w80" placeholder="编号"></el-input> -->
                            <!-- <div class="zdhq">{{ checkedProject.projectNo }}</div> -->
                        </div>
                    </div>
                </div>
                <div class="projectTitle">
                    <div class="textc pjTitle mt10 mb10">软件测试报告</div>
                    <div class="textc pjTitle mt10 mb10">SOFTWARE TESTING REPORT</div>
                </div>
                <div class="wordTable w750 marginAuto">
                    <div class="wordTableRow h60">
                        <div class="itemTitle rightBorder w160">软件名称</div>
                        <div class="itemTitle flex-c-c w600">
                            <div class="zdhq">{{ checkedProject.projectName }}</div>
                        </div>
                    </div>
                    <div class="wordTableRow h60">
                        <div class="itemTitle rightBorder w160">项目经理</div>
                        <div class="itemTitle flex-c-c w600">
                            <div class="zdhq">{{ checkedProject.headName }}</div>
                        </div>
                    </div>
                    <div class="wordTableRow h60">
                        <div class="itemTitle rightBorder w160">测试工程师</div>
                        <div class="itemTitle flex-c-c w600">
                            <div class="zdhq">{{ checkedProject.testName }}</div>
                        </div>
                    </div>
                    <div class="wordTableRow h60">
                        <div class="itemTitle rightBorder w160">测试日期</div>
                        <div class="itemTitle flex-c-c w600">
                            <div class="zdhq"><el-date-picker v-model="formData.fileDay" class="miniDay"
                                    value-format="yyyy-MM-dd" format="yyyy年MM月dd日" placeholde="测试日期" type="date">
                                </el-date-picker></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div></div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml10">管理文件>项目结题验收报告 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-GO-R002</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="wordContentTitle">测 试 报 告</div>
                <table border="1" cellspacing="0" class="tableBorder institutionTable">
                    <tr class="h60">
                        <td class="textc itemTitle fs9 w120">产品名称</td>
                        <td class="textc itemTitle fs9">{{ checkedProject.projectName }}</td>
                        <td class="textc itemTitle fs9 w130">版本号</td>
                        <td class="textc itemTitle fs9 w200"><el-input v-model="formData.levelCode" class="wordInput"
                                placeholder="版本号"></el-input></td>
                    </tr>
                    <tr class="h60">
                        <td class="textc itemTitle fs9">开发单位</td>
                        <td class="textc itemTitle fs9" colspan="3">{{ checkedCompany.companyName }}</td>
                    </tr>
                    <tr class="h60">
                        <td class="textc itemTitle fs9">开发平台</td>
                        <td class="textc itemTitle fs9"><el-input v-model="formData.development" class="wordInput"
                                placeholder="开发平台"></el-input></td>
                        <td class="textc itemTitle fs9">开发语言</td>
                        <td class="textc itemTitle fs9"><el-input v-model="formData.language" class="wordInput"
                                placeholder="开发语言"></el-input></td>
                    </tr>
                    <tr class="h60">
                        <td class="textc itemTitle fs9">检测地点</td>
                        <td class="textc itemTitle fs9">公司研发中心</td>
                        <td class="textc itemTitle fs9">测试完成日期</td>
                        <td class="textc itemTitle fs9"><el-date-picker v-model="formData.fileDay" class="miniDay w190"
                                value-format="yyyy-MM-dd" format="yyyy年MM月dd日" placeholde="测试完成日期" type="date">
                            </el-date-picker></td>
                    </tr>
                    <tr class="h60">
                        <td class="textc itemTitle fs9">样品清单</td>
                        <td class="itemTitle fs9 lineheight40" colspan="3">
                            <div class="mt30">1、《{{ checkedProject.projectName }}用户手册》电子版一份</div>
                            <div class="mb30">2、部分源代码 电子版一张</div>
                        </td>
                    </tr>
                    <tr class="h60">
                        <td class="textc itemTitle fs9">测试依据</td>
                        <td class="itemTitle fs9" colspan="3">
                            <div class="lineheight40">GB/T
                                25000.51-2016《系统与软件工程系统与软件质量要求和评价（SQuaRE）第51部分:就绪可用软件产品(RUSP)的质量要求和测试细则》、企业内部检测及测试规范
                            </div>
                        </td>
                    </tr>
                    <tr class="h60">
                        <td class="textc itemTitle fs9">测试结论</td>
                        <td class="itemTitle fs9" colspan="3">
                            <div class="lineheight40 textIndent40">本次测试主要从用户文档、病毒检查、功能性、安全及可靠性、易用性和适应性6个方面对软件名称进行了测试。
                            </div>
                            <div class="lineheight40 textIndent40">
                                测试表明:该软件用户文档描述完整，样品检测无病毒，软件功能可正确实现。软件运行基本稳定，容易操作，在规定的软硬件环境下可正确运行基本满足软件产品登记测试项及测试通过准则的要求。
                            </div>
                            <div class="lineheight40 textIndent40 mt50">测试结果：通过</div>
                            <div class="text-align-right mt80 mr100 mb20 lineheight40">
                                <div>{{ checkedCompany.companyName }}</div>
                                <div>（盖章）</div>
                                <div class="d-flex justify-content-end">
                                    <div class="mr30">年</div>
                                    <div class="mr30">月</div>
                                    <div>日</div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr class="h60">
                        <td class="textc itemTitle fs9">测试</td>
                        <td class="textc itemTitle fs9">{{ checkedProject.testName }}</td>
                        <td class="textc itemTitle fs9">日期</td>
                        <td class="textc itemTitle fs9"><el-date-picker v-model="formData.fileDay" class="miniDay w190"
                                value-format="yyyy-MM-dd" format="yyyy年MM月dd日" type="date" placeholder="选择日期">
                            </el-date-picker></td>
                    </tr>
                    <tr class="h60">
                        <td class="textc itemTitle fs9">审核</td>
                        <td class="textc itemTitle fs9">{{ checkedProject.headName }}</td>
                        <td class="textc itemTitle fs9">日期</td>
                        <td class="textc itemTitle fs9"><el-date-picker v-model="formData.fileDay" class="miniDay w190"
                                value-format="yyyy-MM-dd" format="yyyy年MM月dd日" type="date" placeholder="选择日期">
                            </el-date-picker></td>
                    </tr>
                </table>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div></div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml10">管理文件>项目结题验收报告 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-GO-R002</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <table border="1" cellspacing="0" class="tableBorder institutionTable">
                    <tr class="h60">
                        <td class="itemTitle fs9 w120" colspan="5">
                            一、软件描述（软件介绍）
                            <el-input v-model="formData.content" type="textarea" maxlength="1000" show-word-limit
                                @focus="keyPosition('content', '软件描述', placeholderData.content)" :rows="4"
                                placeholder="软件描述"></el-input>
                        </td>
                    </tr>
                    <tr class="h60">
                        <td class="itemTitle fs9 w120 lineheight40" colspan="5">
                            <div>二、测试描述</div>
                            <div class="textIndent40 mb50">
                                本次测试针对{{ checkedCompany.companyName }}的用户文档、病毒检查、功能性、安全及可靠性、易用性和适应性6个方面进行测试。
                            </div>
                        </td>
                    </tr>
                    <tr class="h60">
                        <td class="itemTitle fs9" colspan="5">
                            （一）测试环境：
                        </td>
                    </tr>
                    <tr class="h40">
                        <td class="textc itemTitle fs9 w100" rowspan="2">编号</td>
                        <td class="textc itemTitle fs9" colspan="2">硬件环境</td>
                        <td class="textc itemTitle fs9" rowspan="2" colspan="2">软件环境</td>
                    </tr>
                    <tr class="h40">
                        <td class="textc itemTitle fs9 w180">名称/型号</td>
                        <td class="textc itemTitle fs9 w230">配置（CPU/内存/硬盘）</td>
                    </tr>
                    <tr class="h60">
                        <td class="textc itemTitle fs9 w100">编号</td>
                        <td class="textc itemTitle fs9">控制端/兼容机</td>
                        <td class="itemTitle fs9 lineheight40">
                            <div>CPU:Intel(R)Core(TM)</div>
                            <div>i5-4210 4*2.27GHz</div>
                            <div>内存:8GB</div>
                            <div>硬盘:1TB</div>
                        </td>
                        <td class="textc itemTitle fs9 w300" colspan="2">操作系统:Windows10专业版</td>
                    </tr>
                    <tr class="h60">
                        <td class="textc itemTitle fs9 w100">2</td>
                        <td class="textc itemTitle fs9">病毒检查服务器/联想启天M4550</td>
                        <td class="itemTitle fs9 lineheight40">
                            <div>CPU:Intel(R)Core(TM)</div>
                            <div>i5-4210 4*2.27GHz</div>
                            <div>内存:8GB</div>
                            <div>硬盘:1TB</div>
                        </td>
                        <td class="itemTitle fs9 w300" colspan="2">
                            <div>操作系统:Windows7旗舰版SP1</div>
                            <div>杀毒软件:360杀毒软件</div>
                        </td>
                    </tr>
                    <tr class="h60">
                        <td class="itemTitle fs9" colspan="5">
                            （二）测试结果
                        </td>
                    </tr>
                    <tr class="h60">
                        <td class="textc itemTitle fs9">
                            序号
                        </td>
                        <td class="textc itemTitle fs9">
                            测试项
                        </td>
                        <td class="textc itemTitle fs9" colspan="2">

                        </td>
                        <td class="textc itemTitle fs9 w100">
                            测试结果
                        </td>
                    </tr>
                    <tr class="h60">
                        <td class="itemTitle fs9" colspan="5">
                            1.用户文档
                        </td>
                    </tr>
                    <tr class="h60">
                        <td class="textc itemTitle fs9">
                            1.1
                        </td>
                        <td class="textc itemTitle fs9">
                            完备性
                        </td>
                        <td class="itemTitle fs9" colspan="2">
                            用户文档完整地说明了软件包的功能以及在程序中用户可以调用的功能。
                        </td>
                        <td class="textc itemTitle fs9 w100">
                            通过
                        </td>
                    </tr>
                    <tr class="h60">
                        <td class="textc itemTitle fs9">
                            1.2
                        </td>
                        <td class="textc itemTitle fs9">
                            正确性
                        </td>
                        <td class="itemTitle fs9" colspan="2">
                            所检测用户文档的信息正确，没有歧义和错误的信息
                        </td>
                        <td class="textc itemTitle fs9 w100">
                            通过
                        </td>
                    </tr>
                    <tr class="h60">
                        <td class="textc itemTitle fs9">
                            1.3
                        </td>
                        <td class="textc itemTitle fs9">
                            一致性
                        </td>
                        <td class="itemTitle fs9" colspan="2">
                            所检测用户文档自身内容或相互之间没有矛盾，并且不与产品说明矛盾。每个术语的含义在文档中保持一致。
                        </td>
                        <td class="textc itemTitle fs9 w100">
                            通过
                        </td>
                    </tr>
                    <tr class="h60">
                        <td class="textc itemTitle fs9">
                            1.4
                        </td>
                        <td class="textc itemTitle fs9">
                            易理解性
                        </td>
                        <td class="itemTitle fs9" colspan="2">
                            所检测用户文档对正常执行工作任务的一般用户是易理解的，通过使用适当的术语、大量的图形表示、详细的解释以及引用有用的信息源来表达。
                        </td>
                        <td class="textc itemTitle fs9 w100">
                            通过
                        </td>
                    </tr>
                </table>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div></div>
                <div class="squareRight"></div>
            </div>
        </div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml10">管理文件>项目结题验收报告 V1.0</span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-GO-R002</span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <table border="1" cellspacing="0" class="tableBorder institutionTable">
                    <tr class="h60">
                        <td class="textc itemTitle fs9 w100">
                            1.5
                        </td>
                        <td class="textc itemTitle fs9 w180">
                            易学性
                        </td>
                        <td class="itemTitle fs9">
                            所检测用户文档可为用户学会如何使用该软件提供帮助。
                        </td>
                        <td class="textc itemTitle fs9 w100">
                            通过
                        </td>
                    </tr>
                    <tr class="h60">
                        <td class="textc itemTitle fs9">
                            1.6
                        </td>
                        <td class="textc itemTitle fs9">
                            可操作性
                        </td>
                        <td class="itemTitle fs9">
                            所检测的用户文档都有详细的目录表。
                        </td>
                        <td class="textc itemTitle fs9 w100">
                            通过
                        </td>
                    </tr>
                    <tr class="h60">
                        <td class="itemTitle fs9" colspan="4">
                            2.病毒检查
                        </td>
                    </tr>
                    <tr class="h60">
                        <td class="textc itemTitle fs9">
                            2.1
                        </td>
                        <td class="textc itemTitle fs9">
                            样品检测
                        </td>
                        <td class="itemTitle fs9">
                            样品未发现病毒。
                        </td>
                        <td class="textc itemTitle fs9 w100">
                            通过
                        </td>
                    </tr>
                    <tr class="h60" v-loading="askLoading" element-loading-text="填入中,请勿操作..."
                        element-loading-spinner="el-icon-loading">
                        <td class="itemTitle fs9" colspan="4">
                            3.功能性
                            <el-dropdown style="margin-left:725px;" split-button type="primary" @click="askCrateData"
                                v-if="askContent" @command="selectAsk">
                                一键填入
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item :title="row.content" v-for="(row, index) in answerData"
                                        :key="index" :command="row.content">{{ row.ask }}</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </td>
                    </tr>
                    <tr class="h60" v-for="(row, index) in formData.dataList" :key="index">
                        <td class="textc itemTitle fs9">
                            {{ row.num }}
                        </td>
                        <td class="textc itemTitle fs9" style="padding:0;">
                            <!-- <el-input v-model="row.name" class="wordInput" placeholder="测试项"></el-input> -->
                            <el-input v-model="row.name" type="textarea" maxlength="1000" show-word-limit :rows="2"
                                placeholder="测试项"></el-input>
                        </td>
                        <td class="itemTitle fs9" style="padding:0;">
                            <el-input v-model="row.content" type="textarea" maxlength="1000" show-word-limit :rows="2"
                                placeholder="功能性"></el-input>
                        </td>
                        <td class="textc itemTitle fs9 w100 posre">
                            通过
                            <div class="tool">
                                <div class="flex-c-c">
                                    <i class="eicon el-icon-caret-top" @click="topMove(index)"></i>
                                    <i class="eicon el-icon-plus" @click="addRow(index)"></i>
                                </div>
                                <div class="flex-c-c">
                                    <i class="eicon el-icon-caret-bottom" @click="bottomMove(index)"></i>
                                    <i class="eicon el-icon-delete" @click="delRow(index)"></i>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr class="h60">
                        <td class="itemTitle fs9" colspan="4">
                            4.安全及可靠性
                        </td>
                    </tr>
                    <tr class="h60">
                        <td class="textc itemTitle fs9">
                            4.1
                        </td>
                        <td class="textc itemTitle fs9">
                            软件容错性
                        </td>
                        <td class="itemTitle fs9">
                            软件具有基本的数据容错功能。
                        </td>
                        <td class="textc itemTitle fs9 w100">
                            通过
                        </td>
                    </tr>
                    <tr class="h60">
                        <td class="textc itemTitle fs9">
                            4.2
                        </td>
                        <td class="textc itemTitle fs9">
                            运行稳定性
                        </td>
                        <td class="itemTitle fs9">
                            在软件功能抽查测试过程中未出现软件异常退出现象。
                        </td>
                        <td class="textc itemTitle fs9 w100">
                            通过
                        </td>
                    </tr>
                    <tr class="h60">
                        <td class="itemTitle fs9" colspan="4">
                            5.易用性
                        </td>
                    </tr>
                    <tr class="h60">
                        <td class="textc itemTitle fs9">
                            5.1
                        </td>
                        <td class="textc itemTitle fs9">
                            易辨识性
                        </td>
                        <td class="itemTitle fs9">
                            程序的问题、消息和结果易于理解，出错信息可提供相应差错产生的原因或纠正的详细信息，
                        </td>
                        <td class="textc itemTitle fs9 w100">
                            通过
                        </td>
                    </tr>
                    <tr class="h60">
                        <td class="textc itemTitle fs9">
                            5.2
                        </td>
                        <td class="textc itemTitle fs9">
                            易学性
                        </td>
                        <td class="itemTitle fs9">
                            有用户手册
                        </td>
                        <td class="textc itemTitle fs9 w100">
                            通过
                        </td>
                    </tr>
                    <tr class="h60">
                        <td class="textc itemTitle fs9">
                            5.3
                        </td>
                        <td class="textc itemTitle fs9">
                            易操作性
                        </td>
                        <td class="itemTitle fs9">
                            软件操作较为容易
                        </td>
                        <td class="textc itemTitle fs9 w100">
                            通过
                        </td>
                    </tr>
                    <tr class="h60">
                        <td class="itemTitle fs9" colspan="4">
                            6.适应性
                        </td>
                    </tr>
                    <tr class="h60">
                        <td class="textc itemTitle fs9">
                            6.1
                        </td>
                        <td class="textc itemTitle fs9">
                            适应性
                        </td>
                        <td class="itemTitle fs9">
                            软件在规定的软硬件环境下能正确运行。
                        </td>
                        <td class="textc itemTitle fs9 w100">
                            通过
                        </td>
                    </tr>
                </table>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div></div>
                <div class="squareRight"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { getProjectUserList, getNormalWordDataByPdId } from "@/api/projectAdmin";
export default {
    props: ['projectData', 'assignment', 'modelData'],
    data() {
        return {
            checkedProject: {},
            checkedCompany: {},
            memberList: [],
            formData: {
                fileDay: "",
                levelCode: "",
                development: "",
                language: "",
                user: "",
                content: "",
                dataList: [{
                    num: "3.1",
                    name: "",
                    content: "",
                }],//"[{num:\"序号1\",name:\"名称1\",content:\"内容1\"},{num:\"序号2\",name:\"名称2\",content:\"内容2\"}]"
            },
            userList: [],
            userListLoading: false,
            placeholderData: {
                content: ""
            },
            answerData: [],
            askContent: "",
            askLoading: false,
        }
    },
    watch: {
        //监听赋值数据，赋值
        assignment: {
            handler(data) {
                if (data.key === 'createData') {
                    try {
                        this.getAnswerData(this.projectData.projectName);
                        let strArr = this.convertData(data.gptResult);
                        let dataList = [];
                        for (let key in strArr) {
                            let row = strArr[key];
                            row = row.replace(/(^\u0020*)|(^\u0020*$)|\n/ig, "");
                            dataList.push({ num: "3." + (this.formData.dataList.length + Number(key) + 1), name: row.split(":")[0], content: row.split(":")[1] })
                        }
                        this.formData.dataList = dataList.concat(this.formData.dataList);
                        this.askLoading = false;
                    } catch (err) {
                       //console.log(err);
                        this.askLoading = false;
                        this.$message.info("一键填入失败");
                    }
                } else if (data.key) {
                    if (data.key.indexOf("_") != -1) {
                        let keys = data.key.split("_");//0 key 1 列表key 2 下标
                        this.formData[keys[0]][keys[2]][keys[1]] = data.gptResult;
                    } else {
                        this.formData[data.key] = data.gptResult;
                    }
                    if (this.$refs[data.key]) {
                        this.$refs[data.key].focus();
                    }
                }
            },
            deep: true,
            immediate: true
        },
        //监听填写数据
        modelData: {
            async handler(data) {
                if (data && JSON.stringify(data) != '{}') {
                    await this.createData();
                    for (let key in data) {
                        let arrKey = ['dataList']
                        if (arrKey.indexOf(key) != -1) {
                            this.formData[key] = data[key] ? JSON.parse(data[key]) : [];
                        } else {
                            this.formData[key] = data[key] ? data[key] : "";
                        }
                    }
                } else {
                    this.formData = {
                        fileDay: "",
                        levelCode: "",
                        development: "",
                        language: "",
                        user: "",
                        content: "",
                        dataList: [{
                            num: "3.1",
                            name: "",
                            content: "",
                        }],
                    };
                    this.createData();
                }
            },
            deep: true
        },
    },
    methods: {
        /**@method 答复转换数组 */
        convertData(answer) {
            let data = [];
            if (answer.match(/功能名称[0-9](:|：)/ig)) {
                data = answer.replace(/功能名称[0-9](:|：)/ig, "|").replace(/功能介绍(:|：)/ig, ":").split("|");
            } else {
                data = answer.replace(/：/ig, ":").replace(/[0-9]\.|=|=[0-9]\:/ig, "|").split("|");
            }
            data.splice(0, 1);
            return data;
        },
        getAnswerData(ask) {
            this.answerData = [];
            let answerData = localStorage.getItem("answerData") ? JSON.parse(localStorage.getItem("answerData")) : [];
            for (let row of answerData) {
                if (row.ask.indexOf(ask) != -1) {
                    this.answerData.push(row)
                }
            }
        },
        askCrateData() {
            this.askLoading = true;
            this.$emit("askCrateData", this.askContent)
        },
        selectAsk(ask) {
            try {
                let strArr = this.convertData(ask);
                let dataList = [];
                for (let key in strArr) {
                    let row = strArr[key];
                    row = row.replace(/(^\u0020*)|(^\u0020*$)|\n/ig, "");
                    dataList.push({ num: "3." + (this.formData.dataList.length + Number(key) + 1), name: row.split(":")[0], content: row.split(":")[1] })
                }
                this.formData.dataList = dataList.concat(this.formData.dataList);
                this.askLoading = false;
            } catch (err) {
                this.askLoading = false;
                this.$message.info("一键填入失败");
            }
        },
        /**@method 定位要赋值的数据 */
        keyPosition(key, title, problem = "") {
            this.$emit("keyPosition", { key, title, problem });
        },
        /**@method 初始化数据 */
        createData() {
            let checkedProject = this.projectData;//项目数据
            this.checkedCompany = JSON.parse(sessionStorage.getItem("checkedProjectCompany"));
            this.checkedProject = {
                ...checkedProject,
                projectName: checkedProject.projectName.replace("的研究开发", ""),
                year: checkedProject.startDay ? checkedProject.startDay.slice(0, 4) : "",
                startMonth: checkedProject.startDay ? checkedProject.startDay.slice(0, 7) : "",
                endMonth: checkedProject.endDay ? checkedProject.endDay.slice(0, 7) : "",
                startDayText: this.convertChinese(checkedProject.startDay),
                endDayText: this.convertChinese(checkedProject.endDay),
                endDayText2: this.convertChinese(checkedProject.endDay, "."),
                projectDayText: this.convertChinese(checkedProject.projectDay),
            };
            this.formData.fileDay = checkedProject.endDay ? new Date(this.checkedProject.endDay).getFullYear() + '-' + (new Date(this.checkedProject.endDay).getMonth() + 1) + "-15" : "";
            this.placeholderData = {
                content: checkedProject.projectName + "软件描述"
            };
            return Promise.all([
                new Promise(open => {
                    getNormalWordDataByPdId({
                        pdId: checkedProject.pdId,
                        modelType: 1
                    }).then(res => {
                        if (res.data) {
                            this.formData.content = res.data.wordData.content;//研究开发内容
                            if (res.data.wordData.content) {
                                this.askContent = `${this.projectData.projectName + res.data.wordData.content}，项目成功转化，你是测试员，请写出${this.projectData.projectName}软件6个确切的功能名称，以及他们对应的功能介绍（功能介绍限制50字）。要求输出格式为=功能名称：功能介绍`;
                                this.getAnswerData(this.projectData.projectName);
                            }
                        }
                        open();
                    })
                }),
                new Promise(open => {
                    this.userListLoading = true;
                    getProjectUserList({
                        work: "技术总监,研发中心主任",
                        pcId: checkedProject.pcId
                    }).then(res => {
                        if (res.data) {
                            this.userList = res.data.pageInfo.list;
                            this.formData.user = res.data.pageInfo.list[0].userName;
                        }
                        this.userListLoading = false;
                        open();
                    })
                })
            ])

        },
        convertChinese(time, type = 'str') {
            if (time) {
                let newDate = new Date(time)
                let y = newDate.getFullYear();
                let m = newDate.getMonth() + 1;
                let d = newDate.getDate();
                if (type == 'str') {
                    return y + '年' + (m < 10 ? '0' + m : m) + '月' + (d < 10 ? '0' + d : d) + '日'
                } else {
                    return y + type + (m < 10 ? '0' + m : m) + type + (d < 10 ? '0' + d : d)
                }
            }
            return "";
        },
        /**@method 列表上移 */
        topMove(i) {
            if (i > 0) {
                let dataList = JSON.parse(JSON.stringify(this.formData.dataList));
                let replaceData = { ...dataList[i - 1] }
                let currentData = { ...dataList[i] };
                dataList[i] = replaceData;
                dataList[i - 1] = currentData;
                this.formData.dataList = dataList;


            }
        },
        /**@method 整理序号 */
        getTableCode() {
            for (let key in this.memberList) {
                this.$set(this.memberList[key], 'num', (Number(key) + 1));
            }
        },
        bottomMove(i) {
            if (this.memberList.length > (i + 1)) {
                let dataList = JSON.parse(JSON.stringify(this.formData.dataList));
                let replaceData = { ...dataList[i + 1] }
                let currentData = { ...dataList[i] };
                dataList[i] = replaceData;
                dataList[i + 1] = currentData;
                this.formData.dataList = dataList;

            }
        },
        addRow(i) {
            this.formData.dataList.push({
                num: "",
                name: "",
                content: "",
            });
            for (let i in this.formData.dataList) {
                this.formData.dataList[i].num = "3." + (Number(i) + 1);
            }
        },
        delRow(i) {
            if (this.formData.dataList.length > 1) {
                this.formData.dataList.splice(i, 1);
                for (let i in this.formData.dataList) {
                    this.formData.dataList[i].num = "3." + (Number(i) + 1);
                }
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.companyLogo {
    height: 100px;
    position: absolute;
    top: 100px;
    left: 80px;
}

.institutionTable {
    border-color: #000;

    /deep/input {
        border: none;
        color: #E6A23C;
    }

    /deep/.el-textarea__inner,
    /deep/.el-input__inner {
        border: none;
        color: #E6A23C;
        text-align: justify;
    }

    .tool {
        display: block;
        position: absolute;
        top: 50%;
        right: -51px;
        width: 60px;
        height: 60px;
        margin-top: -30px;
        background: #f1f2f4;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);

        .eicon {
            font-size: 18px;
            color: #666;
            cursor: pointer;
            height: 25px;
            width: 25px;
            text-align: center;
            line-height: 25px;
        }

        .el-icon-delete {
            color: #F56C6C;
        }
    }

    .itemTitle {
        height: 100%;
        font-size: 15px;
        padding: 5px 10px;
    }

    .itemContent {
        min-height: 30px;
        height: 100%;
        font-size: 15px;
        position: relative;
    }
}

.companySeal {
    height: 200px;
    width: 200px;
    position: absolute;
    bottom: 130px;
    right: 30px;
}

.zdhq {
    // color: #E6A23C !important;
}

.showModelContent div,
.showModelContent span {
    color: #000;
    border-color: #000;

    /deep/input {
        border: none;
        color: #E6A23C; // #000;
    }

    /deep/.el-textarea__inner,
    /deep/.el-input__inner {
        border: none;
        color: #E6A23C; // #000;
        padding: 0px 15px;
    }
}

.wordSetContent div {
    font-size: 20px;
    letter-spacing: 2px;
}

.showModelContent {
    background: #fff;
    position: relative;
    width: 1100px;
    min-height: 1500px;
    margin: 0 auto;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 50px;
    padding-bottom: 100px;

    .wordContentTitle {
        font-size: 30px;
        text-align: center;
        padding: 10px 0;
        font-weight: 600;
    }

    .modelHeader {
        div {
            font-size: 16px;
        }

        .modelHeaderTitle {
            border-bottom: 1px solid #000;
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordPage {
        position: absolute;
        bottom: 50px;
        width: 1000px;
        height: 30px;
        font-size: 20px;

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordCodeTable {
        width: 350px;
        margin-left: auto;
        border: 2px solid #000;
        margin-bottom: 150px;

        .wordCodeTitle {
            width: 40%;
            height: 43px;
            border-right: 1px solid #000;
            font-size: 18px;
        }

        .wordCodeContent {
            width: 60%;
            height: 43px;
            font-size: 16px;

            div {
                letter-spacing: 0px;
            }

            /deep/input {
                height: 42px;
                line-height: 42px;
            }
        }
    }

    .projectTitle div {
        font-size: 45px !important;
    }

    .projectTitle {
        margin-bottom: 500px;
    }

    .wordSetContent {
        .wordTable {
            border: 1px solid #000;

            .wordTableRow {
                display: flex;
                align-items: center;
                border-bottom: 1px solid #000;
                position: relative;

                .itemTitle {
                    font-size: 20px;
                    padding: 0 10px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                }

                .itemContent {
                    min-height: 60px;
                    overflow: hidden;

                    div {
                        font-size: 20px;
                    }

                    /deep/.el-range-editor--small.el-input__inner {
                        height: 58px;
                    }

                    /deep/.el-textarea__inner {
                        height: 58px;
                    }

                    /deep/.el-range-separator {
                        padding: 0;
                        line-height: 50px;
                        font-size: 20px;
                        width: 20px;
                    }

                    /deep/.el-range__close-icon {
                        font-size: 20px;
                        line-height: 53px;
                    }
                }
            }

            .wordTableRow2 {
                display: flex;
                align-items: center;
                position: relative;

                .itemTitle {
                    font-size: 20px;
                    padding: 0 10px;
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                }

                .itemContent {
                    min-height: 60px;
                    overflow: hidden;

                    div {
                        font-size: 20px;
                    }

                    /deep/.el-range-editor--small.el-input__inner {
                        height: 58px;
                    }

                    /deep/.el-range-separator {
                        padding: 0;
                        line-height: 50px;
                        font-size: 20px;
                        width: 20px;
                    }

                    /deep/.el-input__inner {
                        padding-right: 0;
                    }

                    /deep/.el-range__close-icon {
                        font-size: 20px;
                        line-height: 53px;
                    }
                }
            }

            .setContainer:hover .tool {
                display: block;
            }

            .wordTableRow:last-child {
                border: none;
            }
        }


        .delText {
            cursor: pointer;
        }

        .delText:hover {
            color: #F56C6C;
        }

        .rightBorder {
            border-right: 1px solid #000;
        }

        .topBorder {
            border-top: 1px solid #000;
        }

        .leftBorder {
            border-left: 1px solid #000;
        }

        .bottomBorder {
            border-bottom: 1px solid #000;
        }

        .wordSign {
            height: 100%;
            padding-top: 250px;

            .wordSignContent {
                text-align: justify;
                margin-left: 50px;
            }
        }

        .tool {
            display: block;
            position: absolute;
            right: -61px;
            width: 60px;
            height: 60px;
            background: #f1f2f4;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);

            .eicon {
                font-size: 20px;
                color: #666;
                cursor: pointer;
                height: 30px;
                width: 30px;
                text-align: center;
                line-height: 30px;
            }

            .el-icon-delete {
                color: #F56C6C;
            }
        }

        .allShow {
            width: 100%;
            height: 60px;
            border: none;
        }

        /deep/.allShow input {
            width: 100%;
            font-size: 20px !important;
        }

        /deep/input {
            height: 56px;
            line-height: 56px;
            font-size: 20px !important;
        }

        .iconCheck {
            border: 1px solid #000;
            height: 20px;
            width: 20px;
        }

        .underline {
            border-bottom: 1px solid #000;
        }

        .wordTableBigTitle {
            padding: 15px 10px;
            border: 1px solid #000;
            font-weight: 600;
        }

        .insert {
            border-top: none;
        }

        .wordTableTitle {
            padding: 15px 10px;
            border: 1px solid #000;
        }

        .wordTableTitle.insert {
            border-top: none;
        }

        .wordSetTextarea {
            border: 1px solid #000;
            border-top: none;
        }

        .wordTextareaContent {
            padding: 0 10px;
            text-indent: 45px;
            text-align: justify;
            line-height: 40px;
        }
    }
}
</style>